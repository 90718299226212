import { useState, useEffect, useContext } from "react";
import { api } from "../../services/api";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PaginatorContext } from "../../providers/Paginator";
import VolverButton from "../../components/VolverButton";
import { toast } from "react-toastify";
import { Button, TextField, IconButton, Table, TablePagination, TableBody, Paper, TableContainer, TableHead, TableRow, TableCell, CircularProgress, Alert } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { grey } from '@mui/material/colors';

export const Vouchers = () => {
  const history = useHistory();
  const param = useParams()
  const [loading, setLoading] = useState();

  const {
    query,
    setQuery,
    totalLength,
    setTotalLength,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    tarifaSelected
  } = useContext(PaginatorContext);

  const [data, setData] = useState([]);
  const [showSearch, setShowSearch] = useState();

  useEffect(() => {
    const callApi = async (id) => {
      setLoading(true);

      try {
        const response = await api.vouchers.getVouchers(
          id,
          query,
          pageSize,
          currentPage
        );

        if (response.status === "success") {
          const res = response.data.vouchers;
          setData(res);
          setTotalLength(response.data.totalDataSize);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    };

    callApi(param.id);
  }, [param.id, tarifaSelected, query, pageSize, currentPage, setTotalLength]);

  // Manejar el cambio de página
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Manejar el cambio de tamaño de página
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reiniciar la página al cambiar el tamaño
  };

  const handleToggleSearch = () => {
    setShowSearch(!showSearch); // Cambia el estado de visibilidad del campo de búsqueda
    setQuery(""); // Limpia el campo de búsqueda al mostrarlo
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <Button variant="contained" color="success" startIcon={<AddIcon />}
          onClick={() => history.push(`/eventos/${param.id}/nuevoVoucher`)}
        >
          Nuevo voucher
        </Button>   
        <form onSubmit={(e) => e.preventDefault()} className="d-flex justify-content-end">
          {showSearch ? (
            <div className="w-100 d-flex">
              <TextField
                id="exampleSearch"
                name="q"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Buscar..."
                type="search"
                variant="outlined"
                size="small"
              />
              <IconButton onClick={() => setShowSearch(false)} color="inherit">
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <Button variant="contained" sx={{background: grey[500]}}  onClick={handleToggleSearch}>
              <SearchIcon />
            </Button>
          )}
        </form>
      </div>
      
      <TableContainer component={Paper} className="mt-3 table-responsive">
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Voucher</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              data && data.length > 0 ? (
                data.map((voucher) => (
                  <TableRow key={voucher.id}>
                    <TableCell component="th" scope="row">
                      <div className="d-flex align-items-center gap-2">
                        <Button variant="contained" size="small" onClick={() => history.push(`/eventos/${param.id}/vouchers/${voucher.id}`)}>
                          <LocalPrintshopOutlinedIcon/>
                        </Button>
                        {voucher.id}
                      </div>
                    </TableCell>
                    
                    <TableCell>{voucher.codigo}</TableCell>
    
                    <TableCell>{voucher.tarifa.concepto}</TableCell>
    
                    <TableCell>{voucher.cantidad}</TableCell>

                    <TableCell>{voucher.nombre}</TableCell>

                    <TableCell>{voucher.apellido}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Alert severity="info">
                      No hay vouchers aún.
                    </Alert>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          className="d-flex justify-content-center"
          rowsPerPageOptions={[10, 25, 50]} // Opciones de tamaño de página
          count={totalLength} // Total de registros
          rowsPerPage={pageSize} // Tamaño de la página actual
          page={currentPage} // Página actual
          onPageChange={handleChangePage} // Evento de cambio de página
          onRowsPerPageChange={handleChangeRowsPerPage} // Evento de cambio de tamaño de página
          labelRowsPerPage="Registros p/página"
          showFirstButton
          showLastButton
        />
      </TableContainer>


      <div className="d-flex justify-content-end mt-3">
        <VolverButton />
      </div>
    </div>
  )
}