import React, { createContext, useContext, useState, useEffect } from 'react';
import useNotificaciones from '../../hooks/hookNotifications';
import { api } from '../../services/api';
import { useAuth0 } from "@auth0/auth0-react";
import { socket } from '../Socket/cliente';
import { toast } from 'react-toastify';

// Crear el contexto
const NotificacionesContext = createContext();

// Hook para usar el contexto en otros componentes
export const useNotificacionesContext = () => {
    return useContext(NotificacionesContext);
};

export const NotificacionesProvider = ({ children }) => {
    const { isAuthenticated } = useAuth0();
    const [notificaciones, setNotificaciones] = useState([]);
    const [pendingNotificaciones, setPendingNotificaciones] = useState(false);

    useEffect(() => {
        const fetchNotificaciones = async () => {
            try {
                const response = await api.notificaciones.getNotificaciones();
                if (response.status === 'success') {
                    const notificacionesArray = response.data.notificaciones || [];
                    setNotificaciones(notificacionesArray);
                    const tienePendientes = notificacionesArray.some(notificacion => !notificacion.atendido);
                    setPendingNotificaciones(tienePendientes);
                } else {
                    console.error('Error al obtener las notificaciones');
                }
            } catch (error) {
                console.error('Error al obtener las notificaciones:', error);
                toast.error("Error al obtener notificaciones.");
            }
        };

        if (isAuthenticated) {
            fetchNotificaciones();

            if (socket.connected) {
                socket.on('nueva-notificacion', (notification) => {
                    setNotificaciones(prev => [...prev, notification]);
                    setPendingNotificaciones(prev => prev || !notification.atendido);
                    toast.success(`${notification.titulo} ${notification.descripcion}`);
                });
            }

            return () => {
                socket.off('nueva-notificacion');
            };
        }
    }, [isAuthenticated]);

    const marcarComoLeida = (id, atendido) => {
        setNotificaciones(prevNotificaciones =>
            prevNotificaciones.map(notificacion =>
                notificacion.id === id ? { ...notificacion, atendido } : notificacion
            )
        );
        const tienePendientes = notificaciones.some(notificacion => !notificacion.atendido);
        setPendingNotificaciones(tienePendientes);
    };

    const eliminarNotificacion = (id) => {
        setNotificaciones(prevNotificaciones =>
            prevNotificaciones.filter(notificacion => notificacion.id !== id)
        );
    };

    return (
        <NotificacionesContext.Provider value={{ notificaciones, pendingNotificaciones, marcarComoLeida, eliminarNotificacion }}>
            {children}
        </NotificacionesContext.Provider>
    );
};