import { useEffect, useState } from "react";
import { api } from "../services/api";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import useCarritoStore from "../providers/Carrito";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useCarrito = () => {
  const { isAuthenticated } = useAuth0();
  const [loadingItem, setLoadingItem] = useState({});
  const [loadingCarrito, setLoadingCarrito] = useState(false);
  const {carrito} = useCarritoStore();
  const refrescarCarrito = useCarritoStore(state => state.refrescarCarrito);
  const actualizarCantidadItem = useCarritoStore(state => state.actualizarCantidadItem);
  const eliminarItemEntero = useCarritoStore(state => state.eliminarItemEntero);
  const history = useHistory();
  
  const crearCarrito = async (idevento, items) => {
    setLoadingCarrito(true);

    try {
      const response = await api.carrito.crearCarrito({
        idevento,
        items,
      });

      if (response.status === "success") {
        refrescarCarrito(response.data.carrito);

        toast.success(response.message, {
          position: "top-right",
          autoClose: 4000,
        });
      }
      else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    } finally {
      setLoadingCarrito(false);
    }
  }

  const actualizarCantidad = async (idtarifa, cantidad) => {
    // Activar el estado de carga para el item específico
    setLoadingItem(prevLoading => ({
      ...prevLoading,
      [idtarifa]: true
    }));

    try {
      const response = await api.carrito.sumarRestarItems({
        idtarifa,
        cantidad
      });

      if (response.status === "success") {
        actualizarCantidadItem(idtarifa, cantidad);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      // Desactivar el estado de carga para el item específico
      setLoadingItem(prevLoading => ({
        ...prevLoading,
        [idtarifa]: false
      }));
    }
  }

  const eliminarItem = async (iditem) => {
    setLoadingItem(prevLoading => ({
      ...prevLoading,
      [iditem]: true
    }));

    try {
      const response = await api.carrito.eliminarItem(iditem);

      if (response.status === "success") {
        const isLastItem = carrito.items.length === 1; // Verificar si este es el último item
        eliminarItemEntero(iditem); // Eliminar el item del carrito

        if (isLastItem) {
          await getCarrito(); // Llama a getCarrito si este era el último item
        }

        toast.success("Item eliminado con éxito", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      else {
        throw new Error(response.message);
      }

    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    } finally {
      setLoadingItem(prevLoading => ({
        ...prevLoading,
        [iditem]: false
      }));
    }
  }

  const getCarrito = async () => {
    setLoadingCarrito(true);

    try {
      const response = await api.carrito.getCarrito();

      if (response.status === "success") {
        refrescarCarrito(response.data.carrito);
        
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } finally {
      setLoadingCarrito(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getCarrito();
    }
  }, [isAuthenticated]);

  return {
    loadingItem,
    loadingCarrito,
    crearCarrito,
    actualizarCantidad,
    eliminarItem
  };
}

export default useCarrito;