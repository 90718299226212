import { useContext } from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImpresionContext } from '../../providers/Impresion';


const NavCanva = () => {
  const {
    setOpenFont,
    setOpenImage,
    setOpenQr,
    setOpenTemplates,
    font,
    image,
    qrCanva,
    templates
  } = useContext(ImpresionContext);

  const handleToggle = (e) => {
    if (e.currentTarget.id === 'font') {
      if (font) {
        setOpenFont(false)
      } else {
        setOpenImage(false);
        setOpenQr(false);
        setOpenTemplates(false);
        setOpenFont(true);
      }
    } else if (e.currentTarget.id === 'image') {
      if (image) {
        setOpenImage(false)
      } else {
        setOpenQr(false);
        setOpenTemplates(false);
        setOpenFont(false);
        setOpenImage(true);
      }
    } else if (e.currentTarget.id === 'qr') {
      if (qrCanva) {
        setOpenQr(false)
      } else {
        setOpenImage(false);
        setOpenTemplates(false);
        setOpenFont(false);
        setOpenQr(true);
      }
    } else if (e.currentTarget.id === 'templates') {
      if (templates) {
        setOpenTemplates(false)
      } else {
        setOpenImage(false);
        setOpenQr(false);
        setOpenFont(false);
        setOpenTemplates(true);
      }
    }

  }


  return (
    <>
      <nav className='d-flex flex-column h-100 justify-content-start gap-1 bg-light ' style={{ zIndex: '100', position: 'relative' }}>
        <button
          type='button'
          id='font'
          style={{ height: '75px' }}
          className='btn btn-light w-100 d-flex flex-column gap-1 justify-content-center align-items-center'
          onClick={(e) => handleToggle(e)}
        >
          <i className="material-symbols-outlined fs-4">format_shapes</i>
          <p className='p-0 m-0 fs-6'>Texto</p>
        </button>
        <button
          id='qr'
          type='button'
          style={{ height: '75px' }}
          className='btn btn-light w-100 d-flex flex-column gap-1 justify-content-center align-items-center'
          onClick={(e) => handleToggle(e)}
        >
          <i className="material-symbols-outlined fs-4">text_fields</i>
          {/* <i class="material-symbols-outlined fs-4">database</i> */}
          <p className='p-0 m-0 fs-6'>Dinamico</p>
        </button>
        <button
          id='image'
          type='button'
          style={{ height: '75px' }}
          className='btn btn-light w-100 d-flex flex-column gap-1 justify-content-center align-items-center'
          onClick={(e) => handleToggle(e)}
        >
          <FontAwesomeIcon id='image' icon={faImage} className='fs-5'></FontAwesomeIcon>
          <p className='p-0 m-0 fs-6'>Imagen</p>
        </button>
        <button
          id='templates'
          type='button'
          style={{ height: '75px' }}
          className='btn btn-light w-100 d-flex flex-column gap-1 justify-content-center align-items-center'
          onClick={(e) => handleToggle(e)}
        >
          {/* <FontAwesomeIcon id='templates' icon={faLightbulb} className='fs-5'></FontAwesomeIcon> */}
          <i className="material-symbols-outlined fs-4">space_dashboard</i>
          <p className='p-0 m-0 fs-6'>Plantillas</p>
        </button>
      </nav>
    </>
  )
}

export default NavCanva;