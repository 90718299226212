import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../services/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Button, FormControl, Alert, TextField, CircularProgress} from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export const CrearPublicacion = () => {
    const { id: eventoId } = useParams();
    const history = useHistory();
    const [formData, setFormData] = useState({
        eventoId: "",
        titulo: "",
        subtitulo: "",
        descripcion: "",
        desde: "",
        hasta: "",
    });
    const [error, setError] = useState("");
    const [t] = useTranslation("global");
    const [files, setFiles] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [mediaUrls, setMediaUrls] = useState([]);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [mediaFormats, setMediaFormats] = useState([]);
    const [mediaId, setMediaId] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [fechaEvento, setFechaEvento] = useState("");
    const [lengthDescrip, setLengthDescrip] = useState("");
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        setLengthDescrip(value.length);
    };

    const handleAddFileClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*,video/*";
        fileInput.multiple = true;
        fileInput.onchange = handleFileChange;
        fileInput.click();
    };


    useEffect(() => {
        if (mediaUrls.length > 0) {
            setSelectedFileIndex(0);
        } else {
            setSelectedFileIndex(null);
        }
    }, [mediaUrls]);

    const handleFileChange = async (e) => {
        const maxSizeFile = 10 * 1024 * 1024;
        const maxFiles = 5;
        const selectedFiles = Array.from(e.target.files);

        if(files.length + selectedFiles.length > maxFiles){
            alert(`Solo puedes subir como máximo ${maxFiles} archivos.`)
            return;
        }

        const validaFiles = [];
        selectedFiles.forEach((file) => {
            if (file.size <= maxSizeFile) {
                validaFiles.push(file);
            } else {
                console.error(`El archivo ${file.name} supera el límite de tamaño de 10MB`);
                alert(`El archivo ${file.name} supera el límite de tamaño de 10MB`);
            }
        });

        if (validaFiles.length === 0) return;

        setLoading(true);

        setFiles((prevFiles) => {
            const combinedFiles = [...prevFiles, ...validaFiles];

            if(combinedFiles.length > maxFiles){
                alert(`Solo puedes subir como máximo ${maxFiles} archivos.`)
                return prevFiles;
            }

            if (selectedFileIndex === null && validaFiles.length > 0) {
                setSelectedFileIndex(prevFiles.length);
            }

            return combinedFiles;
        });

        const formData = new FormData();
        validaFiles.forEach((file) => {
            formData.append("files", file);
        });


        try {
            const response = await api.media.upload(formData);
            if (response.status === "success") {
                const medias = response.data.media;
                setMediaUrls(medias.map((media) => media.url));
                setMediaTypes(medias.map((media) => media.type));
                setMediaFormats(medias.map((media) => media.format));
                setMediaId(medias.map((media) => media.public_id));
            }
        } catch (error) {
            console.error("Error uploading files:", error);
        } finally{
            setLoading(false);
        }
    };

    const deleteMedia = async (index) => {
        const publicId = mediaId[index];

        try {
            const response = await api.media.delete({ public_id: publicId });
            if (response.status === "success") {
                setFileNames(fileNames.filter((_, i) => i !== index));
                const updatedMediaUrls = mediaUrls.filter((_, i) => i !== index);
                const updatedMediaTypes = mediaTypes.filter((_, i) => i !== index);
                const updatedMediaFormats = mediaFormats.filter((_, i) => i !== index);
                const updatedMediaId = mediaId.filter((_, i) => i !== index);

                setMediaUrls(updatedMediaUrls);
                setMediaTypes(updatedMediaTypes);
                setMediaFormats(updatedMediaFormats);
                setMediaId(updatedMediaId);

                if (mediaUrls.length > 1) {
                    setSelectedFileIndex(0);
                } else {
                    setSelectedFileIndex(null);
                }

            }
        } catch (error) {
            console.error("Error deleting media:", error);
        }
    };

    const renderFilePreview = (file, index) => {
        const isImage = file.type.startsWith("image/");
        const isVideo = file.type.startsWith("video/");
        const fileURL = URL.createObjectURL(file);

        const thumbnailClass = selectedFileIndex === index ? "thumbnail selected" : "thumbnail";

        return (
            <div key={index} className={thumbnailClass} onClick={() => handleThumbnailClick(index)}>
                {isImage && <img src={fileURL} alt={`Preview ${index}`} />}
                {isVideo && <video src={fileURL} />}
            </div>
        );
    };

    const handleThumbnailClick = (index) => {
        setSelectedFileIndex(index);
    };

    const renderMainPreview = () => {
        if (selectedFileIndex === null || files.length === 0) return null;

        const selectedFile = files[selectedFileIndex];
        const isImage = selectedFile.type.startsWith("image/");
        const isVideo = selectedFile.type.startsWith("video/");
        const fileURL = URL.createObjectURL(selectedFile);

        const handleRemoveFile = () => {
            setFiles((prevFiles) => prevFiles.filter((_, index) => index !== selectedFileIndex));
            setSelectedFileIndex(null); // Resetea la seleccion
        };

        return (
            <div className="main-preview">
                {isImage && <img src={fileURL} alt="Imagen seleccionada" />}
                {isVideo && (
                    <video src={fileURL} controls />
                )}

                <button type="button" className="remove-button" onClick={() => {
                    deleteMedia(selectedFileIndex);
                    handleRemoveFile();
                }}>
                    Eliminar
                </button>

            </div>
        );
    };

    useEffect(() => {
        const fetchEvento = async () => {
            try {
                const response = await api.eventos.getPublic(eventoId);
                if (response.status === "success") {
                    setFechaEvento(response.data.evento.fecha);
                }
            } catch (error) {
                console.error("Error deleting media:", error);
            }
        };

        fetchEvento();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const hoy = new Date();

        const newErrors = [];

        if (formData.titulo.length > 50) newErrors.titulo = "El titulo de la publicacion no puede exceder 50 caracteres."
        if (formData.titulo.length < 5) newErrors.titulo = "El titulo de la publicacion debe tener al menos 5 caracteres."

        if (formData.descripcion.trim() === "") newErrors.descripcion = "La descripción es obligatoria."
        if (formData.descripcion.length > 80) newErrors.descripcion = "La descripción no puede superar 80 caracteres."
        if (formData.descripcion.length < 5) newErrors.descripcion = "La descripción necesita al menos 5 caracteres."

        if (formData.desde === "" || formData.hasta === "") newErrors.fechas = "Las fecha de inicio y finalizacion de la publicacion son obligatorias."
        if (formData.hasta > fechaEvento) newErrors.hasta = "La fecha de finalizacion de la publicacion no puede ser mayor a la fecha de realizacion del evento."
        if (formData.desde < hoy) newErrors.desde = "La fecha de inicio de la publicacion no puede ser anterior a hoy."

        if (files.length === 0) newErrors.files = "Por favor, sube un archivo.";

        // detiene el envio si hay errores
        if (Object.keys(newErrors).length > 0) {
            setError(newErrors);
            return;
        }

        const desdeFormatted = moment.tz(formData.desde, "America/Buenos_Aires").format("YYYY-MM-DDT00:00:00-03:00");
        const hastaFormatted = moment.tz(formData.hasta, "America/Buenos_Aires").format("YYYY-MM-DDT00:00:00-03:00");

        const data = {
            idevento: parseInt(eventoId),
            titulo: formData.titulo,
            subtitulo: formData.subtitulo,
            descripcion: formData.descripcion,
            desde: desdeFormatted,
            hasta: hastaFormatted,
            attachs: mediaUrls.map((url, index) => ({
                url: url,
                type: mediaTypes[index],
                format: mediaFormats[index],
                public_id: mediaId[index],
            })),
        };

        try {
            const response = await api.publicaciones.createPublicacion(data);
            if (response.status === "success") {
                setFormData({
                    eventoId: "",
                    titulo: "",
                    subtitulo: "",
                    descripcion: "",
                    desde: "",
                    hasta: "",
                });
                setMediaUrls("");
                setMediaTypes("");
                setMediaFormats("");
                setMediaId("");
                setError("");
                setSubmitted(true);
                setTimeout(() => {
                    history.goBack();
                }, 1500);
            } else {
                setError(<>{t("publications.error1")}</>);
            }
        } catch (error) {
            setError(<>{t("publications.error2")}</>);
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px", padding: "0 10px" }}>
            <div style={{ maxWidth: "600px", width: "100%" }}>
                <h2 className="mb-4">{t("publications.createPublication")}</h2>
                <div>
                    <form id="form-publicacion" onSubmit={handleSubmit} className="mt-4">
                        <FormControl sx={{ width: "100%" }}>
                            <p className="subtitulo-seccion-evento-expanded">Título <span className="text-danger">*</span></p>
                            <p className="descrip-seccion-evento-expanded mb-0">Ingrese un titulo para su publicacion</p>
                            <TextField
                                sx={{
                                    marginTop: "5px",
                                    marginBottom: "15px",
                                    input: {
                                        padding: "10px 15px"
                                    }
                                }}
                                type="text"
                                id="titulo"
                                name="titulo"
                                value={formData.titulo}
                                onChange={handleInputChange}
                            />
                            <>
                                {error.titulo &&
                                    <Alert severity="error"
                                        sx={{
                                            marginBottom: "10px"
                                        }}>{error.titulo}</Alert>
                                }
                            </>
                        </FormControl>

                        <FormControl sx={{ width: "100%" }}>
                            <p className="subtitulo-seccion-evento-expanded">Subtitulo</p>
                            <TextField
                                sx={{
                                    marginTop: "5px",
                                    marginBottom: "15px",
                                    input: {
                                        padding: "10px 15px"
                                    }
                                }}
                                type="text"
                                id="subtitulo"
                                name="subtitulo"
                                value={formData.subtitulo}
                                onChange={handleInputChange}
                            />

                        </FormControl>

                        <FormControl sx={{ width: "100%" }}>
                            <p className="subtitulo-seccion-evento-expanded">Descripción <span className="text-danger">*</span></p>
                            <p className="descrip-seccion-evento-expanded mb-0">Ingrese una descripcion concisa y atrayente para su publicacion</p>
                            <TextField
                                sx={{
                                    marginTop: "5px",
                                    marginBottom: "15px",
                                    input: {
                                        padding: "10px 15px"
                                    }
                                }}
                                multiline
                                rows={2}
                                id="descripcion"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleInputChange}

                            />
                            <div style={{ position: "relative", top: "-10px" }}>
                                {lengthDescrip === 80 ? (
                                    <p className="text-right" style={{ color: "#c44536", fontSize: "12px" }}>
                                        {lengthDescrip}/80
                                    </p>
                                ) : (
                                    <p className="text-right" style={{ fontSize: "12px", color: "#666" }}>
                                        {lengthDescrip}/80
                                    </p>
                                )}
                            </div>
                            <>
                                {error.descripcion &&
                                    <Alert severity="error"
                                        sx={{
                                            position: "relative",
                                            top: "-25px",
                                            marginBottom: "15px"
                                        }}>{error.descripcion}</Alert>
                                }
                            </>
                        </FormControl>

                        <div className="mb-3" style={{ position: "relative", top: "-20px" }}>
                            <p className="subtitulo-seccion-evento-expanded">Periodo de la publicación <span className="text-danger">*</span></p>
                            <p className="descrip-seccion-evento-expanded mb-0 text-justify">Selecciona las fechas en las que deseas que tu publicación sea visible.
                                La publicación comenzará a mostrarse en la fecha de inicio y dejará de estar disponible después de la fecha de finalización.</p>
                            <div className="d-flex flex-row gap-4 flex-wrap mt-1">
                                <FormControl sx={{ width: "35%" }}>
                                    <TextField
                                        sx={{
                                            marginTop: "5px",
                                            marginBottom: "15px",
                                            input: {
                                                padding: "10px 15px"
                                            }
                                        }}
                                        id="desde"
                                        name="desde"
                                        type="date"
                                        value={formData.desde}
                                        onChange={handleInputChange}
                                    />
                                    <small style={{ position: "relative", top: "-10px" }}>Fecha de inicio</small>

                                </FormControl>
                                <FormControl sx={{ width: "35%" }}>
                                    <TextField
                                        sx={{
                                            marginTop: "5px",
                                            marginBottom: "15px",
                                            input: {
                                                padding: "10px 15px"
                                            }
                                        }}
                                        id="hasta"
                                        name="hasta"
                                        type="date"
                                        value={formData.hasta}
                                        onChange={handleInputChange}
                                    />
                                    <small style={{ position: "relative", top: "-10px" }}>Fecha de finalizacion</small>
                                </FormControl>
                                <>
                                    {error.desde &&
                                        <Alert severity="error"
                                            sx={{
                                                position: "relative",
                                                top: "-20px",
                                            }}>{error.desde}</Alert>
                                    }
                                    {error.hasta &&
                                        <Alert severity="error"
                                            sx={{
                                                position: "relative",
                                                top: "-20px",
                                            }}>{error.hasta}</Alert>
                                    }
                                    {error.fechas &&
                                        <Alert severity="error"
                                            sx={{
                                                position: "relative",
                                                top: "-20px",
                                            }}>{error.fechas}</Alert>
                                    }
                                </>
                            </div>
                        </div>

                        <FormControl sx={{ width: "100%" }}>
                            <p className="subtitulo-seccion-evento-expanded mb-2">Imagenes para tu publicacion <span className="text-danger">*</span></p>
                            <p className="descrip-seccion-evento-expanded">
                                Sube hasta 5 imágenes y videos que muestren los detalles y momentos destacados para promocionar tu evento.
                                Asegúrate de elegir archivos de alta calidad para un mejor impacto visual.
                            </p>
                            <div className="info-input-img">
                                <ul className="ul-info-img">
                                    <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño recomendado de imagen: 19200x1280</li>
                                    <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño máximo de archivo: 10MB</li>
                                    <li style={{ fontSize: "10px", fontWeight: "normal" }}>Formatos admitidos de imagen: JPEG, PNG</li>
                                </ul>
                            </div>
                            <div className={`input-file ${files.length === 0 ? "" : "border-0"}`}>
                                <input
                                    id="files"
                                    name="files"
                                    type="file"
                                    accept="image/*,video/*"
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                />

                                {loading ? (
                                        <div className="d-flex flex-column align-items-center">
                                            <p>Cargando archivos...</p>
                                            <CircularProgress/>
                                        </div>
                                    ) : files.length === 0 ? (
                                        <label className="placeholder-input-img" htmlFor="files">
                                            {t("event.inputImage")}
                                        </label>
                                    ) : (
                                    <>
                                        <div className="imagen-contenedor">
                                            {renderMainPreview()}
                                            <div className="thumbnails-contenedor">
                                                <div className="boton-agregar-img" onClick={handleAddFileClick}>
                                                    <AddOutlinedIcon sx={{ color: "#3659E3" }} />
                                                </div>
                                                {files.map(renderFilePreview)}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <>
                                {error.files &&
                                    <Alert severity="error"
                                        sx={{
                                            marginTop: "10px"
                                        }}>{error.files}</Alert>
                                }
                            </>
                        </FormControl>

                        {error && <Alert color="red">Por favor, chequea la información ingresada.</Alert>}
                        {submitted && (
                            <Alert color="success">
                                {t("publications.successCreatedAlert")}
                            </Alert>
                        )}
                        <div className="d-flex justify-content-end mb-4">
                            <Button
                                type="submit"
                                form="form-publicacion"
                                className="mt-3 boton-submit"
                            >
                                {t("button.create")} publicacion
                            </Button>{" "}
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
};
