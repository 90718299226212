import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../../services/api";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import moment from "moment";
import ShareButton from "../../../../components/shareButton/shareButton";
import Loading from "../../../../components/Loading";
import useCarrito from "../../../../hooks/hookCarrito";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import "./eventopublic.css";
import { styled } from '@mui/system';
import {
    ShoppingCartOutlined, FavoriteBorder, KeyboardArrowUpOutlined,
    PlaceOutlined, KeyboardArrowDownOutlined, CalendarMonthOutlined,
    AccessAlarmOutlined, Person2Outlined, ExpandMore,
    CloseOutlined, InfoOutlined,
} from '@mui/icons-material';

import { Button, ButtonGroup, Breadcrumbs, CircularProgress, Typography, Link, Accordion, AccordionSummary, AccordionDetails, Drawer, Divider, Tooltip, Zoom } from "@mui/material";

import { useMediaQuery } from "react-responsive";
import EventoPublicMobile from "./EventoPublicMobile";

//escapado de caracteres para el wysiwyg
const sanitizeHtml = (html) => {
    // crea un div temporal
    const div = document.createElement("div");
    // guarda el html en el div
    div.innerHTML = html;

    // filtra las etiquetas permitidas
    const allowedTags = [
        "ol",
        "ul",
        "li",
        "p",
        "strong",
        "em",
        "br",
        "h1",
        "h2",
        "u",
    ]; // etiquetas permitidas
    Array.from(div.querySelectorAll("*")).forEach((node) => {
        if (!allowedTags.includes(node.nodeName.toLowerCase())) {
            node.parentNode.removeChild(node); // elimina los que no permite
        }
    });

    return div.innerHTML; // devuelve el html seguro
};

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
    '& .MuiBreadcrumbs-separator': {
        color: 'white',
    },
}));

export const EventoPublic = () => {
    const [data, setData] = useState();
    const param = useParams();
    const [cantidad, setCantidad] = useState({});
    const [mapUrl, setMapUrl] = useState("");
    const history = useHistory();
    const { crearCarrito } = useCarrito();
    const [loadingCarrito, setLoading] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const [showMap, setShowMap] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const preguntas = [
        { pregunta: "¿Cómo puedo comprar entradas para un evento?", respuesta: `Para comprar entradas, visita la página del evento en nuestra plataforma. Elige la categoría de entrada (como General, VIP, etc.), selecciona la cantidad que deseas comprar y haz clic en "Comprar". A continuación, serás redirigido al proceso de pago, donde podrás ingresar tus datos y completar la compra.` },
        { pregunta: "¿Qué métodos de pago están disponibles para comprar entradas?", respuesta: "Nuestra plataforma acepta diversos métodos de pago, como tarjetas de crédito, débito, PayPal, y transferencias bancarias. Al finalizar tu compra, podrás seleccionar el método que prefieras en la pantalla de pago." },
        { pregunta: "¿Cómo recibo mis entradas después de comprarlas?", respuesta: `Una vez completada la compra, recibirás tus entradas por correo electrónico en formato digital, con un código QR que se escaneará en el evento. También puedes acceder a tus entradas desde tu cuenta en nuestra plataforma, en la sección "Mis Entradas".` },
        { pregunta: "¿Es posible cambiar o devolver una entrada después de haberla comprado?", respuesta: "Las políticas de devolución y cambios dependen del organizador del evento. Revisa los términos del evento antes de completar la compra. Si el organizador permite devoluciones o cambios, podrás solicitarlo a través de nuestra plataforma o contactando directamente con el soporte del evento." },
        { pregunta: "¿Cómo puedo saber si mi compra ha sido exitosa?", respuesta: `Después de completar la compra, recibirás un correo electrónico de confirmación con los detalles de tu compra y tus entradas adjuntas. Si no recibes este correo en unos minutos, revisa tu carpeta de spam o accede a la sección "Mis Entradas" en tu cuenta para verificar la compra.` },
    ]

    const toggleDrawer = (newOpenDrawer) => () => {
        setOpenDrawer(newOpenDrawer);
    };

    useEffect(() => {
        const callApi = async () => {
            try {
                const response = await api.eventos.getPublic(param.id);
                if (response.status === "success") {
                    setData(response.data.evento);
                }
            } catch (error) {
                toast.error("Error en la red.", {
                    position: "top-center",
                    autoClose: 5000,
                });
            }
        };

        callApi();
    }, [param.id]);


    /* CALCULOS TARIFAS */

    const calculoSubtotalTarifa = (tarifa) => {
        const cantidadTarifa = cantidad[tarifa.id] || 0;
        return cantidadTarifa * tarifa.precio;
    }

    const subtotal = data?.tarifas?.reduce((acc, tarifa) => {
        return acc + calculoSubtotalTarifa(tarifa);
    }, 0) || 0;

    //comision por ahora no funciona ya que no esta tomado en cuenta en el back
    const comision = data?.tarifas?.reduce((acc, tarifa) => {
        const cantidadTarifa = cantidad[tarifa.id] || 0;
        if (tarifa.asumir_comision === 0) {
            return acc + (calculoSubtotalTarifa(tarifa) * 0.05);
        }
        return acc;
    }, 0) || 0;

    const total = subtotal + comision;

    const hayComision = data?.tarifas?.some(tarifa => {
        const cantidadTarifa = cantidad[tarifa.id] || 0;
        return tarifa.asumir_comision === 0 && cantidadTarifa > 0;
    });

    const tarifasSeleccionadas = data?.tarifas?.filter((tarifa) => cantidad[tarifa.id] > 0);
    /* FIN CALCULOS TARIFAS */

    const direccion = data?.direccion + " " + data?.ciudad;
    const apiKey = "AIzaSyCzPvPffhA4gns9-sfxzSlXXC4ytQtbKxc";

    useEffect(() => {
        if (direccion) {
            const direccionEncoded = encodeURIComponent(direccion);
            const newMapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${direccionEncoded}`;

            setMapUrl(newMapUrl);
        }
    }, [direccion, apiKey]);

    const incremento = (idtarifa) => {
        setCantidad((prevCantidades) => {
            const nuevaCantidad = (prevCantidades[idtarifa] || 0) + 1;

            // Verificar si la cantidad supera el límite permitido
            const limite = data?.tarifas?.find(
                (tarifa) => tarifa.id === idtarifa
            )?.limite_persona;

            if (nuevaCantidad > parseInt(limite)) {
                return prevCantidades; // No actualizamos si se supera el límite
            }

            return {
                ...prevCantidades,
                [idtarifa]: nuevaCantidad,
            };
        });
    };

    const decremento = (idtarifa) => {
        setCantidad((prevCantidades) => ({
            ...prevCantidades,
            [idtarifa]: Math.max(0, (prevCantidades[idtarifa] || 0) - 1),
        }));
    };

    const handleAñadirCarrito = async () => {
        setLoading(true);

        const idevento = parseInt(param.id);

        const items = Object.entries(cantidad)
            .map(([idtarifa, cantidad]) => ({
                idtarifa: parseInt(idtarifa),
                cantidad,
            }))
            .filter((item) => item.cantidad > 0); // Filtramos para solo incluir items con cantidad mayor a 0

        if (items.length === 0) {
            toast.info("Debes seleccionar al menos un item para añadir al carrito.", {
                position: "top-right",
                autoClose: 4000,
            });
            setLoading(false);
            return;
        }

        await crearCarrito(idevento, items);

        setCantidad(
            items.reduce((acc, item) => ({ ...acc, [item.idtarifa]: 0 }), {})
        );

        setLoading(false);
    };

    const toggleMap = () => {
        setShowMap(!showMap);
    }

    if (!data) {
        return <Loading />;
    }

    if (isMobile) {
        return (
            <EventoPublicMobile data={data} map={mapUrl} />
        );
    }
    else {
        return (
            <>
                <div className="header-public" style={{ backgroundImage: `url(${data.attachs[0].url})` }}>
                    <div className="overlay"></div>
                    <div className="breadcrumb-public">
                        <StyledBreadcrumbs aria-label="breadcrumb" separator="›">
                            <Link className="texto-evento" sx={{ color: "white", fontSize: "18px" }} underline="hover" href="/">
                                Inicio
                            </Link>
                            <Link
                                className="texto-evento"
                                underline="hover"
                                sx={{ color: "white", fontSize: "18px" }}
                                href="/"
                            >
                                {data.evento_tipo.evento_tipo}
                            </Link>
                            <Typography className="texto-evento"
                                sx={{ color: "white", fontSize: "18px" }}>{data.evento}</Typography>
                        </StyledBreadcrumbs>
                    </div>
                    <div className="contenido-public">
                        <div className="d-flex flex-row align-items-baseline">

                            <img
                                src={data.attachs[0].url}
                                alt="Imagen del evento"
                                className="imagen-evento"
                            />
                            <div style={{ marginTop: "15%" }} className="d-flex flex-row justify-content-end align-items-center w-100">
                                <h1 className="titulo-evento texto-evento">{data.evento}</h1>
                                <FavoriteBorder sx={{

                                    color: "white", cursor: "pointer"
                                }} />
                                <ShareButton />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-4 py-4 d-flex flex-wrap flex-lg-nowrap justify-content-center">
                    <div className="w-100">
                        <div className="d-flex flex-row gap-5">
                            <div className="d-flex flex-column">
                                <p style={{ color: "#221A60", fontSize: "20px", marginBottom: "5px" }}>
                                    Ubicacion
                                </p>
                                <div className="d-flex gap-2" style={{ marginBottom: "10px" }}>
                                    <PlaceOutlined sx={{ width: "19px", height: "19px", marginTop: "1px" }} />
                                    <div className="d-flex flex-column">
                                        <p style={{ fontWeight: "bold", fontSize: "16px", marginBottom: "5px" }}>
                                            {data.direccion}
                                        </p>
                                        <p style={{ fontSize: "16px", marginBottom: "5px", }}>
                                            {data.ciudad}
                                        </p>
                                        {!showMap ? <p style={{ fontSize: "14px", marginBottom: "0", color: "#5656FF" }} onClick={toggleMap}>Mostrar mapa <KeyboardArrowDownOutlined sx={{ width: "16px", height: "16px" }} /></p>
                                            :
                                            <p style={{ fontSize: "14px", marginBottom: "0", color: "#5656FF" }} onClick={toggleMap}>Cerrar mapa <KeyboardArrowUpOutlined sx={{ width: "16px", height: "16px" }} /></p>}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <p style={{ color: "#221A60", fontSize: "20px", marginBottom: "5px" }}>
                                    Fecha
                                </p>
                                <p className="d-flex align-items-center" style={{ fontSize: "16px", marginBottom: "5px" }}>
                                    <CalendarMonthOutlined sx={{ width: "16px", height: "16px", marginRight: "10px" }} />
                                    {format(new Date(data.fecha), "dd 'de' MMMM", {
                                        locale: es,
                                    })}
                                </p>
                                <p className="d-flex align-items-center" style={{ fontSize: "16px", marginBottom: "5px" }}>
                                    <AccessAlarmOutlined sx={{ width: "16px", height: "16px", marginRight: "10px" }} />
                                    {moment.parseZone(data.hora).format("HH:mm")} hs.
                                </p>
                            </div>
                            <div className="d-flex flex-column">
                                <p style={{ color: "#221A60", fontSize: "20px", marginBottom: "5px" }}>
                                    Organizado por
                                </p>
                                <p className="d-flex align-items-center" style={{ fontSize: "16px", marginBottom: "5px" }}>
                                    <Person2Outlined sx={{ width: "16px", height: "16px", marginRight: "10px" }} />
                                    Usuario ID: {data.ownerid}
                                </p>
                            </div>
                        </div>
                        {showMap && (
                            <div className="">
                                <iframe
                                    title="location"
                                    className="rounded shadow"
                                    width="90%"
                                    height="250"
                                    id="map"
                                    loading="lazy"
                                    allowFullScreen
                                    referrerPolicy="no-referrer-when-downgrade"
                                    src={mapUrl}
                                ></iframe>
                            </div>
                        )}
                        <div className="mt-2 " style={{ width: "95%" }}>
                            <p style={{ color: "#221A60", fontSize: "22px", marginBottom: "10px" }}>
                                Acerca del evento
                            </p>
                            <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(data?.descripcion) }} />
                        </div>
                        <div className="mt-2" style={{ width: "95%" }}>
                            <p style={{ color: "#221A60", fontSize: "22px", marginBottom: "10px" }}>
                                Preguntas frecuentes
                            </p>
                            {preguntas?.map((p, i) => (
                                <Accordion
                                    key={i}       
                                    style={{ backgroundColor: '#f9f9f9', marginBottom: "10px" }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${i}-content`}
                                        id={`panel${i}-header`}
                                    >
                                        <p className="m-0 fw-medium">{p.pregunta}</p>
                                    </AccordionSummary>

                                    <AccordionDetails as="p" className="m-0">
                                        {p.respuesta}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </div>

                    <div
                        className="w-100 pb-4 px-4 mt-4 mt-lg-0"
                        style={{ maxWidth: "400px" }}
                    >
                        <p style={{ color: "#221A60", fontSize: "20px", marginBottom: "5px" }}>
                            Tarifas
                        </p>


                        <div className="d-flex flex-column fw-medium gap-2 mt-1 mb-4">
                            {data.tarifas ? (
                                data.tarifas.map((tarifa) => (
                                    <div
                                        key={tarifa.id}
                                        className="tarifa-opcion d-flex py-2 align-items-center position-relative"
                                    >
                                        <div className={`${tarifa.restantes === 0 ? "barra-tarifas-disabled" : "barra-tarifas-desktop"}`} ></div>
                                        <div className="d-flex flex-column  ms-3">
                                            {tarifa.restantes === 0 ? (
                                                <>
                                                    <p className=" fw-bold mb-0" style={{ color: "#D9D9D9" }}>{tarifa.concepto}</p>
                                                    <small className=" rounded d-flex align-items-center px-2" style={{ color: "#C12828", backgroundColor: "#FCDADA", paddingTop: "2px", paddingBottom: "2px" }}>
                                                        <CloseOutlined sx={{ width: "16px", height: "17px", marginRight: "5px" }} /> Agotados
                                                    </small>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="mb-0" style={{ color: "black" }}>{tarifa.concepto}</p>
                                                    <small className="text-secondary">
                                                        {tarifa.idtipo === 1 ? <i>Tickets</i> : <i>Vouchers</i>}{" "}
                                                        restantes: {tarifa.restantes}
                                                    </small>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-warning text-center">
                                    <hr />
                                    Aún no hay tarifas.
                                    <hr />
                                </p>
                            )}
                        </div>
                        <div className="d-flex justify-content-end flex-wrap gap-2 align-items-center">
                            <Button className="boton-submit" onClick={toggleDrawer(true)}>
                                Comenzar compra <ShoppingCartOutlined sx={{ color: "white", width: "18px", height: "18px", marginLeft: "5px" }} />
                            </Button>
                        </div>
                        <Drawer
                            sx={{
                                '& .MuiDrawer-paper': {
                                    borderRadius: '10px 10px 0 0',
                                },
                            }}
                            transitionDuration={500}
                            anchor="bottom"
                            open={openDrawer}
                            onClose={toggleDrawer(false)}
                        >
                            <div className="d-flex flex-row justify-content-between" style={{ padding: "20px", paddingBottom: "15px", paddingTop: "15px" }}>
                                <p className="fw-bold mb-0" style={{ color: "#221A60", fontSize: "20px" }}>Resumen de tu compra</p>
                                <button type="button" onClick={toggleDrawer(false)}>
                                    <CloseOutlined sx={{ width: "16px", height: "17px", marginRight: "5px" }} />
                                </button>
                            </div>
                            <div className="d-flex flex-row w-100" style={{ padding: "20px", paddingTop: "0", paddingBottom: "10px" }}>
                                <div className="" style={{ flex: 1, paddingRight: '8px' }}>
                                    <p style={{ color: "#221A60", fontSize: "20px", marginBottom: "5px" }}>
                                        Tarifas
                                    </p>
                                    <div className="d-flex flex-column gap-1 mt-1 mr-2" style={{ overflowY: "auto", height: "90%" }}>
                                        {data.tarifas ? (
                                            data.tarifas.map((tarifa) => (
                                                <div
                                                    key={tarifa.id}
                                                    className="tarifa-opcion d-flex py-1 mr-4 align-items-center justify-content-between position-relative"
                                                >
                                                    <div className={`${tarifa.restantes === 0 ? "barra-tarifas-disabled" : "barra-tarifas-desktop"}`} ></div>
                                                    <div className="d-flex flex-column  ms-3">
                                                        {tarifa.restantes === 0 ? (
                                                            <>
                                                                <p className=" fw-bold mb-0" style={{ color: "#D9D9D9" }}>{tarifa.concepto}</p>
                                                                <small className=" rounded d-flex align-items-center px-2" style={{ color: "#C12828", backgroundColor: "#FCDADA", paddingTop: "2px", paddingBottom: "2px" }}>
                                                                    <CloseOutlined sx={{ width: "16px", height: "17px", marginRight: "5px" }} /> Agotados
                                                                </small>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="mb-0" style={{ color: "black" }}>{tarifa.concepto}</p>
                                                                <small className="text-secondary">
                                                                    {tarifa.idtipo === 1 ? <i>Tickets</i> : <i>Vouchers</i>}{" "}
                                                                    restantes: {tarifa.restantes}
                                                                </small>
                                                                <small className="mb-0" style={{ color: "black" }}>
                                                                    $ {tarifa.precio}
                                                                </small>
                                                            </>
                                                        )}
                                                    </div>
                                                    <ButtonGroup>
                                                        <Button
                                                            className="fw-bold"
                                                            outline
                                                            onClick={() => decremento(tarifa.id)}
                                                            style={{ border: "1px solid #7D888D", width: "40px" }}
                                                        >
                                                            -
                                                        </Button>
                                                        <Button
                                                            className="fw-bold"
                                                            outline
                                                            disabled
                                                            style={{
                                                                color: "black",
                                                                border: "1px solid #7D888D",
                                                                cursor: "default",
                                                                width: "40px",
                                                            }}
                                                        >
                                                            {cantidad[tarifa.id] || 0}
                                                        </Button>
                                                        <Button
                                                            className="fw-bold"
                                                            outline
                                                            onClick={() => incremento(tarifa.id)}
                                                            style={{ border: "1px solid #7D888D", width: "40px" }}
                                                        >
                                                            +
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-warning text-center">
                                                <hr />
                                                Aún no hay tarifas.
                                                <hr />
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="" style={{ flex: 1, paddingRight: '8px' }}>
                                    <p style={{ color: "#221A60", fontSize: "20px", marginBottom: "5px" }}>Tu compra</p>
                                    <div className="d-flex flex-column px-3">
                                        <div className="mb-3">
                                            {tarifasSeleccionadas?.length > 0 ? (
                                                tarifasSeleccionadas.map((tarifa) => (
                                                    <div key={tarifa.id} className="d-flex justify-content-between align-items-center py-1 mb-2">
                                                        <div className="d-flex flex-column">
                                                            <span className="">{`${cantidad[tarifa.id]} x ${tarifa.concepto}`}</span>
                                                        </div>
                                                        <span className="">$ {calculoSubtotalTarifa(tarifa)}</span>
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="mb-0 py-4">No hay tarifas seleccionadas</p>
                                            )}
                                        </div>

                                        {tarifasSeleccionadas?.length > 0 && (
                                            <>
                                                <Divider variant="middle" sx={{ backgroundColor: "black" }} />
                                                <div className="d-flex flex-column py-4">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <p className="mb-3">
                                                            Subtotal
                                                        </p>
                                                        <p className="mb-3">
                                                            $ {subtotal}
                                                        </p>
                                                    </div>
                                                    {hayComision && (
                                                        <div className="d-flex flex-row justify-content-between">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <p className="mb-0">
                                                                    Costo
                                                                </p>
                                                                <Tooltip arrow TransitionComponent={Zoom} placement="right" title="El costo de servicio de PassGo no es reembolsable">
                                                                    <InfoOutlined sx={{ width: "18px", height: "18px", marginLeft: "5px" }} />
                                                                </Tooltip>
                                                            </div>
                                                            <p className="mb-0">
                                                                $ {comision}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                                <Divider variant="middle" sx={{ backgroundColor: "black" }} />
                                                <div className="d-flex flex-row justify-content-between py-4">
                                                    <p className="mb-3 fw-bold">Total</p>
                                                    <div className="d-flex flex-column text-right">
                                                        <p className="mb-0 ">$ {total}</p>
                                                        <span className="fw-light" style={{ fontSize: "14px" }}>El precio incluye impuestos</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end align-items-end mt-4">
                                                    <Button type="button" className="boton-submit" onClick={handleAñadirCarrito}>
                                                        {loadingCarrito
                                                            ? <CircularProgress size="18px" sx={{ color: "white" }} />
                                                            : <span>Finalizar compra <ShoppingCartOutlined sx={{ color: "white", width: "18px", height: "18px", marginLeft: "5px" }} /></span>
                                                        }
                                                    </Button>

                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                </div>
            </>
        );
    }
};
