import Button from '@mui/material/Button';
import { useContext, useEffect, useRef } from 'react';
import { Layer, Stage } from 'react-konva';
import { useParams } from 'react-router-dom';
import { ImpresionContext } from '../providers/Impresion';
// import { PaginatorContext } from '../providers/Paginator';
import DownloadIcon from '@mui/icons-material/Download';
import HelpIcon from '@mui/icons-material/Help';
import RedoIcon from '@mui/icons-material/Redo';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { api } from '../services/api';
import ImageField from './Impresion/ImageField';
import QRField from './Impresion/QRField';
import TextField from './Impresion/TextField';
import IconButton from '@mui/material/IconButton';


const Canva = ({ width, height }) => {
  const {
    setState,
    state,
    scale,
    // setEditSucces,
    // setIndex,
    isCreated,
    setIsCreated,
    textArea,
    setTextArea,
    setOpenFont,
    setOpenImage,
    setOpenQr,
    setOpenTemplates,
    setOpenFontToolBar,
    elements,
    setElements,
    selectedId,
    setSelectedId,
    setSaved,
    setEdited,
    setError,
    dimensionContainer,
    // setDimensionContainer,
    // setScale,
    history,
    setHistory,
    indexHistory,
    setIndexHistory,
    uploadHistory,
    ableUndo,
    setAbleUndo,
    ableRedo,
    setAbleRedo,
    // typeTicket,
    // setTypeTicket,
    driverObj,
    isMoving,
    setIsMoving,
  } = useContext(ImpresionContext);

  // const { setIsToggled } = useContext(PaginatorContext)

  const notify = (type, message, position = "bottom-center") => {
    const options = {
      position,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  }


  const stage = useRef(null);
  const textAreaRef = useRef(null)
  const layerRef = useRef(null);


  const param = useParams()

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
      setOpenFontToolBar(false);
      setOpenFont(false);
      setOpenImage(false)
      setOpenQr(false)
      setOpenTemplates(false)
      setTextArea(false)
    }
  };

  const HandleDeleteElement = (e) => {
    if (selectedId) {
      const newElements = elements.filter((element) => element.id !== selectedId);
      setElements(newElements);
      setSelectedId(null);
      setTextArea(false);
      setOpenFontToolBar(false);

    }
  }

  const HandleZIndex = (num) => {
    const index = elements?.findIndex((element) => element.id === selectedId)
    const newElements = elements.slice();
    const element = newElements[index];
    newElements.splice(index, 1);
    newElements.splice(index + num, 0, element);
    setElements(newElements);
  }

  const SaveStage = () => {
    let stringDimensiones = '';

    const newElements = elements?.map((element) => {
      return {
        ...element,
        x: element.x / scale,
        y: element.y / scale,
        width: element.width / scale,
        height: element.height / scale,
        fontSize: element.fontSize / (0.3625 * scale)
      }
    })

    let data = {
      type: dimensionContainer.type,
      elements: newElements
    }

    const callApi = async () => {
      try {
        if (!(data instanceof String)) {
          stringDimensiones = JSON.stringify(data)
        }
        const response = await api.eventos.createImpresion(param.id, { json: stringDimensiones });

        if (response.status === "success") {
          setIsCreated(true)//estado maneja el mostrados de botones Guardar o Editar
          setSaved(true);//estado que maneja el alert
          notify("success", "El diseño se creó con éxito")
        }

      } catch (error) {
        setState({
          ...state,
          error: error
        });
        notify("error", "No se puede guardar el diseño")
      }
    }
    callApi();

  }

  const HandleEdit = () => {
    let stringDimensiones = ''

    let newElements = elements?.map((element) => {
      return {
        ...element,
        x: element.x / scale,
        y: element.y / scale,
        width: element.width / scale,
        height: element.height / scale,
        fontSize: element.fontSize / (0.3625 * scale)
      }
    })

    let data = {
      type: dimensionContainer.type,
      elements: newElements
    }

    const callApi = async () => {
      try {
        if (!(data instanceof String)) {
          stringDimensiones = JSON.stringify(data)
        }

        const response = await api.eventos.updateImpresion(param.id, stringDimensiones);

        if (response.status === "success") {
          setEdited(true)//estado que maneja el alert
          notify("success", "Se guardaron los cambios")
        } else {
          setError(true)
          notify("error", "No se guardaron los cambios")
        }

      } catch (error) {
        notify("error", "No se guardaron los cambios")
        setError(true)
        setState({
          ...state,
          error: error
        });
      }
    }
    callApi();

  }

  const HandleDownload = () => {
    const callApi = async () => {
      try {
        const response = await api.eventos.downloadImpresion(param.id);
        if (!response) {
          notify("error", "Error al realizar la descarga")
          throw new Error('Error al descargar el PDF')
        }
        const blob = await response;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `impresion_${param.id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        notify("success", "La descarga se realizó con éxito")

      } catch (error) {
        console.error('Error al descargar el PDF:', error.message);
        throw error;
      }
    }
    callApi()
    console.log('Se descargó con exito');
  }

  useEffect(() => {
    const newData = elements?.map((element) => {
      return {
        ...element,
        x: element.x * scale,
        y: element.y * scale,
        width: element.width * scale,
        height: element.height * scale,
        fontSize: element.fontSize * (0.3625 * scale)
      }
    })
    setElements(newData)
    setHistory([newData])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale])

  const HandleUndo = () => {
    setAbleRedo(true)

    if (indexHistory >= history.length - 1) {
      setAbleUndo(false)
      return // No puedes deshacer más allá del inicio del historial
    }
    // Calcula el nuevo índice de historial
    const newIndexHistory = indexHistory + 1

    // Accede al estado correspondiente en el historial
    const newElements = history[history.length - 1 - newIndexHistory]

    // Actualiza el estado con el nuevo valor
    setElements(newElements)
    setIndexHistory(newIndexHistory)

    if (newIndexHistory >= history.length - 1) {
      setAbleUndo(false)
      return // No puedes deshacer más allá del inicio del historial
    }

  }

  const HandleRedo = () => {
    setAbleUndo(true)

    if (indexHistory <= 0) {
      setAbleRedo(false)
      return // No puedes rehacer más allá del final del historial
    }

    // Calcula el nuevo índice de historial
    const newIndexHistory = indexHistory - 1;

    // Accede al estado correspondiente en el historial
    const newElements = history[history.length - 1 - newIndexHistory];

    // Actualiza el estado con el nuevo valor
    setElements(newElements);
    setIndexHistory(newIndexHistory);

    if (newIndexHistory <= 0) {
      setAbleRedo(false)
      return // No puedes deshacer más allá del inicio del historial
    }
  };

  const onPressDownKey = () => {
    setSelectedId(null);
    setOpenFontToolBar(false);
    setOpenFont(false);
    setOpenImage(false)
    setOpenQr(false)
    setOpenTemplates(false)
    setTextArea(false)
  };

  useEffect(() => {
    const handlePressKey = (event) => {
      if (event.key === 'Escape') {
        onPressDownKey()

      }
      if (event.ctrlKey && event.key === 'z') {
        HandleUndo()

      }
      if (event.ctrlKey && event.key === 'y') {
        HandleRedo()
      }
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault()
        if (!isCreated) {
          SaveStage()
          return
        }
        HandleEdit()
      }
    };

    window.addEventListener('keydown', handlePressKey);

    return () => {
      window.removeEventListener('keydown', handlePressKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);


  const theme = createTheme({
    palette: {
      black: {
        main: grey[900],
        light: grey[200],
        dark: grey[800],
        contrastText: grey[300],
      },
    },
  })


  return (
    <>
      <div className='position-absolute' style={{ zIndex: 10, top: '10px', right: '10px' }}>
        <div className='d-flex gap-1'>
          <div className='d-flex pr-1 gap-1'>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="black" disabled={!ableUndo} onClick={HandleUndo}>
                <UndoIcon />
              </Button>
              <Button variant="contained" color="black" disabled={!ableRedo} onClick={HandleRedo}>
                <RedoIcon />
              </Button>
            </ThemeProvider>
          </div>
          {isCreated ? (
            <>
              <Button
                variant="contained"
                color='primary'
                onClick={HandleEdit}
                type='button'
                startIcon={<SaveIcon />}>
                Guardar
              </Button>
              <Button
                variant="outlined"
                color='primary'
                onClick={HandleDownload}
                type='button'
                startIcon={<DownloadIcon />}>
                Descargar Entradas
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color='primary'
              onClick={SaveStage}
              startIcon={<SaveIcon />}
              type='button' className=''>
              Guardar Diseño
            </Button>
          )
          }
        </div>
      </div>
      <div style={{ paddingLeft: '300px' }}>
        <div className='bg-body position-relative'
          style={{ border: '2px dashed gray' }}
        >
          <Stage
            width={width}
            height={height}
            ref={stage}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
          >
            <Layer ref={layerRef}>

              {elements?.map((element) => {
                if (element.type === 'image') {
                  return (
                    <ImageField
                      key={element.id}
                      imgProp={element}
                      isSelected={element.id === selectedId}

                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(false);
                      }}

                      onChange={(newAttrs) => {
                        const newElements = elements.slice();
                        const index = elements?.findIndex((i) => i.id === element.id)

                        newElements[index] = newAttrs;
                        setElements(newElements);
                        uploadHistory(newElements)
                      }}
                    />
                  )
                }
                if (element.type === 'qr') {
                  return (
                    <QRField
                      key={element.id}
                      qrProp={element}
                      isSelected={element.id === selectedId}

                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(false);
                      }}

                      onChange={(newAttrs) => {
                        const newQrField = elements.slice();
                        const index = elements?.findIndex((q) => q.id === element.id)
                        newQrField[index] = newAttrs;
                        setElements(newQrField);
                        uploadHistory(newQrField)
                      }}
                    />
                  )
                }
                if (
                  element.type === 'text' |
                  element.type === 'text-evento' |
                  element.type === 'text-codigo' |
                  element.type === 'text-nombre'
                ) {
                  return (
                    <TextField
                      key={element.id}
                      textProp={element}
                      isSelected={element.id === selectedId}
                      onSelect={() => {
                        setSelectedId(element.id);
                        setOpenFont(true);
                        setOpenImage(false);
                        setOpenQr(false);
                        setOpenTemplates(false);
                      }}
                      onChange={(newAttrs) => {
                        const newTextField = elements.slice();
                        const index = elements?.findIndex((t) => t.id === element.id)
                        newTextField[index] = newAttrs;
                        setElements(newTextField)
                        uploadHistory(newTextField)
                      }}
                    />
                  )
                }
                return null
              })
              }
            </Layer>
          </Stage>

          {textArea && (
            elements?.map((text) => {
              let condition = text.type === "text" ||
                text.type === "text-evento" ||
                text.type === "text-codigo" ||
                text.type === "text-nombre"

              if (condition && text.id === selectedId) {
                return (
                  < textarea
                    key={text.id}
                    id={text.id}
                    ref={textAreaRef}
                    value={text.text}
                    style={{
                      position: 'absolute',
                      top: `${text.y}px`,
                      left: `${text.x}px`,
                      width: `${text.width}px`,
                      height: `${text.height}px`,
                      border: 'none',
                      padding: '0px',
                      margin: '0px',
                      fontSize: `${text.fontSize}px`,
                      outline: 'true',
                      background: 'none',
                      transform: ` rotate(${text.rotation}deg)`,
                      fontFamily: `${text.fontFamily}`,
                      color: `${text.fill}`,
                      lineHeight: 1,
                      fontWeight: `${text.fontStyle === ('bold' | 'bold italic') ? 'bold' : 'normal'} `,
                      fontStyle: `${text.fontStyle === ('italic' | 'bold italic') ? 'italic' : 'normal'} `,
                      textDecoration: `${text.textDecoration}`,
                    }}

                    onChange={(e) => {
                      const newTextField = elements.slice()
                      const index = elements?.findIndex((t) => t.id === e.target.id)
                      newTextField[index] = { ...newTextField[index], text: e.target.value }
                      setElements(newTextField)
                      uploadHistory(newTextField)
                    }}

                    onKeyDown={
                      (e) => {
                        if (e.key === 'Enter') {
                          setTextArea(false)
                        }
                      }
                    }
                  />
                )
              }
              return null
            })
          )}

          {selectedId && elements && !isMoving &&
            (
              elements?.map((element) => {
                if (element.id === selectedId) {
                  return (
                    <div
                      className='position-absolute p-1 w-auto h-auto rounded-3 border d-flex bg-light align-items-center gap-2'
                      style={{
                        top: `${element.height + element.y + 12}px`,
                        left: `${element.width / 2 + element.x - 64}px`,
                      }}
                    >
                      <button className='btn btn-danger p-0' onClick={HandleDeleteElement}>
                        <div className='d-flex align-items-center p-1'>
                          <i className='material-symbols-outlined' >delete</i>
                        </div>
                      </button>
                      <button className='btn btn-outline-secondary p-0' onClick={() => HandleZIndex(1)}>
                        <div className='d-flex align-items-center p-1'>
                          <i className="material-symbols-outlined">stat_2</i>
                        </div>
                      </button>
                      <button className='btn btn-outline-secondary p-0' onClick={() => HandleZIndex(-1)}>
                        <div className='d-flex align-items-center p-1'>
                          <i className="material-symbols-outlined">stat_minus_2</i>
                        </div>
                      </button>
                    </div>
                  )
                }
                return null
              }
              )
            )
          }
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 10 + 'px',
          right: 10 + 'px',
          zIndex: 50,
        }}>
        <IconButton
          sx={{ padding: 0 }}
          color="warning"
          onClick={() => { driverObj.drive() }}>
          <HelpIcon fontSize="large" />
        </IconButton>
      </div>
    </>
  );
};

export default Canva;