import React, { useState, useEffect, useRef } from "react";
import Loading from "../../components/Loading";
import { api } from "../../services/api"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import "./Styles.css"
import moment from 'moment';
import QrReader from 'react-weblineindia-qrcode-scanner'
import { Container, ListGroup, Button, Card, CardImg, CardBody, ListGroupItem, CardTitle, Alert, CardSubtitle } from "reactstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";

export const QrVoucher = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const beepSoundOkRef = useRef(null);
  const beepSoundErrRef = useRef(null);
  const [evento, setEvento] = useState();
  const [voucher, setVoucher] = useState({
    apellido: "Apellido",
    nombre: "y Nombre"
  });
  const [acceso, setAcceso] = useState();
  const [success, setSuccess] = useState();
  const [scan, setScan] = useState(true);
  const [alerta, setAlerta] = useState();

  const [showCamera, setShowCamera] = useState(false)
  const [showScanner, setShowScanner] = useState(true)
  const inputRef = useRef(null)
  const [scannerValue, setScannerValue] = useState("")


  const [qr, setQr] = useState();
  const param = useParams();

  const callApi = async () => {
    try {
      const response = await api.eventos.get(param.id);

      if (response.status === "success") {
        setEvento(response.data.evento);
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  useEffect(() => {
    beepSoundOkRef.current = new Audio('/ok.mp3');
    beepSoundOkRef.current.preload = 'auto';
    beepSoundErrRef.current = new Audio('/error.wav');
    beepSoundErrRef.current.preload = 'auto';
    callApi();
  }, []);

  const callQr = async (data) => {
    setQr(data);

    const response = await api.accesos.setVoucher(param.id, data);
    console.log(data)
    console.log(response)

    if (response.status === "success") {
      await beepSoundOkRef.current.play();
      setSuccess(true);
      setAcceso(response.data.acceso_voucher);
      setVoucher(response.data.acceso_voucher.voucher);
    }
    else {
      await beepSoundErrRef.current.play();
      setSuccess(false);
      setAcceso(response.data.acceso_voucher);
      setVoucher(response.data.acceso_voucher.voucher);
    }
  };

  const handleScannerRead = (e) => {
    callQr(e.target.value)
    inputRef.current.focus()
  }

  const previewStyle = {
    width: "100%",
    height: "40vh",
  }

  const handleScan = (data) => {
    if (data) {
      setScan(false);
      callQr(data);
    }
  }

  const handleError = (err) => {
    console.error(err);
  }


  const handleSelectOption = (e) => {
    const option = e.currentTarget.id
    if (option === "camera") {
      setShowScanner(false)
      setShowCamera(true)
    }
    if (option === "scanner") {
      setShowScanner(true)
      setShowCamera(false)
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  return (
    <>
      {evento &&
        <Container fluid="sm" className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="w-100">
              <nav
                className="w-100 d-flex justify-content-evenly "
              >
                <div
                  id="scanner"
                  className="btn rounded-0 d-flex aligm-items-center gap-1"
                  style={{
                    borderBottom: showScanner ? '2px solid black' : ''
                  }}
                  onClick={(e) => handleSelectOption(e)}
                >
                  <i className="material-symbols-outlined">qr_code_scanner</i>
                  Escaner
                </div>
                <div
                  id="camera"
                  onClick={(e) => handleSelectOption(e)}
                  className="btn rounded-0 d-flex aligm-items-center gap-1"
                  style={{
                    borderBottom: showCamera ? '2px solid black' : ''
                  }}
                >
                  <i className="material-symbols-outlined">photo_camera</i>
                  Cámara
                </div>
              </nav>
            </div>
            {showScanner &&
              <div className="w-100 p-2 d-flex flex-column justify-content-center align-items-center gap-2">
                <input
                  ref={inputRef}
                  autoFocus
                  className="w-auto"
                  value={scannerValue}
                  placeholder="Escanea algún código"
                  style={{
                    border: "none",
                  }}
                  onChange={(e) => handleScannerRead(e)}
                />
                <div className="w-100 d-flex justify-content-center align-items-center gap-3">
                  <div className="w-50 d-flex justify-content-center align-items-end gap-2">
                    {success &&
                      <CardImg
                        alt="OK"
                        src="/qrverde.png"
                        top
                        style={{ width: 100 + "px", height: 100 + "px" }}
                      />
                    }
                    {/* {!success &&
                      <CardImg
                        alt="Error"
                        src="/qrrojo.png"
                        top
                        style={{ width: 100 + "px", height: 100 + "px" }}
                      />
                    } */}
                    {!success &&
                      <CardImg
                        alt="Error"
                        src="/qrgris.png"
                        top
                        style={{ width: 100 + "px", height: 100 + "px" }}
                      />
                    }
                  </div>
                  <div className="w-50">
                    {voucher &&
                      <CardBody>
                        <CardTitle className="text-left fs-6">
                          {qr}
                        </CardTitle>

                        <CardSubtitle tag="h3" className="text-left">
                          {voucher.apellido} {voucher.nombre}
                        </CardSubtitle>
                      </CardBody>
                    }
                  </div>
                </div>
              </div>

            }
            {showCamera && scan &&
              <QrReader
                delay={100}
                legacyMode={false}
                style={previewStyle}
                onError={handleError}
                onScan={handleScan}
              />
            }
            <div className="w-100 d-flex justify-content-center align-items-center gap-2">
              {showCamera && voucher && !scan &&
                <>
                  <div className="w-50">
                    {success
                      ?
                      <CardImg
                        alt="OK"
                        src="/qrverde.png"
                        top
                        style={{ width: '100px' }}
                      />
                      :
                      <CardImg
                        alt="Error"
                        src="/qrrojo.png"
                        top
                        style={{ width: '100px' }}
                      />
                    }
                  </div>
                  <div className="w-50">
                    <CardBody>
                      <CardTitle className="text-left fs-6">
                        {qr}
                      </CardTitle>
                      <CardSubtitle tag="h3" className="text-left">
                        {voucher.apellido} {voucher.nombre}
                      </CardSubtitle>
                    </CardBody>
                  </div>
                </>
              }
            </div>
            {voucher &&
              <>
                <ListGroup flush>
                  {acceso &&
                    <ListGroupItem>
                      Reclamado: {acceso.reclamado} {moment.parseZone(acceso.fecha).format("HH:mm:ss")}
                    </ListGroupItem>
                  }
                </ListGroup>

                <CardBody>
                  <Button
                    color="primary"
                    onClick={() => {
                      // setScan(true)
                      // setVoucher({
                      //   apellido: "Apellido",
                      //   nombre: "y Nombre"
                      // })
                      // setAcceso(null)
                      // setQr(null)
                    }}
                  >
                    Volver <FontAwesomeIcon icon={faQrcode} />
                  </Button>
                </CardBody>
              </>
            }

            {/* <Alert color="success" isOpen={alerta > ""}>
              {alerta}
            </Alert> */}
          </div >
        </Container>
      }
    </>
  )
}

export default withAuthenticationRequired(QrVoucher, {
  onRedirecting: () => <Loading />,
});
