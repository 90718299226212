import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PassGoOutlined from "../../src/assets/PassGoOutlined.png";
import CookiePreferences from "./cookies/CookiePreferences";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  const [t] = useTranslation("global");
  const history = useHistory();

  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const handleOpenCookiePreferences = () => {
    setShowCookieBanner(true);
  };

  const handleCloseCookiePreferences = () => {
    setShowCookieBanner(false);
  };

  const handleHelpClick = () => {
    history.push('/ayuda');
  }

  return (
    <footer id="footer" className="p-3 text-center" style={{ backgroundColor: "#0B0062" }}>
      <div className="mt-2 mb-4">
        <img src={`${PassGoOutlined}`} width="110px" alt="Logo" />
      </div>
      <p style={{ color: "white" }}>
        {t("footer.footer")}
      </p>
      <button
        style={{ color: "#ff6700", border: "none", background: "transparent", cursor: "pointer", fontSize: "14px", textDecoration: "underline" }} 
        onClick={handleOpenCookiePreferences}
      >
        Administrar preferencias de cookies
      </button>

      <button
        style={{ color: "#ff6700", background: "transparent", cursor: "pointer", fontSize: "14px", textDecoration: "underline", display:"block", margin:"10px auto"}} 
        onClick={handleHelpClick}
      >
        Ayuda
      </button>

      {showCookieBanner && <CookiePreferences onClose={handleCloseCookiePreferences} />}
    </footer>
  );
};

export default Footer;