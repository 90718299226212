import { Button, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useEffect, useState } from "react"
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap"
import { api } from "../../../services/api";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { TarifaDetalles } from "../../../components/modales/TarifaDetalles"
import { toast } from 'react-toastify';
import { useMediaQuery } from "react-responsive";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';

export const SegundoPaso = ({ eventId: propEventId }) => {
  const location = useLocation(); 
  const { eventId: stateEventId } = location.state || {};
  const eventId = stateEventId || propEventId;
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [tarifasTipo, setTarifasTipo] = useState([]);
  const [t] = useTranslation("global");
  const [selectedOption, setSelectedOption] = useState('de pago');
  const isMobile = useMediaQuery({ query: `(max-width: 610px)` });

  const initForm = {
    idevento: eventId,
    idmoneda: 1,
    concepto: '',
    precio: 0,
    unidad: '',
    idtipo: '',
    limite_persona: 10,
    limite_online: 50,
    limite_maximo: 100,
    asumir_comision: false
  }
  const [formData, setFormData] = useState(initForm);

  const [formErrors, setFormErrors] = useState({
    concepto: false,
    precio: false,
    unidad: false,
    limite_persona: false,
    limite_online: false,
    limite_maximo: false,
  });

  const callTipoTarifas = async () => {
    try {
      const response = await api.tarifas.GetAllTarifasTipos();
      if (response.status === "success") {
        setTarifasTipo(response.data.tarifasTipo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    callTipoTarifas();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "limite_persona" || name === "precio" || name === "limite_online" || name === "limite_maximo") {
      if (/^\d*\.?\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (value.trim() !== "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {
      concepto: formData.concepto === '',
      precio: selectedOption === 'de pago' ? !formData.precio : false,
      unidad: formData.unidad === '',
      limite_persona: formData.limite_persona === '',
      limite_online: formData.limite_online === '',
      limite_maximo: formData.limite_maximo === '',
    };
    setFormErrors(errors);

    const isValid = Object.values(errors).every(error => !error);

    const newTarifa = {
      ...formData,
      idevento: parseInt(eventId),
      precio: parseFloat(formData.precio),
      idtipo: parseInt(formData.idtipo),
      limite_persona: parseInt(formData.limite_persona),
      limite_online: parseInt(formData.limite_online),
      limite_maximo: parseInt(formData.limite_maximo),
    }

    if (isValid) {
      try {
        const response = await api.tarifas.createTarifa(newTarifa);
        if (response.status === "success") {
          setShowOffcanvas(showOffcanvas);
          setFormData(initForm)
          toast.success("Tarifa creada correctamente.", {
            position: "top-right",
            autoClose: 2000,
          });
        }
        else {
          throw new Error(response.message);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
        });
      } finally {
        setShowOffcanvas(!showOffcanvas)
      }
    }
  }

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      asumir_comision: checked
    }));
  }

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setFormErrors(false);
    if (option === 'gratis') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        precio: 0
      }));
    }
  }

  const toggleOffcanvas = (opcion) => {
    if (opcionSeleccionada === opcion) {
      setShowOffcanvas(!showOffcanvas);
    } else {
      setShowOffcanvas(true);
    }
    setOpcionSeleccionada(opcion);
    setFormErrors(false);
    setSelectedOption('de pago')
    setFormData((prevFormData) => ({
      ...prevFormData,
      idtipo: opcion === 'tickets' ? 1 : 2
    }));
  };

  const closeOffcanvas = () => {
    setShowOffcanvas(false);
  };
  
  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      gap: "30px"
    }}>
      <div style={{
        width: "270px",
        padding: "20px", 
        borderRadius: "15px",
        border: "1px solid #D9D9D9",

      }}>
        <div style={{
          width: "65px",
          height: "65px",
          marginBottom: "20px", 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#ede8fd"
        }}
          className='rounded-4'>
          <ConfirmationNumberOutlinedIcon style={{
            fontSize: "35px",
            color: "#5c3acb"
          }}/>
        </div>
        <div>
          <h5>Tickets</h5>
          <p style={{
            fontSize: "13px",
            marginBottom: "5px"
          }}>Administra el acceso a tu evento creando y personalizando tickets. Define el nombre, precio, cantidad disponible y límite de compra para cada tipo de ticket.</p>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "end"
        }}>
          <AddCircleOutlineOutlinedIcon 
            color="action"
            onClick={() => toggleOffcanvas("tickets")}
            style={{cursor: "pointer", fontSize: "30px"}}/>
        </div>
      </div>
      <div style={{
        width: "270px",
        padding: "20px", 
        borderRadius: "15px",
        border: "1px solid #D9D9D9"
      }}>
        <div style={{
          width: "65px",
          height: "65px",
          marginBottom: "20px", 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#def2ff"
        }}
          className='rounded-4'>
          <FastfoodOutlinedIcon style={{
            fontSize: "35px",
            color: "#249ae6"
          }}/>
        </div>
        <div>
          <h5>Consumiciones</h5>
          <p style={{
            fontSize: "13px",
            marginBottom: "5px"
          }}>Gestiona las consumiciones para tu evento. Crea opciones de comida y bebida, estableciendo nombre, precio, cantidad disponible y límites de compra.</p>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "end"
        }}>
          <AddCircleOutlineOutlinedIcon 
            color="action"
            onClick={() => toggleOffcanvas("Consumicion")}
            style={{cursor: "pointer", fontSize: "30px"}}/>
        </div>
      </div>
        <Offcanvas
          backdrop={false}
          direction="end"
          isOpen={showOffcanvas}
          toggle={toggleOffcanvas}
          style={{
            width: isMobile && "100%"
          }}
        >
          <OffcanvasHeader toggle={closeOffcanvas}>
            {opcionSeleccionada === 'tickets' ? (
              "Crear tickets"
            ) : (
              "Crear consumiciones"
            )}
          </OffcanvasHeader>
          <OffcanvasBody>
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-evenly mb-3">
                  <Button
                    variant={selectedOption === "de pago" ? "contained" : "outlined"}
                    size="large"
                    onClick={() => handleOptionChange('de pago')}
                  >
                    De pago
                  </Button>
                  <Button 
                    variant={selectedOption === "gratis" ? "contained" : "outlined"}
                    size="large"
                    onClick={() => handleOptionChange('gratis')}
                  >
                    Gratis
                  </Button>
              </div>
              
              <TextField 
                label={t("rates.concept")}
                variant="outlined"
                fullWidth
                name="concepto" 
                onChange={handleChange}
                margin="normal"
                error={formErrors.concepto}
                helperText={formErrors.concepto && 'Este campo es obligatorio'}
              />

              <TextField 
                label={t("rates.price")} 
                variant="outlined"
                fullWidth
                name="precio" 
                onChange={handleChange}
                value={formData.precio || ''}
                disabled={selectedOption === 'gratis'}
                margin="normal"
                error={selectedOption === 'de pago' && !!formErrors.precio}
                helperText={formErrors.precio && 'Este campo es obligatorio'}
              />
              {formData.precio > 0 && (
                <FormControlLabel 
                  control={<Checkbox checked={formData.asumir_comision} />} 
                  label={t("tickets.assumeTheCosts")} 
                  name="asumirCostos"
                  onChange={handleCheckboxChange}
                />
              )}
              <TextField 
                label={t("rates.unit")}
                variant="outlined" 
                fullWidth
                name="unidad" 
                onChange={handleChange}
                error={formErrors.unidad}
                helperText={formErrors.unidad && 'Este campo es obligatorio'}
              />
              
              <TextField 
                label="Límite de compra por pedido"
                variant="outlined" 
                fullWidth
                name="limite_persona" 
                onChange={handleChange}
                value={formData.limite_persona}
                margin="normal"
                error={!!formErrors.limite_persona}
                helperText={formErrors.limite_persona && 'Este campo es obligatorio'}
              />
              
              <TextField 
                label="Limite de vantas online"
                variant="outlined" 
                fullWidth
                name="limite_online" 
                onChange={handleChange}
                value={formData.limite_online}
                margin="normal"
                error={formErrors.limite_online}
                helperText={formErrors.limite_online && 'Este campo es obligatorio'}
              />
              
              <TextField 
                label="Limite de tarifas de consumicion"
                variant="outlined" 
                fullWidth
                name="limite_maximo" 
                onChange={handleChange}
                value={formData.limite_maximo}
                margin="normal"
                error={formErrors.limite_maximo}
                helperText={formErrors.limite_maximo && 'Este campo es obligatorio'}
              />
              
              <Button 
                type="submit"
                variant="contained"
                style={{ margin: "5px" }}
              >
                {t("button.create")}
              </Button>
            </form>
          </OffcanvasBody>
        </Offcanvas>
      </div>

  )
}