import React, { useState } from 'react'
import { api } from "../../services/api";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import { useAuth0 } from "@auth0/auth0-react";

initMercadoPago('APP_USR-9b1c297a-187c-4f1d-aa68-8cbaaca61b7c', {
  locale: "es-AR"
});

const MercadoPago = (props) => {
  // Usuario activo - Current user
  const { user, isAuthenticated } = useAuth0();
  const [preferenceID, setPreferenceID] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const id = parseInt(props?.idcarrito);
      const response = await api.mercadopago.createPago({
        idcarrito: id,
      });
      
      if (response.status === "success") {
        setPreferenceID(response.data.id)
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Wallet initialization={{ preferenceId: preferenceID }}
      customization={{
        texts:{ valueProp: 'smart_option'}
      }}
    />
  )
}

export default MercadoPago;