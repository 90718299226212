import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../services/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, FormControl, Alert, TextField, CircularProgress } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const EditPublicacion = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    eventoId: "",
    titulo: "",
    subtitulo: "",
    descripcion: "",
    desde: "",
    hasta: "",
  });
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const [t] = useTranslation("global");
  const [lengthDescrip, setLengthDescrip] = useState("");
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);

  const [mediaUrls, setMediaUrls] = useState([]);
  const [mediaTypes, setMediaTypes] = useState([]);
  const [mediaFormats, setMediaFormats] = useState([]);
  const [mediaId, setMediaId] = useState([]);
  const [files, setFiles] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPublicacion = async () => {
      try {
        const response = await api.publicaciones.get(id);
        if (response.status === "success") {
          const data = response.data.publicacion;
          setFormData({
            eventoId: data.idevento,
            titulo: data.titulo,
            subtitulo: data.subtitulo,
            descripcion: data.descripcion,
            desde: moment(data.desde).format("YYYY-MM-DD"),
            hasta: moment(data.hasta).format("YYYY-MM-DD"),
          });

          setMediaUrls(data.attachs.map(attach => attach.url));
          setMediaTypes(data.attachs.map(attach => attach.type));
          setMediaFormats(data.attachs.map(attach => attach.format));
          setMediaId(data.attachs.map(attach => attach.public_id));
        }
      } catch (error) {
        setError("Error al cargar los datos de la publicación.");
      }
    };
    fetchPublicacion();
  }, [id]);


  const handleCancel = () => {
    setFormData({
      eventoId: "",
      titulo: "",
      subtitulo: "",
      descripcion: "",
      desde: "",
      hasta: "",
    });
    setMediaUrls("");
    setMediaTypes("");
    setMediaFormats("");
    setMediaId("");
    setError("");
    setSubmitted(false);
    history.goBack();
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));

    setLengthDescrip(value.length);
  };

  const handleAddFileClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*,video/*";
    fileInput.multiple = true;
    fileInput.onchange = handleFileChange;
    fileInput.click();
  };

  useEffect(() => {
    if (mediaUrls.length > 0) {
      setSelectedFileIndex(0);
    } else {
      setSelectedFileIndex(null);
    }
  }, [mediaUrls]);

  const handleFileChange = async (e) => {
    const maxSizeFile = 10 * 1024 * 1024;
    const maxFiles = 5;
    const selectedFiles = Array.from(e.target.files);

    if (files.length + selectedFiles.length > maxFiles) {
      alert(`Solo puedes subir como máximo ${maxFiles} archivos.`);
      return;
    }

    const validaFiles = [];
    selectedFiles.forEach((file) => {
      if (file.size <= maxSizeFile) {
        validaFiles.push(file);
      } else {
        console.error(`El archivo ${file.name} supera el límite de tamaño de 10MB`);
        alert(`El archivo ${file.name} supera el límite de tamaño de 10MB`);
      }
    });

    if (validaFiles.length === 0) return;

    setLoading(true);

    setFiles((prevFiles) => {
      const combinedFiles = [...prevFiles, ...validaFiles];

      if (combinedFiles.length > maxFiles) {
        alert(`Solo puedes subir como máximo ${maxFiles} archivos.`);
        return prevFiles;
      }

      if (selectedFileIndex === null && validaFiles.length > 0) {
        setSelectedFileIndex(prevFiles.length);
      }

      return combinedFiles;
    });

    const formData = new FormData();
    validaFiles.forEach((file) => {
      formData.append("files", file);
    });


    try {
      const response = await api.media.upload(formData);
      if (response.status === "success") {
        const medias = response.data.media;
        setMediaUrls(medias.map((media) => media.url));
        setMediaTypes(medias.map((media) => media.type));
        setMediaFormats(medias.map((media) => media.format));
        setMediaId(medias.map((media) => media.public_id));
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteMedia = async (index) => {
    const publicId = mediaId[index];

    try {
      const response = await api.media.delete({ public_id: publicId });
      if (response.status === "success") {
        setMediaUrls(mediaUrls.filter((_, i) => i !== index));
        setMediaTypes(mediaTypes.filter((_, i) => i !== index));
        setMediaFormats(mediaFormats.filter((_, i) => i !== index));
        setMediaId(mediaId.filter((_, i) => i !== index));

        setFiles(files.filter((_, i) => i !== index));

        if (mediaUrls.length > 1) {
          setSelectedFileIndex(0);
        } else {
          setSelectedFileIndex(null);
        }
      }
    } catch (error) {
      console.error("Error deleting media:", error);
    }
  };


  const handleThumbnailClick = (index) => {
    setSelectedFileIndex(index);
  };

  const renderFilePreview = (url, index) => {

    const isImage = mediaTypes[index] && (mediaTypes[index].toLowerCase().includes('image') || mediaTypes[index].toLowerCase().includes('img'));
    const isVideo = mediaTypes[index] && mediaTypes[index].toLowerCase().includes('video');
    const fileURL = url;

    const thumbnailClass =
      selectedFileIndex === index ? "thumbnail selected" : "thumbnail";

    return (
      <div
        key={index}
        className={thumbnailClass}
        onClick={() => handleThumbnailClick(index)}
      >
        {isImage && <img src={fileURL} alt={`Preview ${index}`} />}
        {isVideo && <video src={fileURL} />}
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hoy = new Date();

    const newErrors = [];

    if (formData.titulo.length > 50) newErrors.titulo = "El titulo de la publicacion no puede exceder 50 caracteres."
    if (formData.titulo.length < 5) newErrors.titulo = "El titulo de la publicacion debe tener al menos 5 caracteres."

    if (formData.descripcion.trim() === "") newErrors.descripcion = "La descripción es obligatoria."
    if (formData.descripcion.length > 80) newErrors.descripcion = "La descripción no puede superar 80 caracteres."
    if (formData.descripcion.length < 5) newErrors.descripcion = "La descripción necesita al menos 5 caracteres."

    if (formData.desde === "" || formData.hasta === "") newErrors.fechas = "Las fecha de inicio y finalizacion de la publicacion son obligatorias."
    if (formData.desde < hoy) newErrors.desde = "La fecha de inicio de la publicacion no puede ser anterior a hoy."

    if (mediaUrls.length === 0) newErrors.files = "Por favor, sube un archivo.";

    // detiene el envio si hay errores
    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    const data = {
      id: parseInt(id),
      idevento: formData.eventoId,
      titulo: formData.titulo,
      subtitulo: formData.subtitulo,
      descripcion: formData.descripcion,
      desde: moment(formData.desde).clone().local().format("YYYY-MM-DDTHH:mm:ss-03:00"),
      hasta: moment(formData.hasta).clone().local().format("YYYY-MM-DDTHH:mm:ss-03:00"),
      attachs: mediaUrls.map((url, index) => ({
        url: url,
        type: mediaTypes[index],
        format: mediaFormats[index],
        public_id: mediaId[index],
      })),
    };

    try {
      const response = await api.publicaciones.updatePublicacion(data);

      if (response.status === "success") {
        setFormData({
          eventoId: "",
          titulo: "",
          subtitulo: "",
          descripcion: "",
          desde: "",
          hasta: "",
        });
        setMediaUrls("");
        setMediaTypes("");
        setMediaFormats("");
        setMediaId("");
        setError("");
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
          history.goBack();
        }, 1500);
      } else {
        console.error("Error submitting form:", error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px", padding: "0 10px" }}>
      <div style={{ maxWidth: "600px", width: "100%" }}>
        <h2>{t("publications.editPublication")}</h2>
        <form id="form-publicacion" onSubmit={handleSubmit} className="mt-4">
          <FormControl sx={{ width: "100%" }}>
            <p className="subtitulo-seccion-evento-expanded">Título <span className="text-danger">*</span></p>
            <p className="descrip-seccion-evento-expanded mb-0">Ingrese un titulo para su publicacion</p>
            <TextField
              sx={{
                marginTop: "5px",
                marginBottom: "15px",
                input: {
                  padding: "10px 15px"
                }
              }}
              type="text"
              id="titulo"
              name="titulo"
              value={formData.titulo}
              onChange={handleInputChange}
            />
            <>
              {error.titulo &&
                <Alert severity="error"
                  sx={{
                    marginBottom: "10px"
                  }}>{error.titulo}</Alert>
              }
            </>
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <p className="subtitulo-seccion-evento-expanded">Subtitulo</p>
            <TextField
              sx={{
                marginTop: "5px",
                marginBottom: "15px",
                input: {
                  padding: "10px 15px"
                }
              }}
              type="text"
              id="subtitulo"
              name="subtitulo"
              value={formData.subtitulo}
              onChange={handleInputChange}
            />

          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <p className="subtitulo-seccion-evento-expanded">Descripción <span className="text-danger">*</span></p>
            <p className="descrip-seccion-evento-expanded mb-0">Ingrese una descripcion concisa y atrayente para su publicacion</p>
            <TextField
              sx={{
                marginTop: "5px",
                marginBottom: "15px",
                input: {
                  padding: "10px 15px"
                }
              }}
              multiline
              rows={2}
              id="descripcion"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleInputChange}
            />
            {lengthDescrip === 80 ? (
              <p className="text-right" style={{ color: "#c44536", fontSize: "12px", position: "relative", top: "-10px" }}>
                {lengthDescrip}/80
              </p>
            ) : (
              <p className="text-right" style={{ fontSize: "12px", color: "#666", position: "relative", top: "-10px" }}>
                {lengthDescrip}/80
              </p>
            )}
            <>
              {error.descripcion &&
                <Alert severity="error"
                  sx={{
                    position: "relative",
                    top: "-25px",
                    marginBottom: "15px"
                  }}>{error.descripcion}</Alert>
              }
            </>
          </FormControl>

          <div className="mb-3" style={{ position: "relative", top: "-20px" }}>
            <p className="subtitulo-seccion-evento-expanded">Periodo de la publicación <span className="text-danger">*</span></p>
            <p className="descrip-seccion-evento-expanded mb-0 text-justify">Selecciona las fechas en las que deseas que tu publicación sea visible.
              La publicación comenzará a mostrarse en la fecha de inicio y dejará de estar disponible después de la fecha de finalización.</p>
            <div className="d-flex flex-row gap-4 flex-wrap mt-1">
              <FormControl sx={{ width: "35%" }}>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    input: {
                      padding: "10px 15px"
                    }
                  }}
                  id="desde"
                  name="desde"
                  type="date"
                  value={formData.desde}
                  onChange={handleInputChange}
                />
                <small style={{ position: "relative", top: "-10px" }}>Fecha de inicio</small>

              </FormControl>
              <FormControl sx={{ width: "35%" }}>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    input: {
                      padding: "10px 15px"
                    }
                  }}
                  id="hasta"
                  name="hasta"
                  type="date"
                  value={formData.hasta}
                  onChange={handleInputChange}
                />
                <small style={{ position: "relative", top: "-10px" }}>Fecha de finalizacion</small>
              </FormControl>
              <>
                {error.desde &&
                  <Alert severity="error"
                    sx={{
                      position: "relative",
                      top: "-20px",
                    }}>{error.desde}</Alert>
                }
                {error.hasta &&
                  <Alert severity="error"
                    sx={{
                      position: "relative",
                      top: "-20px",
                    }}>{error.hasta}</Alert>
                }
                {error.fechas &&
                  <Alert severity="error"
                    sx={{
                      position: "relative",
                      top: "-20px",
                    }}>{error.fechas}</Alert>
                }
              </>
            </div>
          </div>

          <FormControl>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <p className="titulo-seccion-evento-expanded">
                {t("event.addImage")} <span className="text-danger">*</span>
              </p>
            </div>

            <p className="descrip-seccion-evento-expanded">
              Sube hasta 5 imágenes y videos que muestren los detalles y momentos destacados para que los asistentes tengan una visión del evento.
              <br />
              Asegúrate de elegir archivos de alta calidad para un mejor impacto visual.
            </p>
            <div className="info-input-img">
              <ul className="ul-info-img">
                <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                  Tamaño recomendado de imagen: 19200x1280
                </li>
                <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                  Tamaño máximo de archivo: 15MB
                </li>
                <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                  Formatos admitidos de imagen: JPEG, PNG
                </li>
              </ul>
            </div>

            <div
              className={`input-file ${mediaUrls.length === 0 ? "" : "border-0"
                }`}
            >
              <input
                id="files"
                name="files"
                type="file"
                accept="image/*,video/*"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {loading ? (
                <div className="d-flex flex-column align-items-center">
                  <p>Cargando archivos...</p>
                  <CircularProgress />
                </div>
              ) : mediaUrls.length === 0 ? (
                <label className="placeholder-input-img" htmlFor="files">
                  {t("event.inputImage")}
                </label>
              ) : (
                <>
                  <div className="imagen-contenedor">
                    {selectedFileIndex !== null && (
                      <div className="main-preview">
                        {mediaTypes[selectedFileIndex].includes('video') ? (
                          <video src={mediaUrls[selectedFileIndex]} controls alt="Video seleccionado" />
                        ) : (
                          <img src={mediaUrls[selectedFileIndex]} alt="Imagen seleccionada" />
                        )}
                        <button
                          type="button"
                          className="remove-button"
                          onClick={() => {
                            deleteMedia(selectedFileIndex);
                          }}
                        >
                          Eliminar
                        </button>
                      </div>
                    )}

                    <div className="thumbnails-contenedor">
                      <div
                        className="boton-agregar-img"
                        onClick={handleAddFileClick}
                      >
                        <AddOutlinedIcon sx={{ color: "#3659E3" }} />
                      </div>
                      {mediaUrls.map(renderFilePreview)}
                    </div>
                  </div>
                </>
              )}
            </div>
            <>
              {error.files &&
                <Alert severity="error"
                  sx={{
                    marginTop: "10px"
                  }}>{error.files}</Alert>
              }
            </>
          </FormControl>

          {error && <Alert color="red">Por favor, chequea la información ingresada.</Alert>}
          {submitted && (
            <Alert color="success">
              {t("publications.successEditedAlert")}
            </Alert>
          )}
          <div className="d-flex justify-content-end mb-5 mt-22">
            <Button style={{ color: "red", border: "1px solid red", backgroundColor: "transparent", fontFamily: "Inter, sans-serif" }} className="mr-2" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
            <Button type="submit" className="boton-submit" onClick={handleSubmit}>
              {t("button.edit")} publicacion
            </Button>{" "}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPublicacion;
