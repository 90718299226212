import { useContext } from "react";
import { ImpresionContext } from "../../providers/Impresion";
import AddLabel from "./AddLabel";
import SideBar from "./SideBar";

const QrSideBar = () => {
  const {
    // elements, 
    // setElements, 
    // scale,
    //  dimensionContainer, 
    //  index,
    //   setIndex, 
    setOpenQr,
    // event 
  } = useContext(ImpresionContext);


  return (
    <>
      <SideBar id='dinamicBar'>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className="p-0 m-0 fs-4">Variables Dinamicas</p>
          </div>
          <div>
            <button className="d-flex align-items-center" onClick={() => setOpenQr(false)}>
              <i className="material-symbols-outlined">close</i>
            </button>
          </div>
        </div>
        <div>
          <p className="fs-6">Estas variables se reemplazaran por los datos registrados cuando descargues el PDF.
            Los valores son representativos.</p>
        </div>
        <div className="d-flex flex-column gap-2">
          <AddLabel type={'text-nombre'}>Nombre y Apellido</AddLabel>
          <AddLabel type={'text-evento'}>Nombre del evento</AddLabel>
          <AddLabel type={'text-codigo'}>Codigo</AddLabel>
          <AddLabel type={'qr'}>Codigo QR</AddLabel>
        </div>
      </SideBar>
    </>
  )
};

export default QrSideBar;