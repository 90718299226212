import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Container, TextField, FormControl, InputLabel, Select, MenuItem, Button, Box, Alert, AlertTitle, FormHelperText } from '@mui/material';

export const CrearVoucher = () => {
    const [state, setState] = useState({
        showResult: false,
        apiMessage: "",
        error: null,
    });
    const [tarifas, setTarifas] = useState();
    const [importe, setImporte] = useState()
    const [tarifaSeleccionada, setTarifaSeleccionada] = useState('');
    const param = useParams();
    const history = useHistory();
    const [t] = useTranslation("global");
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        idtarifa: '',
        cantidadtarifas: 1,
        importe: '',
        cantidadvouchers: 1
    })

    useEffect (() => {
        const fetchTarifa = async () => {
            try {
                let totalTarifas = [];
                let currentPage = 1;
                let hasMoreTickets = true;
                let filtro = '';
          
                while (hasMoreTickets) {
                    const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
                    if (response.status === "success") {
                        const tarifas  = response.data.tarifas; 
            
                        if (tarifas && tarifas.length > 0) {
                            totalTarifas = [...totalTarifas, ...tarifas];
                            currentPage++;
                        } else {
                            hasMoreTickets = false;
                        }
                    }
                    setTarifas(totalTarifas)
                }    
            } catch (error) {
                setState({
                    ...state,
                    error: error.error,
                });
            }
        }
        fetchTarifa();
    },[param.id, state]);

    const consumicionTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 2);


    const [unidad, setUnidad] = useState('');
    useEffect(() => {
        const selectedTarifa = tarifas?.find((tarifa) => tarifa.id === parseInt(formData.idtarifa));
        setUnidad(selectedTarifa ? selectedTarifa.unidad : '');
        setTarifaSeleccionada(selectedTarifa ? selectedTarifa.concepto : '');

        if (selectedTarifa && formData.cantidadtarifas) {
            const cantidad = parseInt(formData.cantidadtarifas);
            const precio = selectedTarifa.precio;
            setImporte(cantidad * precio);
        } else {
            setImporte(0);
        }
    }, [formData.idtarifa, formData.cantidadtarifas, tarifas]);

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == "cantidadtarifas" || name == "cantidadvouchers") {
            if (value == '' || /^[1-9]\d*$/.test(value)) {
                setFormData({
                    ...formData,
                    [name]: value,
                });
            }
        } else {
            setFormData({
              ...formData,
              [name]: value,
            });
        }
    };

    const idTarifa = parseInt(formData.idtarifa);
    const cantidadTarifas = parseInt(formData.cantidadtarifas);
    const cantidadVouchers = parseInt(formData.cantidadvouchers);

    const [errors, setErrors] = useState({});
    const handleSubmit = async (e) => { 
        e.preventDefault();
        let validationErrors = {};
    
        if (formData.nombre === '') {
          validationErrors.nombre = 'Por favor ingresar un nombre';
        }
        
        if (formData.idtarifa === '') {
          validationErrors.idtarifa = 'Por favor seleccionar una tarifa';
        }

        if (formData.cantidadtarifas === '') {
          validationErrors.cantidadtarifas = 'Por favor ingresar un cantidad de tarifas';
        }

        if (formData.cantidadvouchers === '') {
            validationErrors.cantidadvouchers = 'Por favor ingresar un cantidad de vouchers';
          }
        
    
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
    
        const data = {
            voucher: {
              nombre: formData.nombre,
              apellido: formData.apellido,
              idtarifa: idTarifa,
              cantidad: cantidadTarifas,
              importe: importe
            },
            cantidad: cantidadVouchers,
          };

        try { 
            const response = await api.vouchers.createVouchers(param.id, data);
            if (response.status === 'success') { 
                toast.success(t("vouchers.voucherCreated"), {
                    position: "top-right",
                    autoClose: 2000,
                });

                setTimeout(() => {
                    history.push(`/eventos/${param.id}`);
                }, 2000);
            } 
            else {
                console.error('Error al crear el voucher:', response.error); 
            } 
        } catch (error) { 
            console.error('Error en la solicitud:', error.message);
            toast.error(error.message, {
                position: "top-right",
                autoClose: 4000,
            }); 
        } 
        console.log(data);
        console.log('Datos enviados a la API:', data); 
    }
  return (
    <Container maxWidth='lg' className=" d-flex justify-content-center flex-wrap">
        <div className="mt-2" style={{maxWidth: isMobile ? '650px' : 'none', width: !isMobile ? '650px' : '100%'}}>
            <Box
                component="form" 
                onSubmit={handleSubmit}>
                <div style={{flexDirection: isMobile ? 'column' : 'row'}} className="w-100 d-flex gap-3 mb-3">
                    <TextField 
                        name="nombre"
                        label="Nombre" 
                        variant="outlined" 
                        value={formData.nombre}
                        onChange={handleChange}
                        style={{width: isMobile ? "100%" : "50%"}}
                        error={errors.nombre}
                        helperText={errors.nombre && errors.nombre}
                        fullWidth={isMobile}
                    />
                    <TextField 
                        name="apellido"
                        label="Apellido" 
                        variant="outlined" 
                        value={formData.apellido}
                        onChange={handleChange}
                        style={{width: isMobile ? "100%" : "50%"}}
                    />       
                </div>

                <div style={{flexDirection: isMobile ? 'column' : 'row'}} className="w-100 d-flex gap-3 mb-3">
                    <FormControl
                        error={errors.idtarifa}
                        style={{width: isMobile ? "100%" : "50%"}}
                    >
                        <InputLabel id="demo-simple-select-label">Tarifa</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            name="idtarifa"
                            label="idtarifa" 
                            variant="outlined" 
                            value={formData.idtarifa}
                            onChange={handleChange}
                        >
                            {consumicionTarifas?.map((tarifa) => (
                                <MenuItem key={tarifa.id} value={tarifa.id}>
                                    {tarifa.concepto}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors.idtarifa}</FormHelperText>
                    </FormControl>
                    <TextField
                        name="cantidadtarifas"
                        label="Cantidad de tarifas"
                        variant="outlined" 
                        value={formData.cantidadtarifas} 
                        onChange={handleChange}
                        inputProps={{ min: 1 }}
                        style={{width: isMobile ? "100%" : "50%"}}
                        error={errors.cantidadtarifas}
                        helperText={errors.cantidadtarifas && errors.cantidadtarifas}
                    />
                </div>
                <TextField
                    name="cantidadvouchers"
                    label={t("vouchers.numberOfVouchers")}
                    variant="outlined" 
                    value={formData.cantidadvouchers}
                    onChange={handleChange}
                    inputProps={{ 
                        min: 1,
                         
                    }}
                    error={errors.cantidadvouchers}
                    helperText={errors.cantidadvouchers && errors.cantidadvouchers}
                />
                <div className="d-flex justify-content-end gap-3 mt-3 mb-5">
                    <Button 
                        variant="outlined"
                        onClick={() => history.push("/eventos/"+ param.id)}
                    >
                        {t("button.cancel")}
                    </Button>
                    <Button 
                        variant="contained" 
                        type="submit"
                    >
                        Enviar
                    </Button>
                </div>
                
            </Box>
        </div>

        <div style={{width: '300px', height: 'fit-content', borderRadius: '5px', marginLeft: isMobile ? '0' : '15px'}} className="py-4 px-3 border border-dark-subtle mt-2">
            <h5 style={{textAlign: "center"}}>
                {t("tickets.summary")}
            </h5>
            <hr/>
            {tarifaSeleccionada && formData.cantidadtarifas > 0 && (
                <p>
                    {unidad} {tarifaSeleccionada} x {formData.cantidadtarifas}
                </p>
            )}
            <div className="d-flex justify-content-between" style={{backgroundColor: '#ededed', padding: '4px 7px'}}>
                <h4 style={{margin: '0px'}}>
                    Total:
                </h4>
                <h4 style={{margin: '0px'}}>
                    {importe === 0 || !tarifaSeleccionada || !formData.cantidadtarifas
                        ? '0'
                    : 
                        `$${importe * cantidadVouchers || ""}`
                    }  
                </h4>
            </div>
        </div>
        <Alert severity="info" variant="outlined" style={{marginTop: isMobile ? '30px' : '0'}}>
            <div>
                <AlertTitle>{t("tickets.attention")}</AlertTitle>
                {t("tickets.import")}
                <div className="d-flex justify-content-end">
                    <Button color="primary" size="small" onClick={() => history.push("/eventos/"+param.id+"/nuevoVoucher/importarExcel") }>
                        {t("tickets.upload")}
                    </Button>
                </div>
            </div>
        </Alert>
    </Container>
  )
}
