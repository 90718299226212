import { useState, useEffect } from 'react';

const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";

const useUbicaciones = (paisPredefinido = null) => {
    const [paises, setPaises] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [paisSeleccionado, setPaisSeleccionado] = useState(paisPredefinido || '');
    const [provSeleccionada, setProvSeleccionada] = useState('');
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState('');

    useEffect(() => {
        const fetchPaises = async () => {
            const url = "https://api.countrystatecity.in/v1/countries";
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: { 'X-CSCAPI-KEY': API_KEY }
                });
                if (response.ok) {
                    const data = await response.json();
                    setPaises(data);
                }
            } catch (error) {
                console.error('Error al obtener los países:', error);
            }
        };
        fetchPaises();
    }, []);

    useEffect(() => {
        if (paisSeleccionado) {
            const fetchProvincias = async () => {
                const url = `https://api.countrystatecity.in/v1/countries/${paisSeleccionado}/states`;
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: { 'X-CSCAPI-KEY': API_KEY }
                    });
                    const result = await response.json();
                    setProvincias(result);
                } catch (error) {
                    console.error('Error al obtener provincias:', error);
                }
            };
            fetchProvincias();
        }
    }, [paisSeleccionado]);

    useEffect(() => {
        if (provSeleccionada && paisSeleccionado) {
            const fetchCiudades = async () => {
                const url = `https://api.countrystatecity.in/v1/countries/${paisSeleccionado}/states/${provSeleccionada}/cities`;
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: { 'X-CSCAPI-KEY': API_KEY }
                    });
                    const result = await response.json();
                    setCiudades(result);
                } catch (error) {
                    console.error('Error al obtener ciudades:', error);
                }
            };
            fetchCiudades();
        }
    }, [provSeleccionada, paisSeleccionado]);

    return {
        paises,
        provincias,
        ciudades,
        paisSeleccionado,
        setPaisSeleccionado,
        provSeleccionada,
        setProvSeleccionada,
        ciudadSeleccionada,
        setCiudadSeleccionada
    };
};

export default useUbicaciones;
