import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { api } from '../../services/api';
import './CookieConsent.css';
import { useTranslation } from 'react-i18next';

const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(['userConsent']);
    const [showBanner, setShowBanner] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState(null);
    const [t] = useTranslation("global");

    useEffect(() => {
        if (cookies.userConsent === undefined) {
            setShowBanner(true);
        }
    }, [cookies.userConsent]);

    const handleConsent = (consent) => {
        const expirationDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);

        setCookie('userConsent', consent, { expires: expirationDate });
        setCookie('functionalConsent', consent, { path: '/', expires: expirationDate });
        setCookie('analyticsConsent', consent, { path: '/', expires: expirationDate });
        setCookie('advertisingConsent', consent, { path: '/', expires: expirationDate });
        setCookie('thirdPartyConsent', consent, { path: '/', expires: expirationDate });

        setFormData({
            consent: consent,
            functionalConsent: consent,
            analyticsConsent: consent,
            advertisingConsent: consent,
            thirdPartyConsent: consent,
        });

        setShowBanner(false);
        callApi(consent);
    };

    const callApi = async (consent) => {
        try {
            const response = await api.cookies.updateCookies({ consent: FormData })
        } catch (e) {
            setError(e);
        }
    };

    return (
        <>
            {showBanner && (
                <div className="cookie-banner">
                    <p>{t("cookies.cookies_banner")}</p>
                    <div>
                        <button onClick={() => handleConsent(true)}>{t("cookies.accept")}</button>
                        <button onClick={() => handleConsent(false)}>{t("cookies.reject")}</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CookieConsent;
