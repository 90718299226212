import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { api } from "../../services/api";
import AdsMobile from "./AdsMobile";
import CardPublicacion from "./CardPublicacion";
import "./ads.css";
import DatePicker from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { toast } from "react-toastify";
import { TextField, CircularProgress, Box, Skeleton } from "@mui/material";

import MicExternalOnOutlinedIcon from "@mui/icons-material/MicExternalOnOutlined";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import SportsBasketballOutlinedIcon from "@mui/icons-material/SportsBasketballOutlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';

import { MenuItem, Select } from "@mui/material";

const categorias = [
  "Música",
  "Fiestas",
  "Deportes",
  "Beneficencia",
  "Gastronomía",
  "Exposiciones",
];

const categoriaIcons = {
  Fiestas: <NightlifeOutlinedIcon className="icon-category" />,
  Música: <MicExternalOnOutlinedIcon className="icon-category" />,
  Beneficencia: <VolunteerActivismOutlinedIcon className="icon-category" />,
  Deportes: <SportsBasketballOutlinedIcon className="icon-category" />,
  Gastronomía: <FastfoodOutlinedIcon className="icon-category" />,
  Exposiciones: <BusinessCenterOutlinedIcon className="icon-category" />,
};

const translations = {
  Música: {
    es: "Música",
    en: "Music",
  },
  Fiestas: {
    es: "Fiestas",
    en: "Social events",
  },
  Deportes: {
    es: "Deportivos",
    en: "Sports",
  },
  Beneficencia: {
    es: "Beneficios",
    en: "Profits",
  },
  Gastronomía: {
    es: "Gastronomía",
    en: "Food",
  },
  Exposiciones: {
    es: "Exposiciones",
    en: "Exhibitions",
  },
};

const translateCategory = (category, language) => {
  return translations[category] ? translations[category][language] : category;
};

const Ads = ({ usuario }) => {
  const [publicaciones, setPublicaciones] = useState([]);
  const [publicacionesConFechaEvento, setPublicacionesConFechaEvento] =
    useState({});
  const [filteredPublicaciones, setFilteredPublicaciones] = useState([]);
  const [filtrosAplicados, setFiltrosAplicados] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const calendarRef = useRef();
  const [loading, setLoading] = useState(true);
  const { i18n, t } = useTranslation("global");
  const currentLanguage = i18n.language;
  const isMobile = useMediaQuery({ query: `(max-width:770px)` });
  const [ciudadSeleccionada, setCiudadSeleccionada] = useState("");
  const [ciudades, setCiudades] = useState([]);

  const months = [
    ["Enero", "En"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "Ma"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sept"],
    ["Octubre", "Oct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dic"],
  ];

  const weekDays = [
    ["Domingo", "Do"],
    ["Lunes", "Lu"],
    ["Martes", "Ma"],
    ["Miercoles", "Mi"],
    ["Jueves", "Ju"],
    ["Viernes", "Vi"],
    ["Sabado", "Sa"],
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.publicaciones.getAllActive();
      if (response.status === "success") {
        const publicacionesData = response.data.publicaciones;
        setPublicaciones(publicacionesData || []);
        setFilteredPublicaciones(response.data.publicaciones || []);

        const ciudadesUnicas = [
          ...new Set(publicacionesData.map((pub) => pub.evento.ciudad)),
        ];
        setCiudades(ciudadesUnicas);

      }
    } catch (error) {
      toast.error("Error al cargar publicaciones.", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFechaEventoChange = (publicacionId, fechaEvento) => {
    const fecha = new Date(fechaEvento);
    setPublicacionesConFechaEvento((prev) => ({
      ...prev,
      [publicacionId]: fecha,
    }));

    return fecha;
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory((prev) => (prev === category ? "" : category));
    applyFiltros(null, category);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFecha = (dates) => {
    setDateRange(dates);
    applyFiltros(null, null, dates);
  };

  const handleSearchSubmit = () => {
    applyFiltros(searchTerm);
  };

  const applyFiltros = (
    searchOverride = null,
    categoryOverride = null,
    dateOverride = null,
    cityOverride = null
  ) => {
    let filtered = publicaciones;
    let anyFiltroApplied = false;

    const categoryToUse =
      categoryOverride !== null ? categoryOverride : selectedCategory;
    const searchToUse = searchOverride !== null ? searchOverride : searchTerm;
    const datesToUse = dateOverride !== null ? dateOverride : dateRange;
    const cityToUse = cityOverride !== null ? cityOverride : ciudadSeleccionada;

    if (categoryToUse) {
      filtered = filtered.filter(
        (pub) => pub.evento.evento_tipo.evento_tipo === categoryToUse
      );
      anyFiltroApplied = true;
    }

    const [startDate, endDate] = datesToUse;
    if (startDate && endDate) {
      filtered = filtered.filter((pub) => {
        const fechaEvento =
          publicacionesConFechaEvento[pub.id] ||
          handleFechaEventoChange(pub.id, pub.evento.fecha);
        if (!fechaEvento) return false;
        return (
          fechaEvento >= startDate.toDate() && fechaEvento <= endDate.toDate()
        );
      });
      anyFiltroApplied = true;
    }

    if (searchToUse) {
      filtered = filtered.filter((pub) =>
        pub.titulo.toLowerCase().includes(searchToUse.toLowerCase())
      );
      anyFiltroApplied = true;
    }

    if (cityToUse) {
      filtered = filtered.filter((pub) => pub.evento.ciudad === cityToUse);
      anyFiltroApplied = true;
    }

    setFilteredPublicaciones(filtered);
    setFiltrosAplicados(anyFiltroApplied);
  };

  const handleClearFiltros = () => {
    setSelectedCategory("");
    setSearchTerm("");
    setDateRange([]);
    setCiudadSeleccionada("");
    setPublicacionesConFechaEvento({});
    setFilteredPublicaciones(publicaciones);
    setFiltrosAplicados(false);
  };


  return (
    <>
      {isMobile ? (
        <div className="">
          <AdsMobile
            usuario={usuario}
            publicaciones={publicaciones}
            filteredPublicaciones={filteredPublicaciones}
            setFilteredPublicaciones={setFilteredPublicaciones}
            handleFechaEventoChange={handleFechaEventoChange}
            ciudadSeleccionada={ciudadSeleccionada}
            setCiudadSeleccionada={setCiudadSeleccionada}
            ciudades={ciudades}
          />
        </div>
      ) : (
        <>
          <div className="d-flex w-100 flex-wrap justify-content-center mt-2">
            <div
              className="d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <TextField
                sx={{
                  width: "40%",
                  borderRight: "none",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px 0 0 10px",
                  },
                  input: {
                    "::placeholder": {
                      fontFamily: "Inter",
                    },
                  },
                }}
                placeholder={t("home.filterText")}
                value={searchTerm}
                onChange={handleSearchChange}
                className="input-search"
              />

              <Select
                sx={{ borderRadius: "0" }}
                className="input-region"
                value={ciudadSeleccionada}
                onChange={(event) => {
                  setCiudadSeleccionada(event.target.value);
                  applyFiltros(null, null, null, event.target.value);
                }}
                displayEmpty
              >
                <MenuItem sx={{
                  fontFamily: "Inter",
                }} value='' hidden>
                  Filtrar por ciudad
                </MenuItem>
                {ciudades.map((ciudad) => (
                  <MenuItem sx={{
                    fontFamily: "Inter",
                  }} key={ciudad} value={ciudad}>
                    {ciudad}
                  </MenuItem>
                ))}
              </Select>

              <div
                className="container-calendar d-inline-flex"
                ref={calendarRef}
              >
                <DatePicker
                  placeholder={t("home.filterDate")}
                  months={months}
                  weekDays={weekDays}
                  range
                  value={dateRange}
                  onChange={handleFecha}
                  numberOfMonths={1}
                  rangeHover
                  rangeColors={"#7D888D"}
                  dateSeparator=" | "
                  className="fs-6 "
                  scrollSensitive={false}
                  plugins={[
                    <Toolbar
                      position="bottom"
                      names={{
                        today: "Hoy",
                        deselect: "Limpiar",
                        close: "Cerrar",
                      }}
                    />,
                  ]}
                />
              </div>

              {filtrosAplicados ? (
                <button
                  type="button"
                  className="boton-limpiar-filtros"
                  onClick={handleClearFiltros}
                >
                  <div className="d-flex flex-row content-boton-filtros">
                    <span className="icon-boton-filtros">
                      <ClearOutlinedIcon className="mr-2" />
                    </span>
                    <p className="txt-boton-filtros mb-0">
                      {t("home.cleanFilters")}
                    </p>
                  </div>
                </button>
              ) : (
                <button
                  type="button"
                  className="boton-buscar-filtros"
                  onClick={handleSearchSubmit}
                >
                  <SearchOutlinedIcon className="mr-2" />
                  {t("button.search")}
                </button>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-center gap-4 mt-5">
            {categorias.map((category) => (
              <button
                key={category}
                className={`button-ads-desktop ${selectedCategory === category ? "active" : ""
                  }`}
                onClick={() => handleCategoryChange(category)}
              >
                <div>
                  <span className="">{categoriaIcons[category]}</span>
                  <p className="text-btn-category">
                    {translateCategory(category, currentLanguage)}
                  </p>
                </div>
              </button>
            ))}
          </div>

          <div className="d-flex flex-wrap justify-content-center gap-4 py-4">
            {loading ? (
              <div className="d-flex d-flex justify-content-evenly gap-4">
                {Array(4).fill().map((_, index) => (
                  <Box key={index} sx={{ width: 280, marginRight: 0.5, my: 5 }}>
                    <Skeleton variant="rectangular" width={280} height={150} />
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton height={50} />
                      <Skeleton />
                      <Skeleton width="60%" />
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Skeleton width="27%" height={40} />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </div>
            ) : (
              <>
                {filteredPublicaciones.length > 0 ? (
                  filteredPublicaciones.map((pub) => (
                    <CardPublicacion
                      usuario={usuario}
                      key={pub.id}
                      publicacion={pub}
                      isLoading={loading}
                    />
                  ))
                ) : (
                  <div className="w-100 d-flex flex-column justify-content-center align-items-center text-center" style={{height: "200px"}}>
                    <EventBusyOutlinedIcon  fontSize="large" />
                    <h5 className="fw-bold">Aun no hay eventos registrados</h5>
                  </div>
                )}
              </>
            )}

          </div>
        </>
      )}
    </>
  );
};

export default Ads;
