import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import "moment/locale/es";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useMediaQuery } from "react-responsive";
import { MensajeFinalizarCreacionEvento } from "../../components/modales/MensajeFinalizarCreacionEvento";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TableChartIcon from '@mui/icons-material/TableChart';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Select,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
  InputAdornment, 
  TextField,
  Divider,
  CircularProgress
} from '@mui/material';
import { toast } from "react-toastify";
moment.locale('es');
moment.locale('en-GB');


export const Evento = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const { i18n, t } = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [filtroEventos, setFiltroEventos] = useState("Todo");
  const [eventosFiltrados, setEventosFiltrados] = useState([]);
  const [open, setOpen] = useState(false);
  const [eventoId, setEventoId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElID, setAnchorElID] = useState(null)
  const [expanded, setExpanded] = useState(false);

  const notify = (type, message, position = "bottom-center") => {
    const options = {
      position,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  }

  const handleOpen = (id) => {
    setEventoId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setEventoId(null);
    setOpen(false);
  };

  const handleClickSeeMore = (e) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    setAnchorElID(e.currentTarget.id)
  }

  const handleCloseSeeMore = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
    setAnchorElID(null)
  }

  const openSeeMore = Boolean(anchorEl);
  const ITEM_HEIGHT = 48

  const HandleDownloadReport = (e, evento) => {
    e.stopPropagation()

    const callApi = async (e) => {

      try {
        const response = await api.reporte.downloadReporte(evento.id)
        if (!response) {
          notify("error", "Error al realizar la descarga")
          throw new Error('Error al descargar el Excel')
        }
        const blob = await response;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte-${evento.id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setAnchorElID(null)

        notify("success", "La descarga se realizó con éxito")
      } catch (error) {
        console.error('Error al descargar el Excel:', error.message);
        throw error;
      }
    }
    callApi()
  }

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);

      try {
        const response = await api.eventos.getAll(query);

        if (response.status === "success") {
          setData(response.data.eventos);
        }
      } catch (error) {
        toast.error("Error en la red.", {
          position: "top-center",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, [query]);

  useEffect(() => {
    let filtro = data;

    if (filtroEventos !== "Todo") {
      filtro = filtro?.filter((evento) =>
        evento?.estado?.estado === filtroEventos
      )
    }

    setEventosFiltrados(filtro);
  }, [filtroEventos, data]);

  const handleRowClick = (evento) => {
    history.push("/eventos/" + evento.id);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.q.value); // Asigna el valor del input a query
  };

  const colorCirculo = (estado) => {
    switch (estado) {
      case "Borrador":
        return "#00f";
      case "Activo":
        return "#0f0";
      case "Finalizado":
        return "grey";
      case "Eliminado":
        return "#f00";
      default:
        return "transparent";
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); 
  };

  return (
    <>
      {isMobile ? (
        <Container>
          <div className="py-2">
            <h1 style={{ color: '#0B0062' }}>
              {t("navbar.myEvents")}
            </h1>
            <p>{t("event.controlEvent")}</p>
          </div>
          <div className="d-flex gap-3 mb-4" style={{ width: '100%' }}>
            <form onSubmit={handleSearch}>
              <TextField
                id="exampleSearch"
                name="q"
                size="small"
                placeholder="Buscar evento"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{color: "#5656FF"}}/>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <Select
              id="filtro-eventos"
              label="filtro-eventos"
              value={filtroEventos}
              onChange={(e) => setFiltroEventos(e.target.value)}
              sx={{ height: '2.5rem', color: '#fff', backgroundColor: '#5656FF' }}
            >
              <MenuItem value="Todo">Todos</MenuItem>
              <MenuItem value="Borrador">Borrador</MenuItem>
              <MenuItem value="Activo">Activos</MenuItem>
              <MenuItem value="Eliminado">Eliminados</MenuItem>
              <MenuItem value="Finalizado">Finalizados</MenuItem>
            </Select>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            eventosFiltrados?.length > 0 ? (
              eventosFiltrados.map((evento) => (
                <>
                  <div key={evento.id} style={{marginBottom: "15px"}} 
                    onClick={evento.estado.id === 1 ? () => handleOpen(evento.id)
                    : (evento.estado.id === 3 || evento.estado.id === 4)
                        ? null
                        : () => handleRowClick(evento)
                    }
                  >
                    <div className="d-flex gap-3">
                      <div
                        className="d-flex flex-column align-items-center justify-content-center lh-1"
                        style={{ width: '70px', height: '70px', backgroundColor: '#EDEDED' }}
                      >
                        <span className="fs-2 fw-medium m-0 p-0">{moment(evento.fecha).clone().local().format("D")}</span>
                        <span className=" text-uppercase m-0 p-0">{moment(evento.fecha).clone().local().format("MMM")}</span>
                      </div>
                      <div className="w-100 align-self-center lh-2">
                        <div className="d-flex align-items-center gap-1">
                          <p className="fw-medium m-0">
                            {evento.evento}
                          </p>
                          {evento.privado ?
                            <LockIcon style={{ color: '#aeaeae', fontSize: '.9rem' }} />
                          :
                            <LockOpenIcon style={{ color: '#aeaeae', fontSize: '.9rem' }} />
                          }
                          <IconButton
                            aria-label="see more"
                            id={evento.id}
                            aria-controls={openSeeMore ? 'long-menu' : undefined}
                            aria-expanded={openSeeMore ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClickSeeMore}
                            sx={{p: 0}}
                            className="ms-auto"
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={evento.id}
                            aria-label="report"
                            anchorEl={anchorEl}
                            open={evento.id == anchorElID}
                            onClose={handleCloseSeeMore}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <MenuItem onClick={(e) => HandleDownloadReport(e, evento)}>
                              <ListItemIcon>
                                <TableChartIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Reporte</ListItemText>
                            </MenuItem>
                          </Menu>
                        </div>
                        <small className="text-secondary d-block">
                          {evento.direccion}
                        </small>
                        <span
                          className="circulo-estado"
                          style={{
                            backgroundColor: colorCirculo(evento.estado.estado),
                              boxShadow: `0 0 5px ${colorCirculo(evento.estado.estado)}`
                            }}
                          />
                        <small className="text-secondary">{evento.estado?.estado}</small>
                      </div>
                    </div>
                    <Divider sx={{ marginTop: '15px', bgcolor: 'gray'}}/>
                  </div>
                  <MensajeFinalizarCreacionEvento open={open} handleClose={handleClose} eventoId={eventoId} />
                </>
              ))
            ) : (
              <Alert severity="info" color="secondary">
                {filtroEventos !== "Todo"
                  ? `Aún no tienes eventos ${filtroEventos.toLowerCase()}s.`
                  : "Aún no tienes eventos."
                }
              </Alert>
            )
          )}
          <div
            className="my-4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button variant="contained" startIcon={<AddIcon/>}
              sx={{ backgroundColor: '#FFC300', color: '#000' }}
              onClick={() => history.push("/crearEvento/creacion")}
            >
              {t("event.addEvent")}
            </Button>
          </div>
        </Container>
      ) : (
        <Container className="my-3" maxWidth="lg">
          <div className="py-2">
            <h1 style={{ color: '#0B0062' }}>
              {t("navbar.myEvents")}
            </h1>
            <p>{t("event.controlEvent")}</p>
          </div>

          <div className="d-flex justify-content-between gap-3 mb-4">
            <div className="d-flex gap-3">
              <form onSubmit={handleSearch}>
                <TextField
                  id="exampleSearch"
                  name="q"
                  size="small"
                  placeholder="Buscar evento"
                  type="search"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{color: "#5656FF"}}/>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>

              <Select
                id="filtro-eventos"
                label="filtro-eventos"
                value={filtroEventos}
                onChange={(e) => setFiltroEventos(e.target.value)}
                sx={{ height: '2.5rem', color: '#fff', backgroundColor: '#5656FF' }}
              >
                <MenuItem value="Todo">Todos</MenuItem>
                <MenuItem value="Borrador">Borrador</MenuItem>
                <MenuItem value="Activo">Activos</MenuItem>
                <MenuItem value="Eliminado">Eliminados</MenuItem>
                <MenuItem value="Finalizado">Finalizados</MenuItem>
              </Select>
            </div>

            <Button variant="contained" startIcon={<AddIcon/>}
              sx={{ backgroundColor: '#FFC300', color: '#000' }}
              onClick={() => history.push("/crearEvento/creacion")}
            >
              {t("event.addEvent")}
            </Button>
          </div>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#EDEDED" }}>
                <TableRow>
                  <TableCell>Evento</TableCell>
                  <TableCell align="center">Rol</TableCell>
                  <TableCell align="center">Ventas</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  eventosFiltrados?.length > 0 ? (
                    eventosFiltrados.map((evento) => (
                      <TableRow
                        hover
                        key={evento.id}
                        style={{
                          cursor: (evento.estado.id === 1 || evento.estado.id === 2)
                            ? 'pointer'
                            : 'inherit'
                        }}
                        onClick={evento.estado.id === 1
                          ? () => handleOpen(evento.id)
                          : (evento.estado.id === 3 || evento.estado.id === 4)
                            ? null
                            : () => handleRowClick(evento)
                        }
                      >
                        <TableCell scope="row" className="ps-0 pb-0">
                          <div className="d-flex gap-3">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center lh-1"
                              style={{ width: '70px', height: '70px', backgroundColor: '#EDEDED' }}
                            >
                              <span className="fs-2 fw-medium m-0 p-0">{moment(evento.fecha).clone().local().format("D")}</span>
                              <span className=" text-uppercase m-0 p-0">{moment(evento.fecha).clone().local().format("MMM")}</span>
                            </div>

                            <div className="align-self-center lh-2">
                              <p className="fw-semibold m-0">
                                {evento.evento}
                              </p>
                              <small className="text-secondary d-block">
                                {evento.direccion} - {moment(evento.hora).clone().local().format("HH:mm [hs.]")}
                              </small>
                              {evento.privado
                                ?
                                <span style={{ color: '#aeaeae' }}>
                                  <LockIcon style={{ fontSize: '.8rem' }} />
                                  <small>Privado</small>
                                </span>
                                :
                                <span style={{ color: '#aeaeae' }}>
                                  <LockOpenIcon style={{ fontSize: '.8rem' }} />
                                  <small>Público</small>
                                </span>
                              }
                            </div>
                          </div>
                        </TableCell>

                        <TableCell align="center" width={100} className="pb-0">
                          <span
                            className="badge rounded-pill text-dark align-content-center"
                            style={{
                              backgroundColor: "#EDEDED",
                              minWidth: "100px",
                              height: "30px"
                            }}
                          >
                            {evento.rol.replace('_', ' ')}
                          </span>
                        </TableCell>

                        <TableCell align="center" width={60} className="pb-0">
                          {evento.ventas}
                        </TableCell>

                        <TableCell align="center" width={60} className="px-0 pb-0">
                          <span
                            className="circulo-estado"
                            style={{
                              backgroundColor: colorCirculo(evento.estado.estado),
                              boxShadow: `0 0 5px ${colorCirculo(evento.estado.estado)}`
                            }}
                          />
                          {evento.estado?.estado}
                        </TableCell>

                        <TableCell scope="row" align="center" width={30} className="pb-0" >
                          <IconButton
                            aria-label="see more"
                            id={evento.id}
                            aria-controls={openSeeMore ? 'long-menu' : undefined}
                            aria-expanded={openSeeMore ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClickSeeMore}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={evento.id}
                            aria-label="report"
                            anchorEl={anchorEl}
                            open={evento.id == anchorElID}
                            onClose={handleCloseSeeMore}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <MenuItem onClick={(e) => HandleDownloadReport(e, evento)}>
                              <ListItemIcon>
                                <TableChartIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Reporte</ListItemText>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Alert severity="info" color="secondary">
                          {filtroEventos !== "Todo"
                            ? `Aún no tienes eventos ${filtroEventos.toLowerCase()}s.`
                            : "Aún no tienes eventos."
                          }
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )
                )}
                <MensajeFinalizarCreacionEvento open={open} handleClose={handleClose} eventoId={eventoId} />
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
};
export default withAuthenticationRequired(Evento, {
  onRedirecting: () => <Loading />,
});