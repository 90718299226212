const SideBar = ({ id, children }) => {

  return (
    <div
      style={{ width: '350px', transition: 'transform 0.3s ease', height: '100%' }}
      className='position-absolute bg-white border rounded-3 start-0 w-full'
    >
      <div
        id={id}
        className="d-flex flex-column w-full gap-2 h-100 px-4 pt-3">
        {children}
      </div>
    </div>
  )
}

export default SideBar;