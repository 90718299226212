import { useState, useEffect } from 'react'
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { Container, Badge, Alert, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, CircularProgress } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import CheckIcon from '@mui/icons-material/Check';


export const ExcelImport = () => {
  const [typeError, setTypeError] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const param = useParams();
  const history = useHistory();
  const [categorias, setCategorias] = useState();
  const [ubicaciones, setUbicaciones] = useState();
  const [tarifas, setTarifas] = useState();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [enviado, setEnviado] = useState(false);
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
  const excelTickets = "/Tickets.xlsx"
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect (() => {
    const fetchCategorias = async () => {
      try {
        let totalCategorias = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';
      
        while (hasMoreTickets) {
          const response = await api.categorias.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const categorias  = response.data.categorias; 

            if (categorias && categorias.length > 0) {
              totalCategorias = [...totalCategorias, ...categorias];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
            setCategorias(totalCategorias)
        }    
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    }
    fetchCategorias();
  },[param.id]);
  
  
  useEffect (() => {
    const fetchUbicaciones = async () => {
      try {
        let totalUbicaciones = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';
      
        while (hasMoreTickets) {
          const response = await api.locations.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const ubicaciones  = response.data.ubicaciones;

            if (ubicaciones && ubicaciones.length > 0) {
              totalUbicaciones = [...totalUbicaciones, ...ubicaciones];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setUbicaciones(totalUbicaciones)
        }    
      } catch (error) {
          setState({
            ...state,
            error: error.error,
          })
        }
    }
    fetchUbicaciones();
  }, [param.id])

  useEffect (() => {
    const fetchTarifa = async () => {
        try {
          let totalTarifas = [];
          let currentPage = 1;
          let hasMoreTickets = true;
          let filtro = '';
      
          while (hasMoreTickets) {
            const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
            if (response.status === "success") {
              const tarifas  = response.data.tarifas; 

              if (tarifas && tarifas.length > 0) {
                totalTarifas = [...totalTarifas, ...tarifas];
                currentPage++;
              } else {
                hasMoreTickets = false;
              }
            }
              setTarifas(totalTarifas)
          }    
        } catch (error) {
            setState({
              ...state,
              error: error.error,
            });
        }
    }
    fetchTarifa();
  },[param.id]);
  const entradasTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 1 );

  const procesarArchivoExcel = (e) => {
    const archivo = e.target.files[0];
    const filtro = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if(archivo) {
      if(archivo && filtro.includes(archivo.type)){
        setTypeError(null);
        setFileName(archivo.name);
        leerArchivoExcel(archivo);
      }
      else {
        setTypeError('Por favor, asegúrese de que el archivo seleccionado sea de tipo Excel(.xlsx o .xls.)')
        setFileName('');
      }
    } 
  }

  const errores = [];
  const validaryGuardar = () => {
    setLoading(true);
    if (!excelData) {
        setTypeError('Por favor, cargue un archivo Excel válido antes de enviar.');
        setLoading(false);
        return;
    }
    
    let errores = [];
        const valido = excelData.every((row, index) => {
          console.log(`Validando fila ${index + 1}:`, row); 
         
        const ubicacionValida = !row.nameubicacion || ubicaciones.some(ubicacion => {
          console.log(`Comparando ubicacion: "${ubicacion.ubicacion}" con row.nameubicacion: "${row.nameubicacion}"`);
          return ubicacion.ubicacion && row.nameubicacion && ubicacion.ubicacion.trim() === row.nameubicacion.trim();
      });

      const categoriaValida = !row.namecategoria || categorias.some(categoria => {
          console.log(`Comparando categoria: "${categoria.categoria}" con row.namecategoria: "${row.namecategoria}"`);
          return categoria.categoria && row.namecategoria && categoria.categoria.trim() === row.namecategoria.trim();
      });

      const tarifaValida = entradasTarifas.some(tarifa => {
        console.log(`Comparando tarifa: "${tarifa.concepto}" con row.nametarifa: "${row.nametarifa}"`);
        return tarifa.concepto && row.nametarifa && tarifa.concepto.trim() === row.nametarifa.trim();
    });

      if (!ubicacionValida) {
          errores.push(`Ubicación inválida en fila ${index + 1}: ${row.nameubicacion}`);
      }
      if (!categoriaValida) {
          errores.push(`Categoría inválida en fila ${index + 1}: ${row.namecategoria}`);
      }
      if (!tarifaValida) {
        errores.push(`Tarifa inválida en fila ${index + 1}: ${row.nametarifa}`);
    }

      return ubicacionValida && categoriaValida && tarifaValida;
  });

  if (!valido) {
      console.log('Errores de validación:', errores);
      setTypeError(`Los datos del archivo Excel contienen errores:\n${errores.join('\n')}`);
      setLoading(false);
      return;
  }
  guardarTickets();
  };

  const guardarTickets = async () => {
    try {
      const response = await api.eventos.createTickets(param.id, excelData);

      if (response.status === 'success') { 
        console.log('Ticket creado exitosamente:');
        setLoading(false);
        setEnviado(true);
        setTimeout(() => {
          history.push(`/eventos/${param.id}`);
        }, 2000); 
      }
      else { 
        console.error('Error al crear el ticket:', response.error); 
        setLoading(false);
      }
    } catch (error) {
        console.error('Error al intentar guardar los tickets:', error.message);
        setLoading(false);
    }
  };


  const leerArchivoExcel = (archivo) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    
      const cleanedJson = jsonData.map(row => {
        const cleanedRow = {};
        for (const key in row) {
          let value = String(row[key]).replace(/\t/g, '').trim();
          if (key === 'idevento') {
            value = parseInt(value, 10);
          }
          if (key === 'cantidadtarifa') {
            value = Number(value);
          }
          if (key === 'importe') {
            value = parseFloat(value);
          }
          cleanedRow[key.trim()] = value;
        }
        return cleanedRow;
      });
      setExcelData(cleanedJson);
    };
    reader.readAsBinaryString(archivo);
  }

  const cleanFile = () => {
    setFileName('');
    setTypeError(null);
  }

  return (
    <>
      <Container>
        <h5>{t("excelImport.format")}</h5>
        <div style={{display: isMobile ? 'block' : 'flex', justifyContent: "end"}}>
          <a href={excelTickets} download="excelTickets.xlsx" className='text-decoration-none'>
            <Button variant="contained" color="success" className='d-flex justify-content-center align-items-center gap-2'>
              <SaveAltOutlinedIcon/>
              Descargar plantilla
            </Button>
          </a>
        </div>
        
        {!isMobile && (
          <TableContainer component={Paper} sx={{marginBottom: "15px", marginTop: "15px"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>nombre</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>apellido</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>nameubicacion</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: '#f0f0f0' }}>nametarifa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">({t("excelImport.example")} Maria)</TableCell>
                  <TableCell align="center">({t("excelImport.example")} Fabbro)</TableCell>
                  <TableCell align="center">({t("excelImport.example")} Mesa 01)</TableCell>
                  <TableCell align="center">({t("excelImport.example")} General)</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      
        <div style={{display: "flex", gap: "20px", flexDirection: isMobile ? 'column' : 'row'}}>
          <div className='especificaciones mt-4'>
            <h5>{t("excelImport.specifications")}</h5>
            <li>{t("excelImport.extension")}</li>
            <li>{t("excelImport.sp3")}</li>
            <li>{t("excelImport.sp4")}</li>
            <li>{t("excelImport.sp5")}</li>
          </div>
          <div className='formulario-ingreso mt-4'>
            <label 
              htmlFor="exampleFile" 
              style={{
                width: "350px", 
                height: "270px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                flexDirection: "column", 
                borderRadius: "10px",
                boxShadow: "10px 10px 30px rgba(0,32,61,.3098039216)",
                padding: isMobile ? "15px" : "25px",
                cursor: "pointer",
                margin: "0px auto 20px"
              }}
            >
              <h4 className='fw-medium mb-3'>{t("excelImport.uploadFile")}</h4>
              <div style={{
                position: "relative",
                border: "2px dashed #e1e2e6",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center", 
                alignItems: "center", 
                gap: "10px",
                padding: "10px",
                borderRadius: "10px",
              }}>
                {!fileName ? (
                  <>
                    <img 
                      src={process.env.PUBLIC_URL + '/subir-excel.png'} 
                      alt="Fiesta" 
                      style={{ width: '70px', height: 'auto' }} 
                    />
                    <p>Cargar archivo</p>
                  </>
                ):(
                  <>
                    <Badge 
                      badgeContent={<CloseOutlinedIcon 
                      sx={{ fontSize: 15 }} />} 
                      color="error"
                      onClick={cleanFile}
                    >
                      <InsertDriveFileOutlinedIcon sx={{ fontSize: 40}}/>
                    </Badge>
                    <span>{fileName}</span>
                  </>
                )}
              </div>
              <input
                id="exampleFile"
                name="file"
                type="file"
                accept=".xlsx,.xls"
                hidden
                onChange={procesarArchivoExcel}
              />
            </label>
          </div>
        </div>

        {typeError && 
          <Alert severity="error" className='mb-3'>{typeError}</Alert>
        }
        {enviado && ( 
          <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success" className="mb-3">{t("excelImport.succesCreated")}</Alert>
        )}
          
        <div className='opciones d-flex justify-content-end'>
          <div>
            <Button variant="outlined" className='mr-3' onClick={() => history.push("/eventos/"+ param.id +"/nuevoTicket")}>{t("button.cancel")}</Button>
          </div>
          <div>
            <Button 
              variant="contained" 
              onClick={validaryGuardar}
              disabled={loading} 
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {loading ? 'Enviando...' : t("excelImport.create")}
            </Button>
          </div>
        </div>
      </Container>
    </>
  )
}
