import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Label, Button, CardBody, Card, Collapse, Alert, Spinner } from "reactstrap";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { api } from "../../services/api";
import './Inscripcion.css';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const formatDateToISO = (date) => {
  return new Date(date).toISOString();
};

export const Inscripcion = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const initForm = {
    fecha: moment().toISOString(),
    email: '',
    nombre: '',
    apellido: '',
    celular: '',
    dni: '',
    nacimiento: '',
    domicilio: '',
    localidad: '',
    provincia: '',
    club: ''
  };

  const [formData, setFormData] = useState(initForm);
  const [formValid, setFormValid] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const history = useHistory();
  const param = useParams();
  const [data, setData] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const callApi = async () => {
    try {
      const response = await api.eventos.getPublic(param.id);

      if (response.status === "success") {
        setData(response.data.evento)
      }
    } catch (error) {
      setState({
        ...state,
        error: "Error en la Red.",
      });
    }
  }

  const attach = data?.attachs?.[0];
  const urlImg = attach?.url;

  useEffect(() => {
    callApi();
  }, [param.id]);

  useEffect(() => {
    const { fecha, nombre, apellido, dni, nacimiento, localidad, provincia, domicilio, email, celular, club } = formData;
    setFormValid(fecha && nombre && apellido && dni && nacimiento && localidad && provincia && domicilio && email && celular && club);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setSubmitMessage('');

    const formatoData = {
      ...formData,
      nacimiento: formatDateToISO(formData.nacimiento),
    };

    console.log(formatoData);

    try {
      const response = await api.inscripciones.createInscripcion(param.id, formatoData);
      if (response.status === "success") {
        setFormData(initForm);
        setSubmitted(true);
        setSubmitMessage("Inscripción exitosa");
        setTimeout(() => {
          history.push("/"); // Redirigir a otra vista después de 3 segundos
        }, 3000);
      } else {
        setError("Error al intentar crear la inscripción");
      }
    } catch (error) {
      console.error("Error al intentar crear la inscripción:", error);
      setError("Error en la red");
    } finally {
      setSubmitting(false);
    }
  };

  return (

    <div className="container">
      <div className='header w-100' style={{ height: '400px' }}>
        <img src={urlImg} className="object-fit-cover" style={{ width: '100%', height: '100%', objectPosition: 'center' }} />
      </div>
      <div className='evento-descripcion mh-100'>
        <div className="mx-5 my-4">
          {data && (
            <>
              <h2>{data.evento}</h2>
              <p>{data.descripcion}</p>
            </>
          )}
        </div>
      </div>
      <div className="form-wrapper">
        <Collapse isOpen={isOpen} style={{ transition: 'height 0.4s ease-in-out', opacity: '0.4s ease-in-out' }}>
          <Card className="card-custom">
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="d-flex gap-3">
                  <FormGroup floating className="w-50">
                    <Input
                      id="nombre"
                      name="nombre"
                      placeholder="Nombre"
                      type="text"
                      value={formData.nombre}
                      onChange={handleChange}
                      required
                    />
                    <Label for="nombre">Nombre</Label>
                  </FormGroup>
                  <FormGroup floating className="w-50">
                    <Input
                      id="apellido"
                      name="apellido"
                      placeholder="Apellido"
                      type="text"
                      value={formData.apellido}
                      onChange={handleChange}
                      required
                    />
                    <Label for="apellido">Apellido</Label>
                  </FormGroup>
                </div>
                <div className="d-flex gap-3">
                  <FormGroup floating className="w-50">
                    <Input
                      id="dni"
                      name="dni"
                      placeholder="DNI"
                      type="number"
                      value={formData.dni}
                      onChange={handleChange}
                      required
                    />
                    <Label for="dni">DNI</Label>
                  </FormGroup>
                  <FormGroup floating className="w-50">
                    <Input
                      id="nacimiento"
                      name="nacimiento"
                      placeholder="Fecha Nacimiento"
                      type="date"
                      value={formData.nacimiento}
                      onChange={handleChange}
                      required
                    />
                    <Label for="nacimiento">Fecha de Nacimiento</Label>
                  </FormGroup>
                </div>
                <div className="d-flex gap-3">
                  <FormGroup floating className="w-50">
                    <Input
                      id="localidad"
                      name="localidad"
                      placeholder="Localidad"
                      type="text"
                      value={formData.localidad}
                      onChange={handleChange}
                      required
                    />
                    <Label for="localidad">Localidad</Label>
                  </FormGroup>
                  <FormGroup floating className="w-50">
                    <Input
                      id="provincia"
                      name="provincia"
                      placeholder="Provincia"
                      type="text"
                      value={formData.provincia}
                      onChange={handleChange}
                      required
                    />
                    <Label for="provincia">Provincia</Label>
                  </FormGroup>
                </div>
                <FormGroup floating>
                  <Input
                    id="domicilio"
                    name="domicilio"
                    placeholder="Domicilio (Calle y Nro.)"
                    type="text"
                    value={formData.domicilio}
                    onChange={handleChange}
                    required
                  />
                  <Label for="domicilio">Domicilio (Calle y Nro.)</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <Label for="email">Email</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="celular"
                    name="celular"
                    placeholder="Celular"
                    type="tel"
                    value={formData.celular}
                    onChange={handleChange}
                    required
                  />
                  <Label for="celular">Celular</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="club"
                    name="club"
                    placeholder="Club"
                    type="text"
                    value={formData.club}
                    onChange={handleChange}
                    required
                  />
                  <Label for="club">Club</Label>
                </FormGroup>
                <div className="d-flex justify-content-center">
                  <Button type="submit" color="primary" disabled={submitting} className="submit-button">
                    {submitting ? (
                      <>
                        <Spinner size="sm" /> Enviando...
                      </>
                    ) : (
                      "Enviar"
                    )}
                  </Button>
                </div>
              </Form>
              {submitting && (
                <Alert color="warning" className="mt-3">
                  <Spinner color="primary" className="mr-3" /> Cargando... por favor espere.
                </Alert>
              )}
              {error && <Alert color="danger" className="mt-3">{error}</Alert>}
              {submitted && (
                <Alert color="success" className="mt-3">
                  {submitMessage}
                </Alert>
              )}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </div>
  );
};

export default Inscripcion;