import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import "./shareButton.css";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import facebook_icon from "../../assets/facebook_icon.svg";
import Twitter_icon from "../../assets/Twitter_icon.svg";
import whatsapp_icon from "../../assets/whatsapp_icon.svg";
import { useLocation } from "react-router-dom";

const ShareButton = ({idEvento, ...props}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
const currentPath = `https://passgo.com.ar/evento/${idEvento}/informacion`
  const shareMessage = encodeURIComponent(
    "Hey! Mira este evento.. " + currentPath
  );

  const vistaEventoInformacion = location.pathname.includes('/informacion');

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
      <DropdownToggle className={`${vistaEventoInformacion ?  'button-special' : 'button-share'}`} style={{padding: "0px"}}>
        <ShareOutlinedIcon {...props} />   
      </DropdownToggle>
      <DropdownMenu className="custom-dropdown-menu">
        <DropdownItem
          href={`https://www.facebook.com/sharer/sharer.php?u=` + currentPath}
          target="_blank"
          className="drop_item facebook"
        >
          <img className="rrss" src={facebook_icon} alt="fb"></img>
        </DropdownItem>
        <DropdownItem
          href={`https://api.whatsapp.com/send?text=` + shareMessage}
          target="_blank"
          className="drop_item-whatsapp"
        >
          <img className="rrss" src={whatsapp_icon} alt="wsp"></img>
        </DropdownItem>
        <DropdownItem
          href={`https://twitter.com/intent/tweet?url=` + currentPath}
          target="_blank"
          className="drop_item twitter"
        >
          <img className="rrss" src={Twitter_icon} alt="tw"></img>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ShareButton;
