import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale, 
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);


export const Eventos = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [evento, setEvento] = useState();
  const [eventosumary, setEventoSumary] = useState();
  const [tarifas, setTarifas] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const param = useParams();
  const [t, i18n] = useTranslation("global");


  useEffect(() => {
    callTarifas();
    callTickets();
    callVouchers();
    const callApi = async () => {
      try {
        const response = await api.eventos.get(param.id);
        if (response.status === "success") {
          setEvento(response.data.evento);
          setEventoSumary(response.data.eventosumary);
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };
    callApi();
  }, []);

  const callTarifas = async () => {
    try {
      const response = await api.tarifas.getAll(param.id);
      if (response.status === "success") {
        setTarifas(response.data.tarifas);
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const callTickets = async () => {
    try {
      let totalTickets = [];
      let currentPage = 1;
      let hasMoreTickets = true;

      while (hasMoreTickets) {
        const response = await api.eventos.getTickets(
          param.id,
          '',
          10,
          currentPage
        );
        if (response.status === "success") {
          const tickets = response.data.tikets;

          if (tickets && tickets.length > 0) {
            totalTickets = [...totalTickets, ...tickets];
            currentPage++;
          } else {
            hasMoreTickets = false;
          }
        }
        setTickets(totalTickets);
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const callVouchers = async () => {
    try {
      let totalVouchers = [];
      let currentPage = 1;
      let hasMoreVouchers = true;

      while (hasMoreVouchers) {
        const response = await api.vouchers.getVouchers(
          param.id,
          10,
          currentPage
        );
        if (response.status === "success") {
          const vouchers = response.data.vouchers;

          if (vouchers && vouchers.length > 0) {
            totalVouchers = [...totalVouchers, ...vouchers];
            currentPage++;
          } else {
            hasMoreVouchers = false;
          }
        }
        setVouchers(totalVouchers);
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const tiposSummary = eventosumary?.reduce((acc, item) => {
    if (!acc[item.tipo]) {
      acc[item.tipo] = {
        Cantidad: 0,
        Escaneados: 0,
        Pendientes: 0,
      };
    }
    acc[item.tipo].Cantidad += item.cantidad;
    acc[item.tipo].Escaneados += item.escaneados;
    acc[item.tipo].Pendientes =
      acc[item.tipo].Cantidad - acc[item.tipo].Escaneados;

    return acc;
  }, {});

  const data = Object.keys(tiposSummary || {}).map((tipo) => ({
    Tipo: tipo,
    Cantidad: tiposSummary[tipo]?.Cantidad,
    Escaneados: tiposSummary[tipo]?.Escaneados,
    Pendientes: tiposSummary[tipo]?.Pendientes,
  }));

  const conteoTickets = tickets.reduce((acc, ticket) => {
    const concepto = ticket.tarifa?.concepto;
    const id = ticket.tarifa?.id; 
    
    if (!acc[concepto]) {
      acc[concepto] = {
        id: id,
        cantidad: 0
      };
    }
    
    acc[concepto].cantidad += 1;

  
    return acc;
  }, {});

  const conteoVouchers = vouchers.reduce((acc, voucher) => {
    const concepto = voucher.tarifa?.concepto;
    const id = voucher.tarifa?.id; 
    
    if (!acc[concepto]) {
      acc[concepto] = {
        id: id,
        cantidad: 0
      };
    }
    
    acc[concepto].cantidad += 1;

  
    return acc;
  }, {});


  const options = {
    responsive: true,
    plugins: {
        legend: {
          display: false
        },
    },
    cutout: '70%'
};

  const dataGraficoTickets = Object.values(conteoTickets).map(item => item.cantidad);
  const noHayTickets = dataGraficoTickets.length === 0 || dataGraficoTickets.every(value => value === 0);

  const ticketsPorConcepto = {
    labels: noHayTickets ? ['No hay datos'] : Object.keys(conteoTickets),
    datasets: [
        {
        data: noHayTickets ? [1] : dataGraficoTickets,
        backgroundColor: noHayTickets ? ['#d3d3d3'] : [
          '#c8eaff',
          '#d5c8ff',
          '#c1f6ca',
          '#fdefb5',
          '#f6cacc'
        ],
        borderColor: noHayTickets ? ['#a9a9a9'] : [
          '#067bea',
          '#8763f9',
          '#64b565',
          '#f7d43f',
          '#ef3231',
        ],
        borderWidth: '1' 
        }
    ]
  }

  const dataGraficoVouchers = Object.values(conteoVouchers).map(item => item.cantidad);
  const noHayVouchers = dataGraficoVouchers.length === 0 || dataGraficoVouchers.every(value => value === 0);

  const vouchersPorConcepto = {
    labels: noHayVouchers ? ['No hay datos'] : Object.keys(conteoVouchers),
    datasets: [
        {
        data: noHayVouchers ? [1] : dataGraficoVouchers,
        backgroundColor: noHayVouchers ? ['#d3d3d3'] : [
          '#fdefb5',
          '#f6cacc',
          '#c8eaff',
          '#d5c8ff',
          '#c1f6ca',
        ],
        borderColor: noHayVouchers ? ['#a9a9a9'] : [
          '#f7d43f',
          '#ef3231',
          '#067bea',
          '#8763f9',
          '#64b565'
        ],
        borderWidth: '1' 
        }
    ]
}/*
  
  /*---------------------------------------------------------------------*/
  return (
    <>
      {evento && (
        <Container fluid="sm">
          {data.map((item, index) => (
            <div
              key={index}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "25px", marginBottom: "25px", flexWrap: "wrap"}}
            >
              <div
                className="shadow"
                style={{
                  width: "230px",
                  height: "180px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    marginBottom: "10px",
                    backgroundColor: "#e4f3fc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    className="material-symbols-outlined"
                    style={{ color: "#067bea", fontSize: "30px" }}
                  >
                    confirmation_number
                  </i>
                </div>
                <h2 className="m-0">{item.Cantidad}</h2>
                <p className="text-secondary m-0">Total {item.Tipo}</p>
              </div>

              <div
                className="shadow"
                style={{
                  width: "230px",
                  height: "180px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    marginBottom: "10px",
                    backgroundColor: "#f5f2ff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    className="material-symbols-outlined"
                    style={{ color: "#8763f9", fontSize: "30px" }}
                  >
                    qr_code_scanner
                  </i>
                </div>
                <h2 className="m-0">{item.Escaneados}</h2>
                <p className="text-secondary m-0">{t("dashboard.scanned")}</p>
              </div>

              <div
                className="shadow"
                style={{
                  width: "230px",
                  height: "180px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    marginBottom: "10px",
                    backgroundColor: "#fffbeb",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    className="material-symbols-outlined"
                    style={{ color: "#f7d43f", fontSize: "30px" }}
                  >
                    pending_actions
                  </i>
                </div>
                <h2 className="m-0">{item.Pendientes}</h2>
                <p className="text-secondary m-0">{t("dashboard.pending")}</p>
              </div>
            </div>
          ))}

          <div style={{ display: "flex", gap: "25px", marginBottom: "25px", flexWrap: "wrap", justifyContent: "center"}}>
            <div
              className="shadow"
              style={{
                width: "270px",
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px"
              }}
            >
              <h2 className="mb-2">Tickets</h2>
              <div style={{width: "200px", height: "200px"}}>
                <Doughnut data={ticketsPorConcepto} options={options}/>
              </div>
              <div style={{width: '100%', marginTop: '10px'}}>
                {!noHayTickets ? (
                  <div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p><b>{t("dashboard.type")}</b></p>
                      </div>
                      <div>
                        <p><b>{t("dashboard.quantity")}</b></p>
                      </div>
                    </div>
                    {Object.entries(conteoTickets).map(([concepto, { cantidad }]) => (
                      <div key={concepto} className="w-100 d-flex mb-2">
                        <div style={{width: '50%'}}>
                          <p className="my-0 ml-2">{concepto}</p>
                        </div>
                        <div style={{width: '50%'}}>
                          <p className="text-end my-0 mr-2">{cantidad}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-100 d-flex justify-content-center">
                    <p className="m-0">{t("dashboard.noTicketsYet")}</p>
                  </div>
                )}
              </div>
            </div>

            <div
              className="shadow"
              style={{
                width: "270px",
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px"
              }}
            >
              <h2 className="mb-2">Vouchers</h2>
              <div style={{width: "200px", height: "200px"}}>
                <Doughnut data={vouchersPorConcepto} options={options}/>
              </div>
              <div style={{width: '100%', marginTop: '10px'}}>
                {!noHayVouchers ? (
                  <div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p><b>{t("dashboard.type")}</b></p>
                      </div>
                      <div>
                        <p><b>{t("dashboard.quantity")}</b></p>
                      </div>
                    </div>
                    {Object.entries(conteoVouchers).map(([concepto, { cantidad }]) => (
                      <div key={concepto} className="w-100 d-flex mb-2">
                        <div style={{width: '50%'}}>
                          <p className="my-0 ml-2">{concepto}</p>
                        </div>
                        <div style={{width: '50%'}}>
                          <p className="text-end my-0 mr-2">{cantidad}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-100 d-flex justify-content-center">
                    <p className="m-0">{t("dashboard.noVouchersYet")}</p>
                  </div>
                )}   
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
export default withAuthenticationRequired(Eventos, {
  onRedirecting: () => <Loading />,
});