import React, { useEffect, useState } from "react";
import { FaFacebookF, FaInstagram, FaWhatsapp, FaPhone } from "react-icons/fa"
import { IoMailOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../components/Footer";

import costumerGino from "../assets/costumerGino.png"
import costumerCandela from "../assets/costumerCandela.png"
import costumerPablo from "../assets/costumerPablo.png"
import costumerMarta from "../assets/costumerMarta.png"
import costumerDaniel from "../assets/costumerDaniel.png"

import "./creaEventos.css"
import { api } from '../services/api';
import textPassGO from "../assets/textPassGO.svg";

import { Rating, FormControl, TextField, Button } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SendIcon from '@mui/icons-material/Send';


export const CreaEventos = () => {
  const [t] = useTranslation("global");
  const [nombre, setNombre] = useState();
  const [email, setEmail] = useState();
  const [telefono, setTelefono] = useState();
  const [consulta, setConsulta] = useState();
  const [nombreError, setNombreError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [telefonoError, setTelefonoError] = useState("");
  const [consultaError, setConsultaError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const words = [
    "se crean.",
    "se gestionan.",
    "se publicitan.",
    "crecen."
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500);

    return () => clearInterval(interval); // Limpia el intervalo al desmontar
  }, []);

  const settingsDif = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "costumer-slider",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    /*  speed: 500, */
    /* nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />, */
  };

  const items = [
    {
      key: 1,
      src: costumerGino,
      name: 'Gino',
      rating: <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly />,
      text: '"La posibilidad de diseñar nuestras propias entradas y vouchers fue un gran plus. Nuestros asistentes quedaron impresionados con la calidad, y la gestión del acceso mediante códigos QR hizo que todo fluyera sin contratiempos."',
    },
    {
      key: 2,
      src: costumerCandela,
      name: 'Candela',
      rating: <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />,
      text: '"Gracias a esta plataforma, organizar nuestro festival anual fue más fácil que nunca. Desde la venta de entradas hasta la promoción en redes sociales, todo fue gestionado de manera impecable. ¡Definitivamente la usaremos para futuros eventos!"',
    },
    {
      key: 3,
      src: costumerPablo,
      name: 'Pablo',
      rating: <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />,
      text: '"Poder publicitar nuestro evento y vender entradas desde un solo lugar fue un cambio total. La plataforma nos permitió enfocarnos en la experiencia de los asistentes, y los resultados fueron increíbles. ¡Un éxito total!"	',
    },
    {
      key: 4,
      src: costumerMarta,
      name: 'Marta',
      rating: <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly />,
      text: '"La plataforma nos ayudó a gestionar nuestro evento benéfico de principio a fin. La opción de personalizar las entradas y seguir las ventas en tiempo real nos dio el control total que necesitábamos. ¡El evento fue un éxito rotundo!"',
    },
    {
      key: 5,
      src: costumerDaniel,
      name: 'Daniel',
      rating: <Rating name="half-rating-read" defaultValue={4} precision={0.5} readOnly />,
      text: '"La organización de nuestro torneo deportivo fue impecable gracias a esta plataforma. Desde la venta de entradas hasta el seguimiento en tiempo real, todo estuvo bajo control. Los asistentes quedaron impresionados con la facilidad del acceso y la gestión del evento. "	',
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nombre.trim() === '') {
      setNombreError(<>{t("errors.emptyField")}</>);
    }
    if (email.trim() === '') {
      setEmailError(<>{t("errors.emptyField")}</>);
    }
    if (telefono.trim() === '') {
      setTelefonoError(<>{t("errors.emptyField")}</>);
    }
    if (consulta.trim() === '') {
      setConsultaError(<>{t("errors.emptyField")}</>);
    }

    else {

      const newConsulta = {
        nombre: nombre,
        email: email,
        telefono: telefono,
        consulta: consulta
      }
      try {
        const response = await api.consultas.create(newConsulta);
        console.log(newConsulta);
        if (response.status === "success") {
          setNombre('')
          setEmail('')
          setTelefono('')
          setConsulta('')
          setSubmitted(true);
        }
      } catch (error) {
        setNombre('')
        setEmail('')
        setTelefono('')
        setConsulta('')
      }
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", position: "relative" }}>

      {/*  -------- HERO  ----------*/}
      <div className="hero-div-crear" >
        <img src={`${textPassGO}`} alt="PassGO" />
        <h3 className="mt-4 hero-title">DONDE TUS EVENTOS</h3>
        <h3 className="hero-h3"><span> {words[index]}</span></h3>
      </div>


      {/*  -------- INFORMACION  ----------*/}
      <div className="info-first-div" >
        <h1 className="info-first-div-title" >
          Todo en una plataforma intuitiva
        </h1>
        <p className="info-first-div-p">
          Te proporcionamos todas las herramientas necesarias para que tu evento sea un <b>éxito.</b><br />
          Planifica, ejecuta y monitorea cada detalle <b>sin complicaciones</b>, todo desde un solo lugar.
        </p>
        <button className="info-first-div-button">
          <a href="#contactus" className="info-first-div-a"><CalendarMonthOutlinedIcon sx={{ marginRight: "10px" }} />Crear evento</a>
        </button>
      </div>

      <div className="info-second-div" style={{ height: "576px" }}>
        <div style={{ backgroundColor: "transparent", height: "100px", width: "100%" }}></div>
        <h1 className="info-second-div-title">Nuestros diferenciales</h1>
        <div className="mt-2">
          <Slider {...settingsDif}>
            <div className="slide">
              <div className="slide-card">
                <p className="slide-title">
                  <EditCalendarOutlinedIcon sx={{ marginRight: "10px" }} />
                  Crea y gestiona tus eventos
                </p>
                <p className="slide-description">
                  En PASSGO podes crear tus eventos y gestionarlos de principio a fin.
                  Descubrí todas las funcionalidades que tenemos para vos.
                </p>
              </div>
            </div>
            <div className="slide">
              <div className="slide-card">
                <p className="slide-title">
                  <CreditScoreOutlinedIcon sx={{ marginRight: "10px" }} />
                  Venta de entradas
                </p>
                <p className="slide-description">
                  Puedes crear y personalizar entradas y vouchers para tu evento con facilidad.
                  Diseña a tu gusto, elige entre múltiples plantillas o crea un diseño desde
                  cero que refleje la identidad de tu evento.
                </p>
              </div>
            </div>
            <div className="slide">
              <div className="slide-card">
                <p className="slide-title">
                  <Groups2OutlinedIcon sx={{ marginRight: "10px" }} />
                  Publicidad para tus eventos
                </p>
                <p className="slide-description">
                  Te ofrecemos las herramientas necesarias para publicitar tu evento y llegar a una audiencia más amplia.
                </p>
              </div>
            </div>

            <div className="slide">
              <div className="slide-card">
                <p className="slide-title">
                  <DesignServicesOutlinedIcon sx={{ marginRight: "10px" }} />
                  Diseña tus tickets y vouchers
                </p>
                <p className="slide-description">
                  Creá y personalizá tus entradas y vouchers para tu evento con facilidad.
                  Diseña a tu gusto, elige entre múltiples plantillas o crea un diseño desde cero que refleje la identidad de tu evento.
                </p>
              </div>
            </div>

            <div className="slide">
              <div className="slide-card">
                <p className="slide-title">
                  <QrCodeScannerOutlinedIcon sx={{ marginRight: "10px" }} />
                  Ingreso con código QR
                </p>
                <p className="slide-description">
                  Con nuestra tecnología de escaneo de códigos QR la gestión de accesos es rápida, segura y eficiente.
                </p>
              </div>
            </div>

            <div className="slide">
              <div className="slide-card">
                <p className="slide-title">
                  <AssessmentOutlinedIcon sx={{ marginRight: "10px" }} />
                  Datos e informes
                </p>
                <p className="slide-description">
                  Obtén una visión completa de tu evento con nuestros informes
                  y análisis de ventas. Nuestra plataforma te proporciona datos precisos
                  en tiempo real, permitiéndote tomar decisiones informadas y optimizar
                  el éxito de tu evento.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      {/*  -------- TESTIMONIOS  ----------*/}
      <div className="costumers-div w-100" style={{ height: "auto" }}>
        <h1 className="costumers-div-title w-100">Nuestros clientes</h1>
        <div className="mt-3 w-100 costumer-slider">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={index} className="d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center mb-3 w-100" style={{}}>
                  <div className="d-flex flex-row align-items-center mb-4">
                    <div className="picture-costumer">
                      <img
                        style={{ width: "70px", height: "70px", borderRadius: "50%", marginRight: "25px" }}
                        src={item.src}
                        alt={`costumer ${item.name}`}
                      />
                    </div>
                    <div className="info-costumer d-flex flex-column">
                      <p className="mb-0 name-costumer">{item.name}</p>
                      <span>{item.rating}</span>
                    </div>
                  </div>
                  <div className="text-div-costumer">
                    <p className="text-p-costumer">{item.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/*  -------- CONTACT US  ----------*/}
      <div id="contactus" className="contactus-div">
        <h1 className="my-2" style={{ marginBottom: "10px", fontWeight: "bold", color: "#221A60" }}> ¡Contactanos!</h1>
        <div className="contactus-div-form">
          <form className="contactus-div-form-form" onSubmit={handleSubmit} >

            <FormControl sx={{ width: "100%" }}>
              <TextField
                sx={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "15px",
                  input: {
                    padding: "10px 15px",
                  },
                }}
                id="name"
                name="name"
                placeholder="Nombre y Apellido"
                type="string"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                sx={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "15px",
                  input: {
                    padding: "10px 15px",
                  },
                }}
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            {' '}
            <FormControl sx={{ width: "100%" }}>
              <TextField
                sx={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "15px",
                  input: {
                    padding: "10px 15px",
                  },
                }}
                id="phone"
                name="phone"
                placeholder="Teléfono"
                type="string"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
            </FormControl>
            {' '}
            <FormControl sx={{ width: "100%" }}>
              <TextField
                sx={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "15px",
                  input: {
                    padding: "10px 15px",
                  },
                }}
                id="text"
                name="text"
                placeholder="Dejanos tu consulta!"
                multiline
                value={consulta}
                onChange={(e) => setConsulta(e.target.value)}
              />
            </FormControl>
            {' '}

            <Button type="submit" className="boton-submit">
              <SendIcon sx={{width:"20px", height:"20px", marginRight:"15px"}}/> Enviar
            </Button>
            {!submitted ? (
              <div></div>
            ) : (
              <div><p style={{ fontSize: "18px", fontWeight: "700", color: "green", padding: "10px" }}>Gracias por comunicarte con nosotros. Responderemos tu consulta a la brevedad.</p></div>
            )}
          </form>

        </div>

        <div className="social-div mb-4">
          <div className="social-div-redes" >
            <h5 className="redes-h5">
            <FaFacebookF style={{fontSize:"25px", paddingRight:"8px",}}/> /passgo
            </h5>
            <h5 className="redes-h5">
              <FaInstagram style={{ fontSize: "30px", paddingRight: "8px" }} /> @passgo
            </h5>
            <h5 className="redes-h5">
              <FaPhone style={{ fontSize: "30px", paddingRight: "8px" }} /> 3482-446801
            </h5>
            <h5 className="redes-h5">
              <FaWhatsapp style={{ fontSize: "30px", paddingRight: "8px" }} /> 3482-446801
            </h5>
            <h5 className="redes-h5">
              <IoMailOutline style={{ fontSize: "30px", paddingRight: "8px" }} />pruebadeenviodecorreocaar@gmail.com
            </h5>
          </div>
        </div>

      </div>
      <div className="w-100" style={{zIndex:"3"}}>
          <Footer />
      </div>
      
    </div>
    
  )
}


export default CreaEventos;