import './HistorialCompras.css';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import VolverButton from '../../../components/VolverButton';
import { useParams } from "react-router-dom";
import { api } from '../../../services/api';
import Loading from '../../../components/Loading';

const HistorialCompras = () => {
  const [compras, setCompras] = useState(null);
  const [error, setError] = useState(null);
  const param = useParams();
  const [loading, setLoading] = useState(true);
  const [statusData, setStatusData] = useState(null);

  const callApi = async () => {
    try {
      const response = await api.compras.getCompra(param.id);

      if (response.status === 'success') {
        setCompras(response.data.compra);
        setStatusData(response.status);
      } else if (response.status === 'in progress') {
        setCompras(response.data);
        setStatusData(response.status);
      } else {
        setError('Error al intentar cargar los detalles.');
      }
    } catch (err) {
      setError('Error en la red.');
    }
    setLoading(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!compras) {
    return <Alert color="danger">
      Algo falló, no se pudo cargar los detalles de la compra.
    </Alert>;
  }

  if (!compras.fecha) {
    return (
      <Alert color="warning">
        No se encontró la fecha en los detalles de la compra.
      </Alert>
    );
  }

  const date = new Date(compras.fecha);
  const fechaDeCompra = date.toLocaleDateString();
  const horaDeCompra = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <>
      <Container fluid="sm" className="custom-container">
        <Row className="d-flex justify-content-center text-left">
          <Col md={8}>
            <div className="custom-card mb-5">
              <div className="custom-card-content">
                <div className="custom-item">
                  <div className="custom-id-container">
                    <span className="custom-id">{fechaDeCompra} | {horaDeCompra}</span>
                  </div>
                  <hr className="custom-divider" />
                  <div className="custom-detail">
                    <span className="custom-label">Total:</span>
                    <span className="custom-value">$TOTAL DE LA COMPRA$</span>
                  </div>
                  <hr className="custom-divider" />
                  <div className="custom-detail">
                    <span className="custom-label">Tipo de pago:</span>
                    <span className="custom-value">Efectivo/Transferencia/Tarjeta</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="custom-footer">
          <VolverButton />
        </div>
      </Container>
    </>
  );
};

export default HistorialCompras;