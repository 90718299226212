import React, { useEffect, useState } from 'react';
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import VolverButton from '../../components/VolverButton';
import { useHistory } from "react-router-dom";
import { TarifaDetalles } from "../../components/modales/TarifaDetalles"
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { Container, Box, TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useMediaQuery } from "react-responsive";


const CrearTarifa = () => {
  const [tarifasTipo, setTarifasTipo] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation("global");
  const param = useParams();
  const [selectedOption, setSelectedOption] = useState('de pago');
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });

  const initForm = {
    idevento: param.id,
    idmoneda: 1,
    concepto: '',
    precio: 0,
    unidad: '',
    idtipo: '',
    limite_persona: 10,
    limite_online: 50,
    limite_maximo: 100,
    asumir_comision: false
  }
  const [formData, setFormData] = useState(initForm);

  const [formErrors, setFormErrors] = useState({
    concepto: false,
    precio: false,
    unidad: false,
    idtipo: false,
    limite_persona: false,
    limite_online: false,
    limite_maximo: false,
  });

  const callTipoTarifas = async () =>{
    try {
      const response = await api.tarifas.GetAllTarifasTipos();
      if(response.status === "success"){
        setTarifasTipo(response.data.tarifasTipo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    callTipoTarifas();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "limite_persona" || name === "precio" || name === "limite_online" || name === "limite_maximo") {
      if (/^\d*\.?\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (value.trim() !== "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,  
      }));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {
      concepto: formData.concepto.trim() === '',
      precio: selectedOption === 'de pago' ? !formData.precio : false,
      unidad: formData.unidad.trim() === '',
      idtipo: formData.idtipo == '',
      limite_persona: formData.limite_persona == '',
      limite_online: formData.limite_online == '',
      limite_maximo: formData.limite_maximo == '',
    };
    setFormErrors(errors);
    const isValid = Object.values(errors).every(error => !error);

    const newTarifa = {
      ...formData,
      idevento: parseInt(formData.idevento),
      precio: parseFloat(formData.precio),
      idtipo: parseInt(formData.idtipo),
      limite_persona: parseInt(formData.limite_persona),
      limite_online: parseInt(formData.limite_online),
      limite_maximo: parseInt(formData.limite_maximo),
    }
    
    if (isValid) {
      try {
        setLoading(true);
        
        const response = await api.tarifas.createTarifa(newTarifa);

        if (response.status === "success") {
          setTimeout(() => {
            history.goBack();

            toast.success("Tarifa creada correctamente.", {
              position: "top-right",
              autoClose: 2000,
            });
          }, 500);
        }
        else {
          throw new Error(response.message);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    }
    else {
      toast.error("Asegurate de que todos los campos sean correctos", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  }

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      asumir_comision: checked
    }));
  }

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setFormErrors(false);
    if (option === 'gratis') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        precio: 0
      }));
    }
  }

  if (loading) {
    return <Loading />
  }

  const tarifasOpciones = [
    {
      value: 1, label: "Para tickets", 
      description: "Administra el acceso a tu evento."
    },
    {
      value: 2, label: "Para consumiciones", 
      description: "Gestiona las consumiciones para tu evento."
    }
  ];

  return (
    <Container maxWidth='sm' className="d-flex justify-content-center flex-wrap">
      <div className='w-100'>
        <Box 
          component="form" 
          onSubmit={handleSubmit}
        >
          <div className="d-flex justify-content-evenly mb-3">
            <Button
              variant={selectedOption === "de pago" ? "contained" : "outlined"}
              size="large"
              onClick={() => handleOptionChange('de pago')}
            >
              De pago
            </Button>
            <Button 
              variant={selectedOption === "gratis" ? "contained" : "outlined"}
              size="large"
              onClick={() => handleOptionChange('gratis')}
            >
              Gratis
            </Button>
          </div>
          <div style={{
            display: isMobile ? 'block' : 'flex',
            gap: '10px', 
            marginTop: '16px'
          }}>
            {tarifasOpciones.map((opcion) => (
              <div key={opcion.value} className="mb-3">
                <button
                  type="button"
                  onClick={() => setFormData({ ...formData, idtipo: opcion.value })}
                  className={`w-100 boton-tipo ${formData.idtipo === opcion.value ? 'boton-tipo-active' : ''}`} 
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px',
                    background: formData.idtipo === opcion.value ? '#e3f2fd' : 'white', 
                    cursor: 'pointer',
                    textAlign: 'left',
                  }}
                >
                  <div className='d-flex flex-column'>
                    <span className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                      {opcion.label}
                    </span>
                    <span style={{ fontSize: "13px" }}>{opcion.description} </span>
                  </div>
                  <span className="indicator-wrapper mx-2">
                    <span className="indicator-fuera"></span>
                    <span className="indicator-dentro" style={{ marginLeft: "1px" }}></span>
                  </span>
                </button>
              </div>
            ))}
          </div>
          <TextField 
            name="concepto"
            label={t("rates.concept")} 
            variant="outlined" 
            value={formData.concepto}
            onChange={handleChange}
            fullWidth 
            margin="normal"
            error={formErrors.concepto}
            helperText={formErrors.concepto && 'Este campo es obligatorio'}
          /> 
          <TextField 
            label={t("rates.price")} 
            variant="outlined"
            fullWidth
            name="precio" 
            onChange={handleChange}
            value={formData.precio || ''}
            disabled={selectedOption === 'gratis'}
            margin="normal"
            error={selectedOption === 'de pago' && !!formErrors.precio}
            helperText={formErrors.precio && 'Este campo es obligatorio'}
          />
          {formData.precio > 0 && (
            <FormControlLabel 
              control={<Checkbox checked={formData.asumir_comision} />} 
              label={t("tickets.assumeTheCosts")} 
              name="asumirCostos"
              onChange={handleCheckboxChange}
            />
          )}
          <TextField 
            label={t("rates.unit")}
            variant="outlined" 
            fullWidth
            name="unidad" 
            onChange={handleChange}
            margin="normal"
            error={formErrors.unidad}
            helperText={formErrors.unidad && 'Este campo es obligatorio'}
          />
            
          <TextField 
            label="Límite de compra por pedido"
            variant="outlined" 
            fullWidth
            name="limite_persona" 
            onChange={handleChange}
            value={formData.limite_persona}
            margin="normal"
            error={!!formErrors.limite_persona}
            helperText={formErrors.limite_persona && 'Este campo es obligatorio'}
          />
          <TextField 
            label="Limite de vantas online"
            variant="outlined" 
            fullWidth
            name="limite_online" 
            onChange={handleChange}
            value={formData.limite_online}
            margin="normal"
            error={formErrors.limite_online}
            helperText={formErrors.limite_online && 'Este campo es obligatorio'}
          />
          <TextField 
            label="Limite de tarifas"
            variant="outlined" 
            fullWidth
            name="limite_maximo" 
            onChange={handleChange}
            value={formData.limite_maximo}
            margin="normal"
            error={formErrors.limite_maximo}
            helperText={formErrors.limite_maximo && 'Este campo es obligatorio'}
          />
          <Button type="submit" style={{margin: "5px"}} variant="contained">
            {t("button.create")}
          </Button>
        </Box>
        <div className="d-flex justify-content-end">
          <VolverButton/>
        </div>  
      </div>
    </Container>
  );
};

export default CrearTarifa;