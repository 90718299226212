import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Alert, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle } from 'reactstrap';
import Loading from '../../../components/Loading';
import { api } from '../../../services/api';
import VolverButton from '../../../components/VolverButton';
import { useTranslation } from 'react-i18next';


export const Venta = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });

  const [venta, setVenta] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();
  const param = useParams();
  const [t] = useTranslation("global");

  const total = venta?.carrito?.items?.reduce((acc, item) => acc + item.tarifa.precio * item.cantidad, 0);

  useEffect(() => {
    const callApi = async (id) => {
      try {
        const response = await api.ventas.get(id);

        if (response.status === "success" || response.status === "in progress") {
          setVenta(response.data.venta);
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red."
        });
      }
    }

    callApi(param.id);
  }, [state]);

  const handleAtender = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  }

  return (
    <>
      {state.error &&
        <Alert color="danger" className="container">
          {state.error}
        </Alert>
      }

      {venta ? (
        <div className="container-fluid mx-auto" style={{maxWidth: '600px'}}>
          <Card className="my-2">
            <CardHeader className="fw-bold fs-4">
              {t("sales.sale")} #{venta.id}
            </CardHeader>

            <CardBody>
              <CardTitle tag="h5" className="mb-4">
                {venta.email}
              </CardTitle>

              <CardText>
                {t("sales.saleRecived")} {new Date(venta.fecha).toLocaleString('es-AR')}
              </CardText>

              <CardText>
                Pago: {venta.atendido !== null ? (
                  new Date(venta.atendido).toLocaleString('es-AR')
                ) : (
                  <mark style={{backgroundColor: '#ffff00'}}>{t("sales.pending")}</mark>
                )}
              </CardText>

              {venta.carrito.items ?
                (venta.carrito.items.map(item => (
                  <div className="p-2 my-2 d-flex justify-content-between border rounded" key={item.id}>
                    <div>
                      <p className="fw-bold m-0">{item.tarifa.concepto}</p>
                      <small className="text-secondary">Cantidad: {item.cantidad}</small>
                    </div>
                    <div className="text-end">
                      <small>$ {item.tarifa.precio}/{item.tarifa.unidad}</small>
                      <p className="fw-bold m-0">$ {(item.cantidad * item.tarifa.precio).toFixed(2)}</p>
                    </div>
                  </div>
                ))
              ) : (
                <Loading />
              )}
              <p className="fs-5 fw-bold text-end m-0">Total: $ {total?.toFixed(2)}</p>
            </CardBody>

            <CardFooter className="d-flex justify-content-between">
              <VolverButton/>

              <button className={venta.atendido === null ? "btn btn-secondary disabled" : "btn btn-success"}
                onClick={() => history.push(`/eventos/ventas/${venta.id}/recibo`)}
                style={{margin: '5px'}}
                >
                Ver recibo
              </button>
            </CardFooter>
          </Card>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default withAuthenticationRequired(Venta, {
  onRedirecting: () => <Loading />,
});