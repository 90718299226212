import React, { useState } from "react";
import { CardBody, Container, Spinner, } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import '../publicaciones-home/ads.css';
import moment from 'moment-timezone';
import ShareButton from "../shareButton/shareButton";
import { useMediaQuery } from "react-responsive";
import LikeButton from "../Like/LikeButton";
import { Card, CardMedia, CardContent, MobileStepper, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';

moment.locale('es');
moment.locale('en-GB');

const translations = {
  Deportivos: {
    es: "Deportivos",
    en: "Sports",
  },
  Beneficios: {
    es: "Beneficios",
    en: "Profits",
  },
  Recepciones: {
    es: "Recepciones",
    en: "Social events",
  },
};

const translateCategory = (category, language) => {
  return translations[category] ? translations[category][language] : category;
};

const CardPublicacion = ({ publicacion, isLoading, usuario }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();
  const { i18n, t } = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const fechaPublicacion = new Date(publicacion?.desde);
  const fechaBajaPublicacion = new Date(publicacion?.hasta)
  const hoy = new Date();

  moment.locale(i18n.language === "es" ? "es" : "en-gb");

  const maxSteps = publicacion.attachs?.length || 0;

  const next = () => {
    const nextIndex = activeIndex === publicacion.attachs?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? publicacion.attachs?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const handleIconClick = (e) => {
    e.stopPropagation();
  };

  const recienteApertura = () => {
    const diferenciaDias = Math.floor((hoy - fechaPublicacion) / (1000 * 60 * 60 * 24));
    return diferenciaDias >= 0 && diferenciaDias <= 7;
  };
  const proximoAFinalizar = () => {
    const diferenciaDias = Math.floor((fechaBajaPublicacion - hoy) / (1000 * 60 * 60 * 24));
    return diferenciaDias >= 0 && diferenciaDias <= 4;
  };

  if (isLoading) {
    return (
      <Container
        fluid
        className={"my-4"}
        style={{ width: "350px", height: "auto" }}
      >
        <Card className="card-indiv border-0" style={{ height: "100%", boxShadow: "0px 5px 14px -6px black !important" }}>
          <CardBody
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Spinner color="primary" />
          </CardBody>
        </Card>
      </Container>
    );
  }

  return (
    <div className="mt-4">
      <Card className="card-publicacion" sx={{ boxShadow: 'none', cursor: "pointer" }} 
        onClick={() =>
          history.push(`/evento/${publicacion.idevento}/informacion`)
        }>
        {publicacion.attachs && publicacion.attachs.length > 0 && (
          <div style={{ position: 'relative' }}>
            <CardMedia
              className="media-publicacion"
              component={publicacion.attachs[activeIndex].url.includes("video") ? "video" : "img"}
              src={publicacion.attachs[activeIndex].url}
              alt={publicacion.titulo}
              controls={publicacion.attachs[activeIndex].url.includes("video")}
            />
            {maxSteps > 1 && (
              <>
                <IconButton
                  style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', color:"#5656FF"}}
                  onClick={previous}
                >
                  <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                  style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)',  color:"#5656FF" }}
                  onClick={next}
                >
                  <KeyboardArrowRight />
                </IconButton>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeIndex}
                  style={{
                    background: 'transparent',
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                  }}
                  nextButton={<div />}
                  backButton={<div />}
                />
              </>
            )}
          </div>
        )}
        <CardContent className="contenido-publicacion">
          <div className="contenedor-botones"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: recienteApertura() || proximoAFinalizar() ? "space-between" : "flex-end"
            }}
          >
            {recienteApertura() && (
              <div className="contenedor-badge" style={{background: "#dadaff", color: "#3d2eab"}}>
                <NotificationsActiveOutlinedIcon fontSize="small"/>
                <small>Reciente apertura</small>
              </div>
            )}
            {proximoAFinalizar() && (
              <div className="contenedor-badge" style={{background: "#ffc0bc", color: "#a91a11"}}>
                <NotificationsActiveOutlinedIcon fontSize="small"/>
                <small>Pronto finalizará</small>
              </div>
            )}
            <div className="d-flex align-items-center gap-2" onClick={handleIconClick}>
              <LikeButton isLoading={isLoading} usuario={usuario} publicacionId={publicacion.id} />
              <ShareButton idEvento={publicacion.idevento} />
            </div>
          </div>
          <p className="title-publicacion">
            {publicacion.titulo}
          </p>
          <div className="fechaHora-publicacion">
            <div className="fecha-publicacion d-flex align-items-center">
              <CalendarMonthOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
              <small>
                {i18n.language === 'es'
                  ? moment(publicacion.evento?.fecha).clone().local().format("D [de] MMMM")
                  : moment(publicacion.evento?.fecha).clone().local().format("MMMM[,] D")}
              </small>
            </div>
            <div className="d-flex align-items-center">
              <AccessAlarmsOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
              <small>{moment(publicacion.evento?.hora).clone().local().format('LT')}</small>
            </div>
          </div>
          <p className="descripcion">
            {publicacion.descripcion.length > 70
              ? `${publicacion.descripcion.substring(
                0,
                70
              )}...`
              : publicacion.descripcion
            }
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default CardPublicacion;
