import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from "react-i18next"
import i18next from "i18next";
import { CookiesProvider } from 'react-cookie';
import global_es from "./utils/languajes/es/global.json"
import global_en from "./utils/languajes/en/global.json"
import global_port from "./utils/languajes/port/global.json"
import { NotificacionesProvider } from "./components/context/NotificationContext";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    },
    port: {
      global: global_port
    },
  }
});

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    {...providerConfig}>
    <I18nextProvider i18n={i18next}>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <NotificacionesProvider>
          <App />
        </NotificacionesProvider>
      </CookiesProvider>
    </I18nextProvider>
  </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
