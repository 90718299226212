import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import { CircularProgress, IconButton, Box, Skeleton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import fondoHeader from "../../assets/background-header.jpg";
import "./homeHeader.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import moment from 'moment-timezone';
import Slider from "react-slick";
import textPassGO from "../../assets/textPassGO.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const HomeHeader = () => {
  const { i18n, t } = useTranslation("global");
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const intervalTime = 5000;
  const words = [
    "se crean.",
    "se gestionan.",
    "se publicitan.",
    "crecen."
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, intervalTime);

    return () => clearInterval(interval);
  }, [activeIndex, eventos.length]);

  useEffect(() => {
    const callApi = async () => {
      try {
        setLoading(true);
        const response = await api.eventos.getAllPublic();
        if (response.status === "success") {
          const eventosFiltrados = filterEventosProximos(response.data.Eventos || []);
          setEventos(eventosFiltrados);
          /*  console.log("eventos", response.data.Eventos)
           console.log("filtrados", filterEventosProximos(response.data.Eventos)); */
        }
      } catch (error) {
        console.error("Error al cargar los eventos", error);
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, []);

  const filterEventosProximos = (eventos) => {
    const hoy = new Date();
    const treintaDiasDespues = new Date();
    treintaDiasDespues.setDate(hoy.getDate() + 30);

    return eventos.filter((event) => {
      const eventDate = new Date(event.fecha);
      return eventDate >= hoy && eventDate <= treintaDiasDespues;
    });
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % eventos.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + eventos.length) % eventos.length);
  };

  if(loading) {
    return (
      <Box sx={{width: "100%", height: "500px", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Skeleton variant="rectangular" width="90%" sx={{ height: "calc(100% - 20px)", top: "20px", borderRadius: "15px" }}>
          <Typography>.</Typography>
        </Skeleton>
      </Box>
    )
  }

  moment.locale(i18n.language === "es" ? "es" : "en-gb");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    className: "header-slider"
  };

  return (
    <>
      {isMobile ? (
        <div className="header-container-mobile">
          <Box sx={{ width: "100%" }}>
            <Slider {...settings}>
              {eventos.map((evento, index) => (
                <div key={index}>
                  <div className="header-slide">
                    <div className="mobile-background-wrapper">
                      <div
                        className="mobile-background-blur"
                        style={{backgroundImage: `url(${evento.attachs.length > 0 ? evento.attachs[0].url : fondoHeader})`,
                        }}
                      />
                      <div className="text-header-mobile d-flex flex-column">
                        <p className="mobile-title">{evento.evento}</p>
                        <p className="mb-4 fw-bold font-italic fecha-header" style={{ color: "white" }}>
                          {i18n.language === "es"
                            ? moment(evento.fecha).clone().local().format("D [de] MMMM")
                            : moment(evento.fecha).clone().local().format("MMMM D")
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </Box>
        </div>
      ) : (
        <div className="header-container" style={{ margin: "0", width: "100%" }}>
          {eventos.length > 0 && eventos[activeIndex] ? (
            <div className="contenido-header">
              <div className="carousel-container">
                <Box display="flex" alignItems="center" justifyContent="space-between" position="relative" sx={{ width: "100%" }}>
                  <div className="boton-carousel" style={{ cursor: "pointer", border: "1px solid #F5F5F5", borderRadius: "0 15px 15px 0", height: "400px", backgroundColor: "#F5F5F5", paddingLeft: "5px" }} onClick={handlePrev}>
                    <IconButton onClick={handlePrev} className="carousel-control left">
                      <ArrowBackIosIcon />
                    </IconButton>
                  </div>
                  <div className="carousel-slide" style={{ textAlign: "center" }}>
                    <div className="img-header">
                      {eventos[activeIndex].attachs && eventos[activeIndex].attachs.length > 0 ? (
                        <img
                          src={eventos[activeIndex].attachs[0].url}
                          alt={eventos[activeIndex].evento}
                          style={{ width: "100%", height: "400px", objectFit: "cover" }}
                        />
                      ) : (
                        <img
                          src={fondoHeader}
                          alt="Evento sin imagen"
                          style={{ width: "100%", height: "400px", objectFit: "cover" }}
                        />
                      )}
                    </div>
                    <div className="text-header">
                      <p className="title-header">{eventos[activeIndex].evento}</p>
                      <p className="mb-0 text-secondary fw-bold font-italic fecha-header">
                        {i18n.language === 'es' ? (
                          moment(eventos[activeIndex].fecha).clone().local().format("D [de] MMMM YYYY")
                        ) : (
                          moment(eventos[activeIndex].fecha).clone().local().format("MMMM D[,] YYYY")
                        )}
                      </p>
                      <p>
                      </p>
                    </div>
                  </div>
                  <div className="boton-carousel" style={{ cursor: "pointer", border: "1px solid #F5F5F5", borderRadius: "15px 0 0 15px", height: "400px", backgroundColor: "#F5F5F5", paddingLeft: "2px", paddingRight: "5px" }} onClick={handleNext}>
                    <IconButton onClick={handleNext} className="carousel-control right">
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </div>
                </Box>
              </div>
            </div>
          ):(
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
              <img src={`${textPassGO}`} alt="PassGO" />
              <h3 className="mt-4 hero-title">DONDE TUS EVENTOS</h3>
              <h3 className="hero-h3"><span> {words[index]}</span></h3>
            </Box>
          )}
        </div>
      )}
    </>
  );
}