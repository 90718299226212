import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { api } from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

const DeleteCategoryModal = ({ id, callApi }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setError("");
  }
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [t] = useTranslation("global");

  const DeleteCategory = (id) => {
    const response = api.categorias.deleteCategoria(id);
    return response
    .then(response => {
      if (response.status === "error"){
        if (response.message.includes("Error 1451")){
          setError(<>{t("categorie.deleteError")}</>)
        }else{
          setError(response.message)
        }
        console.log(error);
    }
    if (response.status === "success") {
      setSubmitted(true)
      console.log("eliminado")
      setTimeout(() => {
        setModal(!modal);
        callApi();
      }, 1000);
    }

  })    
  }

  return (
    <div>
      <Button outline color="danger" onClick={toggle}>
      <FontAwesomeIcon icon={faTrash}/>
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{ zIndex: 9999, paddingTop:"80px"}}>
        <ModalHeader toggle={toggle}>{t("categorie.deleteCategorie")}</ModalHeader>
        <ModalBody>{t("categorie.question")}</ModalBody>
        {error && <Alert color="danger">{error}</Alert>}
              {submitted && (
                <Alert color="success">
                 {t("categorie.successAlert")}
                </Alert>
              )}
        <ModalFooter>
          <Button color="danger" onClick={() => DeleteCategory(id)}>
          {t("button.delete")}
          </Button>
          <Button color="secondary" onClick={toggle}>
          {t("button.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default DeleteCategoryModal;