import React, { useState, useEffect, useRef } from "react";
import { Alert, FormControl, TextField, Button, CircularProgress } from "@mui/material";
import { api } from "../../../services/api";
import moment from "moment-timezone";
import "./evento.css";
import { useTranslation } from "react-i18next";
import useUbicaciones from "../../../hooks/hookUbicacion";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";


import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import MicExternalOnOutlinedIcon from "@mui/icons-material/MicExternalOnOutlined";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import SportsBasketballOutlinedIcon from "@mui/icons-material/SportsBasketballOutlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const categoriaIcons = {
    1: <NightlifeOutlinedIcon sx={{ marginRight: "10px" }} />,
    2: <VolunteerActivismOutlinedIcon sx={{ marginRight: "10px" }} />,
    3: <SportsBasketballOutlinedIcon sx={{ marginRight: "10px" }} />,
    4: <FastfoodOutlinedIcon sx={{ marginRight: "10px" }} />,
    5: <BusinessCenterOutlinedIcon sx={{ marginRight: "10px" }} />,
    6: <MicExternalOnOutlinedIcon sx={{ marginRight: "10px" }} />,
};

const CrearEvento = ({ setEventId, setEventoCreado }) => {
    const refSeccion = useRef({});
    const [tipos, setTipos] = useState("");
    const [expandedSection, setExpandedSection] = useState(false);
    const [evento, setEvento] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [fecha, setFecha] = useState("");
    const [hora, setHora] = useState("");
    const [eventos_tipos_id, setEventotipo] = useState("");
    const [publicoprivado, setPublicoPrivado] = useState("");
    const [direccion, setDireccion] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [mapUrl, setMapUrl] = useState("");
    const [error, setError] = useState("");
    const localTime = moment.tz.guess();
    const [submitted, setSubmitted] = useState(false);
    const [t] = useTranslation("global");
    const [mediaUrls, setMediaUrls] = useState([]);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [mediaFormats, setMediaFormats] = useState([]);
    const [mediaId, setMediaId] = useState([]);
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        provincias,
        ciudades,
        provSeleccionada,
        setProvSeleccionada,
        ciudadSeleccionada,
        setCiudadSeleccionada,
    } = useUbicaciones("AR"); // pais predefinido

    const handleProvChange = (event) => {
        const selectedProvIso2 = event.target.value;
        setProvSeleccionada(selectedProvIso2);
    };

    const handleCityChange = (event) => {
        const selectedCityId = event.target.value;
        setCiudadSeleccionada(selectedCityId);
    };

    useEffect(() => {
        const getEventosTipos = async () => {
            try {
                const response = await api.eventos.getAllEventosTipos();
                if (response.status === "success") {
                    setTipos(response.data.eventoTipos);
                }
            } catch (error) {
                console.error("Error: " + error);
            }
        };
        getEventosTipos();
    }, []);

    const handleSectionClick = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const handleInputClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (mediaUrls.length > 0) {
            setSelectedFileIndex(0);
        } else {
            setSelectedFileIndex(null);
        }
    }, [mediaUrls]);

    const handleAddFileClick = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*,video/*";
        fileInput.multiple = true;
        fileInput.onchange = handleFileChange;
        fileInput.click();
    };

    const handleFileChange = async (e) => {
        const maxSizeFile = 10 * 1024 * 1024;
        const maxFiles = 5;
        const selectedFiles = Array.from(e.target.files);

        if (files.length + selectedFiles.length > maxFiles) {
            alert(`Solo puedes subir como máximo ${maxFiles} archivos.`)
            return;
        }

        const validaFiles = [];
        selectedFiles.forEach((file) => {
            if (file.size <= maxSizeFile) {
                validaFiles.push(file);
            } else {
                console.error(`El archivo ${file.name} supera el límite de tamaño de 10MB`);
                alert(`El archivo ${file.name} supera el límite de tamaño de 10MB`);
            }
        });

        if (validaFiles.length === 0) return;

        setLoading(true);

        setFiles((prevFiles) => {
            const combinedFiles = [...prevFiles, ...validaFiles];

            if (combinedFiles.length > maxFiles) {
                alert(`Solo puedes subir como máximo ${maxFiles} archivos.`)
                return prevFiles;
            }

            if (selectedFileIndex === null && validaFiles.length > 0) {
                setSelectedFileIndex(prevFiles.length);
            }

            return combinedFiles;
        });

        const formData = new FormData();
        validaFiles.forEach((file) => {
            formData.append("files", file);
        });

        try {
            const response = await api.media.upload(formData);
            if (response.status === "success") {
                const medias = response.data.media;
                setMediaUrls(medias.map((media) => media.url));
                setMediaTypes(medias.map((media) => media.type));
                setMediaFormats(medias.map((media) => media.format));
                setMediaId(medias.map((media) => media.public_id));
            }
        } catch (error) {
            console.error("Error uploading files:", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteMedia = async (index) => {
        const publicId = mediaId[index];

        try {
            const response = await api.media.delete({ public_id: publicId });
            if (response.status === "success") {
                setMediaUrls(mediaUrls.filter((_, i) => i !== index));
                setMediaTypes(mediaTypes.filter((_, i) => i !== index));
                setMediaFormats(mediaFormats.filter((_, i) => i !== index));
                setMediaId(mediaId.filter((_, i) => i !== index));

                setFiles(files.filter((_, i) => i !== index));

                if (mediaUrls.length > 1) {
                    setSelectedFileIndex(0);
                } else {
                    setSelectedFileIndex(null);
                }
            }
        } catch (error) {
            console.error("Error deleting media:", error);
        }
    };

    const handleThumbnailClick = (index) => {
        setSelectedFileIndex(index);
    };

    const renderFilePreview = (file, index) => {
        const isImage = file.type.startsWith("image/");
        const isVideo = file.type.startsWith("video/");
        const fileURL = URL.createObjectURL(file);

        const thumbnailClass =
            selectedFileIndex === index ? "thumbnail selected" : "thumbnail";

        return (
            <div
                key={index}
                className={thumbnailClass}
                onClick={() => handleThumbnailClick(index)}
            >
                {isImage && <img src={fileURL} alt={`Preview ${index}`} />}
                {isVideo && <video src={fileURL} />}
            </div>
        );
    };

    const renderMainPreview = () => {
        if (selectedFileIndex === null || files.length === 0) return null;

        const selectedFile = files[selectedFileIndex];
        const isImage = selectedFile.type.startsWith("image/");
        const isVideo = selectedFile.type.startsWith("video/");
        const fileURL = URL.createObjectURL(selectedFile);

        const handleRemoveFile = () => {
            setFiles((prevFiles) =>
                prevFiles.filter((_, index) => index !== selectedFileIndex)
            );
            setSelectedFileIndex(null); // Resetea la seleccion
        };

        return (
            <div className="main-preview">
                {isImage && <img src={fileURL} alt="Imagen seleccionada" />}
                {isVideo && <video src={fileURL} controls />}

                <button
                    type="button"
                    className="remove-button"
                    onClick={() => {
                        deleteMedia(selectedFileIndex);
                        handleRemoveFile();
                    }}
                >
                    Eliminar
                </button>
            </div>
        );
    };
    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"],
        ],
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const regexE = /^(?!([a-zA-Z])\1+$)[a-zA-Z\sñÑ\u00C0-\u017F]*$/;
        const hoy = new Date();
        const fechasel = new Date(fecha);
        const fechamax = new Date().setFullYear(hoy.getFullYear() + 1);

        const newErrors = [];

        if (files.length === 0) newErrors.files = "Por favor, sube un archivo.";

        if (evento.trim() === "")
            newErrors.evento = "El nombre del evento no puede estar vacío.";
        if (!regexE.test(evento))
            newErrors.evento =
                "Evita el uso de caracteres especiales en el nombre del evento";
        if (evento.length > 50)
            newErrors.evento = "El nombre del evento no puede exceder 50 caracteres.";
        if (evento.length < 5)
            newErrors.evento =
                "El nombre del evento debe tener al menos 5 caracteres.";

        if (descripcion.trim() === "")
            newErrors.descripcion = "La descripción es obligatoria.";
        if (descripcion.length > 480)
            newErrors.descripcion = "La descripción no puede superar 250 caracteres.";
        if (descripcion.length < 5)
            newErrors.descripcion = "La descripción necesita al menos 5 caracteres.";

        if (fechasel > fechamax) newErrors.fecha = t("errors.errorDate");
        if (fecha === "" || hora === "")
            newErrors.hora = "La fecha y hora son necesarias.";
        if (ciudadSeleccionada === "" || direccion === "") newErrors.direccion = "La ciudad y la direccion no pueden estar vacías."

        if (eventos_tipos_id === "")
            newErrors.eventos_tipos_id = "Selecciona un tipo de evento.";
        if (publicoprivado === "")
            newErrors.publicoprivado =
                "Es necesario seleccionar si el evento es público o privado.";

        // detiene el envio si hay errores
        if (Object.keys(newErrors).length > 0) {
            setError(newErrors);
            return;
        }

        const pp = publicoprivado === "true" ? true : false;
        //se crea una const con la fecha y hora en horario local segun zona horaria configurada.
        const fechaEnHorarioLocal = moment
            .tz(`${fecha} ${hora}`, localTime)
            .format("YYYY-MM-DDTHH:mm:ss");
        const horaEnHorarioLocal = moment
            .tz(`${fecha} ${hora}`, localTime)
            .format("YYYY-MM-DDTHH:mm:ss");
        const data = {
            evento: {
                evento: evento,
                descripcion: descripcion,
                //se convierten fecha y hora para guardarlas siempre en zona horaria -3
                fecha: moment(fechaEnHorarioLocal)
                    .clone()
                    .tz("America/Buenos_Aires")
                    .format("YYYY-MM-DDTHH:mm:ss-03:00"),
                hora: moment(horaEnHorarioLocal)
                    .clone()
                    .tz("America/Buenos_Aires")
                    .format("YYYY-MM-DDTHH:mm:ss-03:00"),
                eventos_tipos_id: eventos_tipos_id,
                privado: pp,
                direccion: direccion,
                ciudad: ciudadSeleccionada,
            },
            attachs: mediaUrls.map((url, index) => ({
                url: url,
                type: mediaTypes[index],
                format: mediaFormats[index],
                public_id: mediaId[index],
            })),
        };
        console.log(data);

        const response = api.eventos.createEvento(data);
        return response.then((response) => {
            if (response.status === "error") {
                setError("Error: " + response.message);
                setEventoCreado(false);
            }
            if (response.status === "success") {
                const newEventId = response.data.evento.id;
                setEventId(newEventId); // Almacena el id en el estado
                localStorage.setItem("eventId", newEventId); // Almacena el id en localStorage
                setEvento("");
                setDescripcion("");
                setEventotipo("");
                setFecha("");
                setHora("");
                setDireccion("");
                setCiudad("");
                setPublicoPrivado("");
                setMediaUrls("");
                setMediaTypes("");
                setMediaFormats("");
                setMediaId("");
                setError("");
                setSubmitted(true);
                setEventoCreado(true);
            }
        });
    };

    const mapdireccion = direccion + " " + ciudadSeleccionada + " " + provSeleccionada;
    const apiKey = "AIzaSyCzPvPffhA4gns9-sfxzSlXXC4ytQtbKxc";

    useEffect(() => {
        if (mapdireccion) {
            const direccionEncoded = encodeURIComponent(mapdireccion);
            const newMapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${direccionEncoded}`;

            setMapUrl(newMapUrl);
        }
    }, [mapdireccion, apiKey]);

    const [tipoSelected, setTipoSelected] = useState(eventos_tipos_id);

    const handleTipoSelect = (id) => {
        setTipoSelected(id);
        setEventotipo(id);
    };

    return (
        <div className="contenedor-evento">
            <form id="form-evento" onSubmit={handleSubmit}>
                {/* SECCION IMAGENES */}
                <div
                    ref={(el) => (refSeccion.current["img"] = el)}
                    className={`seccion-evento ${expandedSection === "img" ? "expanded" : ""
                        }`}
                    onClick={() => handleSectionClick("img")}
                >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <p
                            className={`titulo-seccion-evento ${expandedSection === "img" ? "hidden" : ""
                                }`}
                        >
                            {t("event.image")}
                        </p>
                        {error.files && (
                            <ErrorOutlineOutlinedIcon
                                sx={{
                                    color: "red",
                                    display: expandedSection === "img" ? "none" : "block",
                                }}
                            />
                        )}
                    </div>

                    <p
                        className={`descrip-seccion-evento ${expandedSection === "img" ? "hidden" : ""
                            }`}
                    >
                        {t("event.imageDescrip")}
                    </p>

                    {expandedSection === "img" && (
                        <div className="contenido-evento">
                            <FormControl onClick={handleInputClick}>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <p className="titulo-seccion-evento-expanded">
                                        {t("event.addImage")} <span className="text-danger">*</span>
                                    </p>
                                    <button
                                        type="button"
                                        onClick={() => handleSectionClick("img")}
                                        className="d-flex justify-content-end"
                                    >
                                        <CloseOutlinedIcon sx={{ color: "#3659E3" }} />
                                    </button>
                                </div>

                                <p className="descrip-seccion-evento-expanded">
                                    Sube hasta 5 imágenes y videos que muestren los detalles y momentos destacados para que los asistentes tengan una visión del evento.
                                    <br />
                                    Asegúrate de elegir archivos de alta calidad para un mejor impacto visual.
                                </p>
                                <div className="info-input-img">
                                    <ul className="ul-info-img">
                                        <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                                            Tamaño recomendado de imagen: 19200x1280
                                        </li>
                                        <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                                            Tamaño máximo de archivo: 10MB
                                        </li>
                                        <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                                            Formatos admitidos de imagen: JPEG, PNG
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    className={`input-file ${files.length === 0 ? "" : "border-0"
                                        }`}
                                >
                                    <input
                                        id="files"
                                        name="files"
                                        type="file"
                                        accept="image/*,video/*"
                                        multiple
                                        onChange={handleFileChange}
                                        onClick={handleInputClick}
                                        style={{ display: "none" }}
                                        required
                                    />

                                    {loading ? (
                                        <div className="d-flex flex-column align-items-center">
                                            <p>Cargando archivos...</p>
                                            <CircularProgress/>
                                        </div>
                                    ) : files.length === 0 ? (
                                        <label className="placeholder-input-img" htmlFor="files">
                                            {t("event.inputImage")}
                                        </label>
                                    ) : (
                                        <>
                                            <div className="imagen-contenedor">
                                                {renderMainPreview()}
                                                <div className="thumbnails-contenedor">
                                                    <div
                                                        className="boton-agregar-img"
                                                        onClick={handleAddFileClick}
                                                    >
                                                        <AddOutlinedIcon sx={{ color: "#3659E3" }} />
                                                    </div>
                                                    {files.map(renderFilePreview)}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <>
                                    {error.files && (
                                        <Alert
                                            severity="error"
                                            sx={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            {error.files}
                                        </Alert>
                                    )}
                                </>
                            </FormControl>
                        </div>
                    )}
                </div>
                {/* SECCION TITULO */}
                <div
                    ref={(el) => (refSeccion.current["gral"] = el)}
                    className={`seccion-evento ${expandedSection === "gral" ? "expanded" : ""
                        }`}
                    onClick={() => handleSectionClick("gral")}
                >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <p
                            className={`titulo-seccion-evento fs-1 ${expandedSection === "gral" ? "hidden" : ""
                                }`}
                        >
                            {t("event.eventName")}
                        </p>
                        {(error.evento || error.descripcion) && (
                            <ErrorOutlineOutlinedIcon
                                sx={{
                                    color: "red",
                                    display: expandedSection === "gral" ? "none" : "block",
                                }}
                            />
                        )}
                    </div>
                    <p
                        className={`descrip-seccion-evento ${expandedSection === "gral" ? "hidden" : ""
                            }`}
                    >
                        {t("event.eventNameDescrip")}
                    </p>

                    {expandedSection === "gral" && (
                        <div className="contenido-evento">
                            <FormControl onClick={handleInputClick}>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <p className="titulo-seccion-evento-expanded">
                                        {t("event.eventDescrip")}
                                    </p>
                                    <button
                                        type="button"
                                        onClick={() => handleSectionClick("gral")}
                                        className="d-flex justify-content-end"
                                    >
                                        <CloseOutlinedIcon sx={{ color: "#3659E3" }} />
                                    </button>
                                </div>

                                <p className="subtitulo-seccion-evento-expanded">
                                    {t("event.eventName")} <span className="text-danger">*</span>
                                </p>
                                <p className="descrip-seccion-evento-expanded mb-0">
                                    {t("event.eventDescrip2")}
                                </p>
                                <TextField
                                    sx={{
                                        marginTop: "5px",
                                        marginBottom: "15px",
                                        input: {
                                            padding: "10px 15px",
                                        },
                                    }}
                                    type="text"
                                    name="evento"
                                    value={evento}
                                    onClick={handleInputClick}
                                    placeholder={t("event.eventName")}
                                    onChange={(e) => setEvento(e.target.value)}
                                    required
                                />
                                <>
                                    {error.evento && (
                                        <Alert
                                            severity="error"
                                            sx={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            {error.evento}
                                        </Alert>
                                    )}
                                </>
                            </FormControl>

                            <FormControl onClick={handleInputClick}>
                                <p className="subtitulo-seccion-evento-expanded">
                                    {t("event.description")}{" "}
                                    <span className="text-danger">*</span>
                                </p>
                                <p className="descrip-seccion-evento-expanded">
                                    {t("event.descriptionXl")}
                                </p>
                                <ReactQuill
                                    theme="snow"
                                    minLength={5}
                                    maxLength={480}
                                    value={descripcion}
                                    onChange={setDescripcion}
                                    onClick={handleInputClick}
                                    style={{ height: "auto" }}
                                    modules={modules}
                                    formats={["header", "bold", "italic", "underline", "list"]}
                                />
                                <>
                                    {error.descripcion && (
                                        <Alert
                                            severity="error"
                                            sx={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            {error.descripcion}
                                        </Alert>
                                    )}
                                </>
                            </FormControl>
                        </div>
                    )}
                </div>
                {/* SECCION FECHA Y LUGAR */}
                <div
                    ref={(el) => (refSeccion.current["date"] = el)}
                    className={`seccion-evento ${expandedSection === "date" ? "expanded" : ""
                        }`}
                    onClick={() => handleSectionClick("date")}
                >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <p
                            className={`titulo-seccion-evento ${expandedSection === "date" ? "hidden" : ""
                                }`}
                        >
                            {t("event.dateandplace")}
                        </p>
                        {(error.fecha || error.hora || error.direccion) && (
                            <ErrorOutlineOutlinedIcon
                                sx={{
                                    color: "red",
                                    display: expandedSection === "date" ? "none" : "block",
                                }}
                            />
                        )}
                    </div>
                    <p
                        className={`descrip-seccion-evento ${expandedSection === "date" ? "hidden" : ""
                            }`}
                    >
                        {t("event.dateDescrip")}
                    </p>

                    {expandedSection === "date" && (
                        <div className="contenido-evento" onClick={handleInputClick}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <p className="subtitulo-seccion-evento-expanded d-flex justify-content-start">
                                    {t("event.dateandtime")}{" "}
                                    <span className="text-danger">*</span>
                                </p>
                                <button
                                    type="button"
                                    onClick={() => handleSectionClick("date")}
                                    className="d-flex justify-content-end"
                                >
                                    <CloseOutlinedIcon sx={{ color: "#3659E3" }} />
                                </button>
                            </div>
                            <p className=" descrip-seccion-evento-expanded mb-0">
                                Ingresa que dia y a que hora se realizara el evento
                            </p>
                            <div className="date-hora">
                                <FormControl onClick={handleInputClick}>
                                    <TextField
                                        sx={{
                                            marginTop: "5px",
                                            marginBottom: "15px",
                                            input: {
                                                padding: "10px 15px",
                                            },
                                        }}
                                        type="date"
                                        name="date"
                                        value={fecha}
                                        onClick={handleInputClick}
                                        onChange={(e) => setFecha(e.target.value)}
                                        required
                                        style={{ width: "300px" }}
                                    />
                                </FormControl>

                                <FormControl onClick={handleInputClick}>
                                    <TextField
                                        sx={{
                                            marginTop: "5px",
                                            marginBottom: "15px",
                                            input: {
                                                padding: "10px 15px",
                                            },
                                        }}
                                        type="time"
                                        name="hora"
                                        onClick={handleInputClick}
                                        value={hora}
                                        onChange={(e) => setHora(e.target.value)}
                                        required
                                    />
                                </FormControl>
                            </div>
                            <>
                                {error.hora && (
                                    <Alert
                                        severity="error"
                                        sx={{
                                            marginTop: "5px",
                                            marginBottom: "25px",
                                        }}
                                    >
                                        {error.hora}
                                    </Alert>
                                )}
                            </>

                            <FormControl>
                                <p className="subtitulo-seccion-evento-expanded mt-2 ">
                                    {t("event.location")} <span className="text-danger">*</span>
                                </p>
                                <p className="descrip-seccion-evento-expanded">
                                    {t("event.locationDescrip")}
                                </p>
                                <div className="d-flex flex-wrap gap-1 align-items-center">
                                    <FormControl
                                        onClick={handleInputClick}
                                        className=""
                                        style={{}}
                                    >
                                        <div>
                                            <select
                                                value={provSeleccionada}
                                                onChange={handleProvChange}
                                                required
                                                style={{}}
                                                className="input-select-evento"
                                            >
                                                <option value="">Seleccione una provincia</option>
                                                {provincias.map((provincia) => (
                                                    <option key={provincia.iso2} value={provincia.iso2}>
                                                        {provincia.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </FormControl>
                                    <FormControl onClick={handleInputClick} className="">
                                        <div>
                                            <select
                                                value={ciudadSeleccionada}
                                                onChange={handleCityChange}
                                                required
                                                className="input-select-evento"
                                                disabled={!provSeleccionada}
                                            >
                                                <option value="" disabled>Seleccione una ciudad</option>
                                                {ciudades.map((ciudad) => (
                                                    <option key={ciudad.id} value={ciudad.name}>
                                                        {ciudad.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </FormControl>
                                    <FormControl
                                        onClick={handleInputClick}
                                        className=""
                                        style={{ width: "60%" }}
                                    >
                                        <TextField
                                            sx={{
                                                marginTop: "5px",
                                                marginBottom: "15px",
                                                input: {
                                                    padding: "10px 15px",
                                                },
                                            }}
                                            name="direccion"
                                            placeholder={t("event.address")}
                                            onClick={handleInputClick}
                                            value={direccion}
                                            onChange={(e) => setDireccion(e.target.value)}
                                            required
                                        />
                                    </FormControl>
                                </div>
                                <>
                                    {error.direccion && (
                                        <Alert severity="error">{error.direccion}</Alert>
                                    )}
                                </>
                                <div className="d-flex justify-content-center mt-3">
                                    <iframe
                                        title={`Mapa de ${direccion}, ${ciudadSeleccionada}`}
                                        className="rounded shadow"
                                        width="100%"
                                        height="230"
                                        id="map"
                                        loading="lazy"
                                        allowFullScreen
                                        referrerPolicy="no-referrer-when-downgrade"
                                        src={mapUrl}
                                    ></iframe>
                                </div>
                            </FormControl>
                        </div>
                    )}
                </div>
                {/* SECCION MAS DETALLES */}
                <div
                    ref={(el) => (refSeccion.current["detail"] = el)}
                    className={`seccion-evento ${expandedSection === "detail" ? "expanded" : ""
                        }`}
                    onClick={() => handleSectionClick("detail")}
                >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <p
                            className={`titulo-seccion-evento ${expandedSection === "detail" ? "hidden" : ""
                                }`}
                        >
                            {t("event.details")}
                        </p>
                        {(error.eventos_tipos_id || error.publicoprivado) && (
                            <ErrorOutlineOutlinedIcon
                                sx={{
                                    color: "red",
                                    display: expandedSection === "detail" ? "none" : "block",
                                }}
                            />
                        )}
                    </div>
                    <p
                        className={`descrip-seccion-evento ${expandedSection === "detail" ? "hidden" : ""
                            }`}
                    >
                        {t("event.detailsDescrip")}
                    </p>

                    {expandedSection === "detail" && (
                        <div className="contenido-evento">
                            <FormControl onClick={handleInputClick}>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <p className="titulo-seccion-evento-expanded">
                                        Detalles adicionales
                                    </p>
                                    <button
                                        type="button"
                                        onClick={() => handleSectionClick("detail")}
                                        className="d-flex justify-content-end"
                                    >
                                        <CloseOutlinedIcon sx={{ color: "#3659E3" }} />
                                    </button>
                                </div>

                                <p className="subtitulo-seccion-evento-expanded">
                                    Tipo de evento <span className="text-danger">*</span>
                                </p>
                                <p className="descrip-seccion-evento-expanded">
                                    Define la naturaleza del evento. Selecciona entre estas
                                    opciones la que mejor se asemeje a tu evento.
                                </p>

                                {tipos?.length > 0 ? (
                                    <div className="row">
                                        {tipos.map((tipo) => (
                                            <div key={tipo.id} className="col-12 col-md-6 mb-3">
                                                <button
                                                    id="eventos_tipos_id"
                                                    name="eventos_tipos_id"
                                                    type="button"
                                                    onClick={() => handleTipoSelect(tipo.id)}
                                                    onChange={(e) =>
                                                        setEventotipo(parseInt(e.target.value))
                                                    }
                                                    className={`w-100 boton-tipo ${tipoSelected === tipo.id ? "boton-tipo-active" : ""
                                                        }`}
                                                >
                                                    <span className="d-flex align-items-center">
                                                        {categoriaIcons[tipo.id]}
                                                        {tipo.evento_tipo}
                                                    </span>
                                                    <span className="indicator-wrapper">
                                                        <span className="indicator-fuera"></span>
                                                        <span className="indicator-dentro"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        ))}
                                        {error.eventos_tipos_id && (
                                            <div className="col-12">
                                                <Alert severity="error" className="mb-4">
                                                    {error.eventos_tipos_id}
                                                </Alert>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <span color="error">Error..</span>
                                )}
                            </FormControl>

                            <FormControl onClick={handleInputClick}>
                                <p className="subtitulo-seccion-evento-expanded">
                                    Visibilidad del Evento <span className="text-danger">*</span>
                                </p>
                                <p className="descrip-seccion-evento-expanded">
                                    Establece la accesibilidad de tu evento. Ten cuidado, esta
                                    opción no podrá modificarse después.
                                </p>
                                <div className="d-flex flex-wrap flex-row gap-3">
                                    <button
                                        type="button"
                                        onClick={() => setPublicoPrivado("false")}
                                        className={`mr-2 mb-2 ${publicoprivado === "false"
                                            ? "boton-tipo-active"
                                            : "boton-tipo"
                                            }`}
                                    >
                                        <div className="d-flex flex-column">
                                            <span className="text-left">Evento publico</span>
                                            <span className="descripcion-boton">
                                                Todos podrán verlo.
                                            </span>
                                        </div>
                                        <span className="indicator-wrapper">
                                            <span className="indicator-fuera"></span>
                                            <span className="indicator-dentro"></span>
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setPublicoPrivado("true")}
                                        className={`mr-2 mb-2 ${publicoprivado === "true"
                                            ? "boton-tipo-active"
                                            : "boton-tipo"
                                            }`}
                                    >
                                        <div className="d-flex flex-column">
                                            <span className="text-left">Evento privado</span>
                                            <span className="descripcion-boton text-left">
                                                Restringido a miembros de la organización.
                                            </span>
                                        </div>
                                        <span className="indicator-wrapper">
                                            <span className="indicator-fuera"></span>
                                            <span className="indicator-dentro"></span>
                                        </span>
                                    </button>
                                </div>
                                <>
                                    {error.publicoprivado && (
                                        <Alert severity="error" className="my-2">{error.publicoprivado}</Alert>
                                    )}
                                </>
                            </FormControl>
                        </div>
                    )}
                </div>
                {error && (
                    <Alert severity="error">
                        Por favor, chequea la información ingresada.
                    </Alert>
                )}
                {submitted && <Alert color="success">{t("event.successCreate")}</Alert>}
                <Button type="submit" form="form-evento" className="mt-3 boton-submit">
                    {t("button.create")} evento
                </Button>{" "}
            </form>
        </div>
    );
};

export default CrearEvento;
