import React from 'react';
import { useHistory } from 'react-router-dom';

const Unauthorized = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Acceso Denegado</h1>
      <p>No tenes permiso para ver esto...</p>
      <button onClick={handleGoBack} style={{ backgroundColor:"red", color:"white",  padding: '10px 20px', cursor: 'pointer' }}>
        Volver atras..
      </button>
    </div>
  );
};

export default Unauthorized;
