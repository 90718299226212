import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  faUser,
  faCalendar,
  faPowerOff,
  faBell,
  faCartShopping,
  faRightToBracket,
  faArrowRight,
  faHouse,
  faCalendarDays
} from "@fortawesome/free-solid-svg-icons";
import useNotificaciones from "../hooks/hookNotifications";
import useCarrito from "../hooks/hookCarrito";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Dropdown,
} from "reactstrap";

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { es } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "hamburgers/dist/hamburgers.css";
import "./navbar.css";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import PassGoOutlined from "../../src/assets/PassGoOutlined.png";
import useCarritoStore from "../providers/Carrito";
import { useNotificacionesContext } from "./context/NotificationContext";

const NavBar = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [isOpen, setIsOpen] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { notificaciones } = useNotificacionesContext();
  const { carrito } = useCarritoStore();    //zustand del carrito
  const [isScrolled, setIsScrolled] = useState(false);

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const routeName = {
    "/eventos": "Mis Eventos",
    "/notificaciones": "Notificaciones",
    "/carrito": "Carrito",
    "/profile": "Perfil",
  }[location.pathname];

  const vistaCreaEventos = location.pathname === '/creaeventos';

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (vistaCreaEventos) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [vistaCreaEventos]);

  const navItems = [
    { path: "/eventos", name: "Mis Eventos", icon: <CalendarMonthOutlinedIcon /> },
    { path: "/notificaciones", name: "Notificaciones", icon: <NotificationsNoneOutlinedIcon /> },
    { path: "/carrito", name: "Carrito", icon: <ShoppingCartOutlinedIcon /> },
    { path: "/profile", name: "Perfil", icon: <AccountCircleOutlinedIcon /> },
  ].filter((item) => item.name !== routeName);

  const toggleHamburger = () => {
    setIsActive(!isActive);
    toggle();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    if (profileMenuOpen) {
      setProfileMenuOpen(false);
    }
  };

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const logoutWithRedirect = () => {
    sessionStorage.removeItem("token");
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const setToken = async () => {
    const token = await getAccessTokenSilently();
    sessionStorage.setItem("token", token);
  };

  (async () => {
    if (isAuthenticated) {
      const existingToken = sessionStorage.getItem("token");

      if (!existingToken) {
        await setToken();
      }
    }
  })()

  /* if (isAuthenticated) {
    setToken();
  } */

  const closeMenus = () => {
    setIsOpen(false);
    setIsActive(false);
    setProfileMenuOpen(false);
    setNotificationsOpen(false);
  };

  const [carritoOpen, setCarritoOpen] = useState(false);
  const toggleCarrito = () => {
    setCarritoOpen((prevState) => !prevState);
  }

  return (
    <>
      {isMobile ? (
        <Navbar className="passgo-navbar" style={{ top: "0", width: "100%" }}>
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div>
              <button
                aria-label="Menu"
                aria-controls="navigation"
                className={`hamburger hamburger--squeeze ${isActive ? "is-active" : ""
                  }`}
                type="button"
                onClick={toggleHamburger}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              {routeName && <span style={{ color: "white" }} className="fs-4 fw-semibold">{routeName}</span>}
            </div>
            <NavbarBrand tag={RouterNavLink} to="/" exact style={{ marginRight: "0", marginLeft: "0" }}>
              <img src={`${PassGoOutlined}`} width="70%" alt="PassGoLogo" />
            </NavbarBrand>
          </div>
          <Collapse isOpen={isActive} navbar>
            <Nav navbar>
              {isAuthenticated ? (
                <>
                  {navItems.map((item) => (
                    <div
                      className="d-flex align-items-center mb-2"
                      key={item.name}
                    >
                      <span className="mr-3"> {item.icon}  </span>
                      {carrito?.items?.length > 0 && item.name === "Carrito" && (
                        <span className="position-absolute translate-middle p-1 bg-danger border border-light rounded-circle" />
                      )}
                      {notificaciones.filter(notificacion => (notificacion.atendido == null)) && item.name === "Notificaciones" && (
                        <span className="position-absolute translate-middle p-1 bg-danger border border-light rounded-circle" />
                      )}
                      <NavItem className="m-0">
                        <RouterNavLink
                          to={item.path}
                          className="text-decoration-none"
                          onClick={closeMenus}
                        >
                          {item.name}
                        </RouterNavLink>
                      </NavItem>
                    </div>
                  ))}
                  <NavItem>
                    <span className="mr-3"><PowerSettingsNewOutlinedIcon /></span>
                    <RouterNavLink
                      to="#"
                      id="qsLogoutBtn"
                      onClick={() => {
                        logoutWithRedirect();
                        closeMenus();
                      }}
                      className="text-decoration-none"
                    >
                      {t("navbar.logout")}
                    </RouterNavLink>
                  </NavItem>
                </>
              ) : (
                <div>
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faHouse} className="mr-3" />
                    <NavItem className="m-0">
                      <RouterNavLink
                        to="/"
                        className="text-decoration-none"
                        onClick={closeMenus}
                      >
                        Inicio
                      </RouterNavLink>
                    </NavItem>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faCalendarDays} className="mr-3" />
                    <NavItem className="m-0">
                      <RouterNavLink
                        to="/creaeventos"
                        className="text-decoration-none"
                        onClick={closeMenus}
                      >
                        {t("navbar.createEvents")}
                      </RouterNavLink>
                    </NavItem>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <FontAwesomeIcon icon={faRightToBracket} className="mr-3" />
                    <NavItem className="m-0">
                      <RouterNavLink
                        id="qsLoginBtn"
                        to="#"
                        onClick={() => {
                          loginWithRedirect();
                          closeMenus();
                        }}
                        className="text-decoration-none"
                      >
                        {t("navbar.login")}
                      </RouterNavLink>
                    </NavItem>
                  </div>
                </div>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      ) : (

        <Navbar className={`passgo-navbar 
          ${vistaCreaEventos // es crea eventos?
            ? isScrolled  // si es, el usuario scrolleo?
              ? 'navbar-scrolled'  //si scrolleo, aplico esta clase
              : 'navbar-special'  //no scrolleo, aplico esta
            : ''  //no es crea eventos, solo aplico passgo-navbar
          }`} expand="md" container={false}>
          <Container className="p-0 d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <NavbarBrand tag={RouterNavLink} to="/" exact>
                <img src={`${PassGoOutlined}`} width="75px" alt="PassGo logo" />
              </NavbarBrand>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/"
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={closeMenus}
                    className={`ml-2 mt-2 opc-navbar fs-6 ${vistaCreaEventos ? isScrolled ? 'opc-navbar' : 'opc-special' : ''}`}
                  >
                    {t("navbar.home")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/creaeventos"
                    exact
                    activeClassName="router-link-exact-active"
                    className={`mt-2 opc-navbar ${vistaCreaEventos ? isScrolled ? 'opc-navbar' : 'opc-special' : ''}`}
                    onClick={closeMenus}
                  >
                    {t("navbar.createEvents")}
                  </NavLink>
                </NavItem>
                {isAuthenticated && (
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      to="/eventos"
                      exact
                      activeClassName="router-link-exact-active"
                      className={`mt-2 opc-navbar ${vistaCreaEventos ? isScrolled ? 'opc-navbar' : 'opc-special' : ''}`}
                      onClick={closeMenus}
                    >
                      {t("navbar.myEvents")}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </div>

            <Nav className="d-none d-md-flex align-items-center gap-2" navbar>
              {isAuthenticated ? (
                <>
                  <UncontrolledDropdown inNavbar>
                    <DropdownToggle
                      nav
                      id="notificationsDropdown"
                      className="m-0 p-1"
                      onClick={toggleNotifications}
                      style={{ position: "relative" }}
                    >
                      <NotificationsIcon className={`${vistaCreaEventos ? isScrolled ? 'icon-navbar' : 'icon-special' : ''}`} style={{ height: "30px", width: "25px", color:"white" }} />
                      {notificaciones.filter(notificacion => (notificacion.atendido == null)).length > 0 && (
                        <span
                          className="position-absolute translate-middle badge rounded-circle bg-danger"
                          style={{ fontSize: "12px", padding: "0px 5px", top: "3px" }}
                        >
                          {notificaciones.filter(notificacion => (notificacion.atendido == null)).length > 9
                            ? '9+' : notificaciones.filter(notificacion => (notificacion.atendido == null)).length
                          }
                        </span>
                      )}
                    </DropdownToggle>
                    <DropdownMenu
                      end
                      style={{ maxHeight: "440px", minWidth: "341px", overflowY: "auto", borderRadius: "8px", boxShadow: "0 30px 30px rgba(0, 0, 0, 0.1)", padding: "0px" }}
                    >
                      <DropdownItem header style={{ padding: "8px", backgroundColor: "#f8f9fa", borderBottom: "3px solid #e9ecef", textAlign: "center" }}>{t("navbar.notifications")}</DropdownItem>

                      {notificaciones.filter(notificacion => (notificacion.atendido == null)).length ? (
                        notificaciones.filter(notificacion => (notificacion.atendido == null))
                          .sort((a, b) => new Date(b.createon) - new Date(a.createon))
                          .slice(0, 3).map((notificacion) => (
                            <DropdownItem
                              key={notificacion.id}
                              tag={Link}
                              to={"/Notificaciones"}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "339px",
                                justifyContent: "space-between",
                                padding: "15px",
                                borderBottom: "2px solid #f1f1f1",
                                backgroundColor: "transparent",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  flex: 1,
                                }}
                              >
                                <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                                  {notificacion.titulo.length > 30
                                    ? `${notificacion.titulo.substring(
                                      0,
                                      35
                                    )}...`
                                    : notificacion.titulo}
                                </div>
                                <div
                                  style={{
                                    fontSize: "14px",
                                    color: "gray",
                                    marginTop: "5px"
                                  }}
                                >
                                  {notificacion.descripcion.length > 43
                                    ? `${notificacion.descripcion.substring(
                                      0,
                                      43
                                    )}...`
                                    : notificacion.descripcion}
                                </div>
                                <span style={{ color: "gray", fontSize: '12px', margin: "5px" }}>
                                  {formatDistanceToNow(new Date(notificacion.createon), { addSuffix: true, locale: es })}
                                </span>
                              </div>
                              {!notificacion.atendido && (
                                <span className="p-1 bg-danger border border-light rounded-circle" />
                              )}
                            </DropdownItem>
                          ))
                      ) : (
                        <DropdownItem
                          tag={RouterNavLink}
                          to="/Notificaciones"
                          onClick={closeMenus}
                          style={{
                            height: "70px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#f8f9fa",
                            color: "#6c757d",
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          {t("notifications.noNotifications")}
                        </DropdownItem>
                      )}

                      {notificaciones?.length > 0 && (
                        <DropdownItem
                          tag={RouterNavLink}
                          to="/Notificaciones"
                          onClick={closeMenus}
                          style={{
                            backgroundColor: "#f8f9fa",
                            color: "#DC3545",
                            padding: "10px",
                            textAlign: "center",
                          }}
                        >
                          {t("navbar.verTodas")}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <Dropdown inNavbar isOpen={carritoOpen} toggle={toggleCarrito}>
                    <DropdownToggle
                      nav
                      className="m-0 p-1"
                      style={{ position: "relative" }}
                    >
                      <ShoppingCartIcon className={`${vistaCreaEventos ? isScrolled ? 'icon-navbar' : 'icon-special' : ''}`} 
                        style={{ height: "30px", width: "25", color:"white"  }}
                      />
                      {carrito?.items?.length > 0 && (
                        <span className="position-absolute translate-middle p-1 bg-danger border border-light rounded-circle" />
                      )}
                    </DropdownToggle>

                    <DropdownMenu
                      end
                      style={{
                        maxHeight: "440px",
                        minWidth: "300px",
                        overflowY: "auto",
                        borderRadius: "8px",
                        boxShadow: "0 30px 30px rgba(0, 0, 0, 0.1)",
                        padding: "0px",
                      }}
                    >
                      <DropdownItem header style={{ padding: "8px", backgroundColor: "#f8f9fa", borderBottom: "3px solid #e9ecef", textAlign: "center" }}>{t("navbar.cart")}</DropdownItem>
                      {carrito?.items?.length > 0 ? (
                        carrito?.items.map((c) => (
                          <DropdownItem
                            key={c.id}
                            tag={Link}
                            to={"/carrito"}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "330px",
                              justifyContent: "space-between",
                              padding: "15px",
                              borderBottom: "2px solid #f1f1f1",
                              backgroundColor: "transparent",
                              color: "black",
                            }}
                          >
                            <div className="d-flex flex-column">
                              <span>{c.tarifa?.concepto}</span>

                              <div className="d-flex justify-content-between">
                                <small className="text-secondary" style={{ marginRight: "230px" }}>
                                  x{c.cantidad} ({c.tarifa?.unidad})
                                </small>

                                <small className="text-secondary">
                                  $&nbsp;
                                  {(() => {
                                    const precioConComision = !c.tarifa?.asumir_comision
                                      ? (c.tarifa?.precio * (1 + (c.tarifa?.tipo_comision?.porcentaje / 100)))
                                      : c.tarifa?.precio;
                                    return (precioConComision * c.cantidad).toFixed(2);
                                  })()}
                                </small>
                              </div>
                            </div>
                          </DropdownItem>
                        ))
                      ) : (
                        <DropdownItem disabled className="text-secondary text-center py-3">
                          {!carrito.items && t("navbar.cartEmpty")}
                        </DropdownItem>
                      )}
                      <DropdownItem
                        className="text-primary"
                        tag={Link}
                        to={"/carrito"}
                        style={{ textAlign: "center", backgroundColor: "#f8f9fa", padding: "10px" }}
                      >
                        {t("navbar.goToCart")}{" "}
                        <ArrowForwardIcon size="sm" />
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>

                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      className="m-0 p-1"
                      id="profileDropDown"
                    >
                      <img
                        src={user.picture}
                        alt="Profile"
                        className="nav-user-profile rounded-circle"
                        width="50"
                      />
                    </DropdownToggle>
                    <DropdownMenu end
                      style={{
                        maxHeight: "250px",
                        minWidth: "200px",
                        overflowY: "auto",
                        borderBottom: "2px solid #f1f1f1",
                        boxShadow: "0 20px 20px rgba(0, 0, 0, 0.1)",
                        padding: "0px",
                      }}
                    >
                      <DropdownItem header style={{ padding: "8px", backgroundColor: "#f8f9fa", borderBottom: "3px solid #e9ecef", textAlign: "center" }}>{user.name}</DropdownItem>
                      <DropdownItem
                        tag={RouterNavLink}
                        to="/profile"
                        className="dropdown-profile"
                        activeClassName="router-link-exact-active"
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "black",
                          borderBottom: "2px solid #f1f1f1",
                        }}
                      >
                        <PersonIcon className="mr-3" />
                        {t("navbar.profile")}
                      </DropdownItem >
                      <DropdownItem
                        tag={RouterNavLink}
                        to="/favoritos"
                        className="dropdown-profile"
                        activeClassName="router-link-exact-active"
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "black",
                          borderBottom: "2px solid #f1f1f1",
                        }}
                      >
                        <StarIcon className="mr-3" />
                        {t("navbar.favorites")}
                      </DropdownItem>
                      <DropdownItem
                        id="qsLogoutBtn"
                        onClick={() => {
                          logoutWithRedirect();
                          closeMenus();
                        }}
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "black",
                          borderBottom: "2px solid #f1f1f1",
                        }}
                      >
                        <PowerSettingsNewIcon className="mr-3" />
                        {t("navbar.logout")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              ) : (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => {
                      loginWithRedirect();
                      closeMenus();
                    }}
                  >
                    {t("navbar.login")}
                  </Button>
                </NavItem>
              )}
            </Nav>
          </Container>
        </Navbar>
      )}
    </>
  );
};

export default NavBar;
