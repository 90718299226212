import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl, FormHelperText, Alert, Button } from '@mui/material';
import React, { useState, useEffect, useRef } from "react";
import { api } from "../../../services/api";
import { useTranslation } from "react-i18next";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ListAltIcon from '@mui/icons-material/ListAlt';


export const AgregarColaboradores = ({ eventId }) => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [evento, setEvento] = useState();
  const [eventocolaboradores, setEventoColaboradores] = useState();
  const form = useRef();
  const [t] = useTranslation("global");
  const [subrol, setSubrol] = useState("")
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [errorRol, setErrorRol] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.eventos.get(eventId);
        if (response.status === "success") {
          setEvento(response.data.evento);
          setEventoColaboradores(response.data.colaboradores);
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };
    callApi();
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (email === "") {
      setErrorMail(true);
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Introduce un correo electrónico válido");
      return;
    }

    if (subrol === "") {
      setErrorRol(true);
      return;
    }

    const data = { email, subrol: Number(subrol) };
    const response = api.colaboradores.createColaborador(eventId, data);
    return response.then((response) => {
      if (response.status === "error") {
        setError("Error: " + response.message);
        setSubmitting(false);
      }
      if (response.status === "success") {
        setSubmitting(false);
        setSubmitted(true);
        setError("");
        setErrorMail(false);
        setErrorRol(false);
        setSubrol("")
        const data = {
          recipient: email,
          evento: evento.evento,
        };
        const response = api.email.send(data);
        setEmail("");
        if (response.status === "error") {
          setError("Error: " + response.message);
        }
      }
    });
  };
  console.log("testind id " + eventId)

  const roles = [
    {
      value: 0, label: "Colaborador General", icon: <ListAltIcon style={{
        color: "#067bea"
      }} />, description: "Rol encargado de diversas tareas como controlar el acceso y los vouchers."
    },
    {
      value: 2, label: "Control de Acceso", icon: <QrCodeScannerIcon style={{
        color: "#067bea"
      }} />, description: "Rol encargado de controlar el acceso de las personas autorizadas al evento."
    },
    {
      value: 3, label: "Control de Voucher", icon: <QrCodeScannerIcon style={{
        color: "#067bea"
      }} />, description: "Rol encargado de controlar y gestionar el canje de los vouchers del evento."
    }
  ];

  return (
    <>
      <div>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: '800px', margin: 'auto', gap: "20px" }}
        >
          <TextField
            name="email"
            label="Correo electrónico"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorMail}
            helperText={errorMail ? "Ingrese un correo electronico" : ""}
          />
          <FormControl
            error={errorRol}
          >
            <p className='mb-3'>Seleccione un rol para su colaborador</p>
            <div className="row">
              {roles.map((role) => (
                <div key={role.value} className="col-4 mb-3">
                  <button
                    type="button"
                    onClick={() => setSubrol(role.value)}
                    className={`w-100 boton-tipo ${subrol === role.value ? 'boton-tipo-active' : ''}`}
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '10px',
                      background: subrol === role.value ? '#e3f2fd' : 'white',
                      cursor: 'pointer',
                      textAlign: 'left'
                    }}
                  >
                    <div className='d-flex flex-column'>
                      <span className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                        {role.label}
                      </span>
                      <span style={{ fontSize: "13px" }}>{role.description} </span>
                    </div>
                    <span className="indicator-wrapper mx-2">
                      <span className="indicator-fuera"></span>
                      <span className="indicator-dentro" style={{ marginLeft: "6px" }}></span>
                    </span>
                  </button>
                </div>
              ))}
            </div>

            {errorRol &&
              <FormHelperText>Por favor, seleccione un rol.</FormHelperText>}
          </FormControl>
          {error &&
            <Alert severity="error">{error}</Alert>
          }
          {submitted && (
            <Alert severity="success">
              {t("collaborator.successEmailAlert")}
            </Alert>
          )}
          <Button variant="contained" className='boton-submit' type="submit">{t("button.register")} colaborador</Button>
        </Box>
      </div>
    </>
  )
}
