import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import './CookiePreferences.css';
import { useTranslation } from 'react-i18next';

const CookiePreferences = ({ onClose }) => {
    const [t] = useTranslation("global");
    const [cookies, setCookie] = useCookies(['consent']);
    const [preferences, setPreferences] = useState({
        functional: cookies.consent?.functional || false,
        analytics: cookies.consent?.analytics || false,
        advertising: cookies.consent?.advertising || false,
        thirdParty: cookies.consent?.thirdParty || false,
    });
    const expirationDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)

    const savePreferences = () => {
        setCookie('consent', preferences, { path: '/', expires: expirationDate })
        onClose();
    };

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setPreferences((prev) => ({ ...prev, [name]: checked }));
    };

    useEffect(() => {
        if (!cookies.essentialConsent) {
            setCookie('essentialConsent', true, { path: '/', expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000) })
        }
    }, [cookies.essentialConsent, setCookie])

    return (
        <div className="cookie-preferences">
            <h3>{t("cookies.cookie_preferences")}</h3>
            <div className="options-container">
                <label>
                    <input type="checkbox"
                        name="essential"
                        checked={cookies.essentialConsent}
                        onChange={() => { }}
                        disabled={true} />
                    {t("cookies.essential_cookies")}
                </label>
                <label>
                    <input type="checkbox" name="functional" checked={preferences.functional} onChange={handleChange} />
                    {t("cookies.functionality_cookies")}
                </label>
                <label>
                    <input type="checkbox" name="analytics" checked={preferences.analytics} onChange={handleChange} />
                    {t("cookies.analytics_cookies")}
                </label>
                <label>
                    <input type="checkbox" name="advertising" checked={preferences.advertising} onChange={handleChange} />
                    {t("cookies.advertising_cookies")}
                </label>
            </div>
            <div>
                <button onClick={savePreferences}>{t("cookies.save_preferences")}</button>
                <button onClick={onClose} style={{ marginLeft: '10px' }}>{t("cookies.close")}</button>
            </div>
        </div>
    );
};

export default CookiePreferences;
