import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { api } from "../../../services/api";
import { Alert, Card, CardBody, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import Loading from '../../../components/Loading';
import VolverButton from '../../../components/VolverButton';
import { useTranslation } from 'react-i18next';
import './cuentas.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

function VincularCuentas() {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const history = useHistory();
  const param = useParams();
  const initForm = {
    idevento: param.id,
    titular: '',
    dni: '',
    cbu: '',
    alias: '',
    banco: ''
  }
  const [formData, setFormData] = useState(initForm);
  const [formValid, setFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [t] = useTranslation("global");
  const [metodoPago, setMetodoPago] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const convertedFormData = {
      ...formData,
      idevento: parseInt(formData.idevento)
    }

    if (formValid) {
      try {
        const response = await api.eventoCuentas.createCuenta(param.id, convertedFormData);

        if (response.status === "success") {
          setFormData(initForm);
          setFormSubmitted(true);
          setState({
            ...state,
            apiMessage: response.message
          });
          setTimeout(() => {
            history.goBack();
          }, 1000);
        }

      } catch (error) {
        setState({
          ...state,
          error: error
        });
      }
    }
  }

  const handleCardClick = (metodoPago) => {
    setMetodoPago(metodoPago);
  }

  const renderForm = () => {
    switch (metodoPago) {
      case 'mercadopago':
        return <MercadoPagoForm t={t} handleSubmit={handleSubmit} handleChange={handleChange} formValid={formValid} handleGenerateLink={handleGenerateLink} loading={loading}/>;
      case 'binance':
        return <BinanceForm t={t} handleSubmit={handleSubmit} handleChange={handleChange} formValid={formValid} />;
      default:
        return <Default t={t} />;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const [cuentas, setCuentas] = useState();
  useEffect(() => {
    const callApi = async (id) => {
      try {
        const response = await api.eventoCuentas.getAll(id);

        setCuentas(response.data);

      } catch (error) {
        console.log(error)
      }
    }

    callApi(param.id);
  }, [param.id]);

  useEffect(() => {
    const { titular, dni, cbu, alias/* , banco */ } = formData;

    setFormValid(titular && dni && cbu && alias/*  && banco */);
  }, [formData]);

  const handleGenerateLink = async () => {
    setLoading(true);

    try {
      const idevento = parseInt(param?.id);

      const response = await api.mercadopago.vincularCuenta({
        idevento: idevento,
      });

      if (response.status === "success") {
        window.location = response.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      {state.error &&
        <Alert color="danger" className="container">
          {state.error}
        </Alert>
      }

      {formSubmitted &&
        <Alert color="success" className="container">
          {t("accountBank.successAddedAlert")}
        </Alert>
      }

      <div className="d-flex justify-content-center gap-4 flex-wrap">
        <div className="d-flex flex-column gap-2">
          <button disabled={cuentas?.mercado_pago === "Vinculado"}>
            <Card className="card-pago" onClick={() => handleCardClick('mercadopago')}>
              <img className="img-card-pago" src="/mp-icon.png" alt="mercado pago icon" />
            </Card>
          </button>
          <p className={"text-center mb-0 p-1 rounded text-white " + (cuentas?.mercado_pago === "Vinculado" ? "bg-success" : "bg-warning")}>
            {cuentas?.mercado_pago}
          </p>
        </div>
        <div className="d-flex flex-column gap-2">
          <button disabled={cuentas?.binance === "No disponible"}>
            <Card className="card-pago" onClick={() => handleCardClick('binance')}>
              <img className="img-card-pago-binance" src="/binance.png" alt="binance icon" />
            </Card>
          </button>
          <p className="bg-body-secondary text-center mb-0 p-1 rounded text-white">
            {cuentas?.binance}
          </p>
        </div>
      </div>

      {renderForm()}
    
      <div className="container d-flex justify-content-end">
        <VolverButton />
      </div>
    </div>
  )
}

function MercadoPagoForm({ t, handleSubmit, handleChange, formValid, handleGenerateLink, loading }) {
  return (
    <Form className="col-lg-8 mx-auto col-12" onSubmit={handleSubmit}>
      <h4 className="text-center my-4">Vinculá tu cuenta de Mercado Pago</h4>
      
      <Card className="bg-body-secondary mx-auto mb-3 vinculacion" style={{width: '350px', cursor: 'pointer'}}>
        <CardBody>
          {!loading
            ?
            <p className="text-secondary text-center mb-0" onClick={handleGenerateLink}>
              <FontAwesomeIcon icon={faAdd} size="2xl" />
              <br/>
              Vinculá tu cuenta de Mercado Pago y comenzá a recibir los ingresos de tus ventas.
            </p>
            :
            <p className="text-secondary text-center mb-0">
              <Spinner color="secondary" />
              <br/>
              Redirigiendo...
            </p>
          }
        </CardBody>
      </Card>
    </Form>
  );
}

function BinanceForm({ t, handleSubmit, handleChange, formValid }) {
  return (
    <Form className="col-lg-8 mx-auto col-12">
      <h4 className="my-4">Vinculá tu cuenta de Binance</h4>
      {true
        ?
        <Alert color="info">
          Estamos trabajando en ello.
        </Alert>
        :
        <>
          <div className="d-flex gap-3">
            <FormGroup className="w-100">
              <Label for="titular">
                {t("accountBank.accountHolder")}
              </Label>
              <Input
                id="titular"
                name="titular"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup className="w-100">
              <Label for="DNI">
                DNI
              </Label>
              <Input
                id="DNI"
                name="dni"
                type="number"
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <Label for="CBU">
              CBU
            </Label>
            <Input
              id="CBU"
              name="cbu"
              type="number"
              onChange={handleChange}
            />
          </FormGroup>

          <div className="d-flex gap-3">
            <FormGroup className="w-100">
              <Label for="alias">
                Alias
              </Label>
              <Input
                id="alias"
                name="alias"
                type="text"
                onChange={handleChange}
              />
            </FormGroup>
          </div>
        </>
      }

      {false &&
        <button className="btn btn-primary" style={{ margin: '5px' }}
          disabled={!formValid}>
          {t("button.add")}
        </button>
      }
    </Form>
  );
}

function Default({t}){
  return(
    <p className="text-center mt-4">
      {t("accountBank.txtDefault")}
    </p>
  );
}

export default withAuthenticationRequired(VincularCuentas, {
  onRedirecting: () => <Loading />,
});