import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { config } from "../../services/config";
// import { ToastContainer, toast } from 'react-toastify';
import {toast} from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
import './toast.css'
import { api } from '../../services/api';

const socket = io(config.URL_SOCKET,{
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 2000,
  reconnectionDelayMax: 600000,
  path:"/socket/",
}); // URL del servidor Socket.IO
console.log(config.URL_SOCKET)
function NotificacionComponent() {
  const [connected, setConnected] = useState(socket.connected); // el connected verifica si se establecio bien la conexion
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    // Obtener el userId desde el endpoint
    const fetchUserId = async () => {
      try {
        const response = await api.usuarios.getUsuario();
        if (response.status === "success") {
          setUserId(response.data.usuario.id);
        } else {
          // console.log('No se pudo obtener el usuario activo');
        }
      } catch (error) {
        console.error('Error al obtener el userId:', error);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    if(userId){
      // escuchar el evento de conexion
      socket.on('connect', () => {
        setConnected(true); // cambiar el estado a conectado. si es que se conecta
        setError(null)
        console.log('Conectado al servidor Socket.IO');

        //registrar el userId
        socket.emit('registrarUsuario', userId); //emitir el userid al servidor del socket
    });

    // // emision del userId cuando se reconecta
    // socket.on('reconnect', () => {
    //   // console.log('Reconectado al servidor Socket.IO');
    //   socket.emit('registrarUsuario', userId); // volver a emitir el userId
    // });

    // manejar errores de conexion
    socket.on('connect_error', (err) =>{
      console.log('Error de conexion: ', err);
      setError('No se pudo conectar al servidor..')
    })

    // manejar desconexion
    socket.on('disconnect', () => {
      console.log('Desconectado del servidor..')
      setConnected(false)
    })

    socket.on('nueva-notificacion', (notification)=> {
      // console.log('Notificacion recibida: ', notification);
      setNotifications(prevNotifications => [...prevNotifications, notification])
    
     //Mostrar toast al recibir una notificacion al assignedto
    //   toast.success(`${notification.titulo} ${notification.descripcion}`, {
    //   position: 'top-right',
    //   autoClose: 4000, 
    // });
  })

    // limpiar la conexion al desmontar el componente
    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('disconnect')
      socket.off('recconect')
      socket.off('nueva-notificacion');
    };
  }
  }, [userId]);

  useEffect(() => {
    if(connected && userId){
      // console.log('emitiendo userId despues de la conexion o reconexion: ', userId);
      socket.emit('registrarUsuario', userId);
    }
  }, [connected, userId]);

  return null;

  //ESTE JSX QUEDA PARA PRUEBAS NOMAS SI SE NECESITA MAS ADELANTE:
//   return (
//     <div>
//        {/* <ToastContainer /> */}
//       <h1>Cliente Socket.IO (prueba)</h1>
//       {connected ? (
//         <p style={{ color: 'green' }}>Conectado al servidor!</p>
//       ) : error ? (
//         <p style={{color: 'red'}}> {error} </p>
//       ):(
//         <p style={{ color: 'orange' }}>Conectando al servidor...</p>
//       )}
// {/* 
//   //     <div>
//   //       <h4>Notificaciones</h4>
//   //       <ul>
//   //         {notifications.length > 0 ? (
//   //           notifications.map((notification, i) => (
//   //             <li key={i}>{notification.titulo}</li>
//   //           ))
//   //         ) : (
//   //           <p>No hay notificaciones.</p>
//   //         )}
//   //       </ul>
//   //     </div> */}
//      </div>
//    );
}
export {socket};
export default NotificacionComponent;