import React, { useState, useEffect, useRef } from "react";
import { Alert, FormControl, TextField, Button, CircularProgress } from "@mui/material";
import { api } from "../../../services/api";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import "./event.css";
import useUbicaciones from "../../../hooks/hookUbicacion";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import MicExternalOnOutlinedIcon from "@mui/icons-material/MicExternalOnOutlined";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import SportsBasketballOutlinedIcon from "@mui/icons-material/SportsBasketballOutlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const categoriaIcons = {
  1: <NightlifeOutlinedIcon sx={{ marginRight: "10px" }} />,
  2: <VolunteerActivismOutlinedIcon sx={{ marginRight: "10px" }} />,
  3: <SportsBasketballOutlinedIcon sx={{ marginRight: "10px" }} />,
  4: <FastfoodOutlinedIcon sx={{ marginRight: "10px" }} />,
  5: <BusinessCenterOutlinedIcon sx={{ marginRight: "10px" }} />,
  6: <MicExternalOnOutlinedIcon sx={{ marginRight: "10px" }} />,
};

const EditEvent = () => {
  const { id } = useParams();
  const history = useHistory();
  const [ownerid, setOwnerId] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [eventos_tipos_id, setEventotipo] = useState("");
  const [tipos, setTipos] = useState("");
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [publicoprivado, setPublicoPrivado] = useState(false);
  const [direccion, setDireccion] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [expandedSection, setExpandedSection] = useState(null);
  const refSeccion = useRef({});
  const [evento, setEvento] = useState("");
  const [t] = useTranslation("global");
  const localTime = moment.tz.guess();
  const [files, setFiles] = useState([]);
  const [mediaUrls, setMediaUrls] = useState([]);
  const [mediaTypes, setMediaTypes] = useState([]);
  const [mediaFormats, setMediaFormats] = useState([]);
  const [mediaId, setMediaId] = useState([]);
  const [mapUrl, setMapUrl] = useState("");
  const [lengthDescrip, setLengthDescrip] = useState("");
  const [showEliminar, setShowEliminar] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    provincias,
    ciudades,
    provSeleccionada,
    setProvSeleccionada,
    ciudadSeleccionada,
    setCiudadSeleccionada,
  } = useUbicaciones("AR"); // pais predefinido

  const handleProvChange = (event) => {
    const selectedProvIso2 = event.target.value;
    setProvSeleccionada(selectedProvIso2);
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setCiudadSeleccionada(selectedCityId);
  };

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await api.eventos.get(id);
        if (response.status === "success") {
          const event = response.data.evento;
          setOwnerId(event.ownerid);
          setEvento(event.evento);
          setDescripcion(event.descripcion);
          setEventotipo(event.eventos_tipos_id);
          setFecha(moment(event.fecha).format("YYYY-MM-DD"));
          setHora(moment(event.hora).format("HH:mm"));
          setDireccion(event.direccion);
          setCiudad(event.ciudad);
          setPublicoPrivado(event.privado);
          setMediaUrls(event.attachs.map((attach) => attach.url));
          setMediaTypes(event.attachs.map((attach) => attach.type));
          setMediaFormats(event.attachs.map((attach) => attach.format));
          setMediaId(event.attachs.map((attach) => attach.public_id));
        }
      } catch (error) {
        console.error("Error fetching event data: " + error);
      }
    };
    fetchEventData();
  }, [id]);

  useEffect(() => {
    const getEventosTipos = async () => {
      try {
        const response = await api.eventos.getAllEventosTipos();
        if (response.status === "success") {
          setTipos(response.data.eventoTipos);
        }
      } catch (error) {
        console.error("Error: " + error);
      }
    };
    getEventosTipos();
  }, []);

  useEffect(() => {
    if (mediaUrls.length > 0) {
      setSelectedFileIndex(0);
    } else {
      setSelectedFileIndex(null);
    }
  }, [mediaUrls]);

  const handleAddFileClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*,video/*";
    fileInput.multiple = true;
    fileInput.onchange = handleFileChange;
    fileInput.click();
  };

  const handleFileChange = async (e) => {
    const maxSizeFile = 10 * 1024 * 1024;
    const maxFiles = 5;
    const selectedFiles = Array.from(e.target.files);

    if (files.length + selectedFiles.length > maxFiles) {
      alert(`Solo puedes subir como máximo ${maxFiles} archivos.`);
      return;
    }

    const validaFiles = [];
    selectedFiles.forEach((file) => {
      if (file.size <= maxSizeFile) {
        validaFiles.push(file);
      } else {
        console.error(
          `El archivo ${file.name} supera el límite de tamaño de 10MB`
        );
        alert(`El archivo ${file.name} supera el límite de tamaño de 10MB`);
      }
    });

    if (validaFiles.length === 0) return;

    setLoading(true);

    setFiles((prevFiles) => {
      const combinedFiles = [...prevFiles, ...validaFiles];

      if (combinedFiles.length > maxFiles) {
        alert(`Solo puedes subir como máximo ${maxFiles} archivos.`);
        return prevFiles;
      }

      if (selectedFileIndex === null && validaFiles.length > 0) {
        setSelectedFileIndex(prevFiles.length);
      }

      return combinedFiles;
    });

    const formData = new FormData();
    validaFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await api.media.upload(formData);
      if (response.status === "success") {
        const medias = response.data.media;
        setMediaUrls(medias.map((media) => media.url));
        setMediaTypes(medias.map((media) => media.type));
        setMediaFormats(medias.map((media) => media.format));
        setMediaId(medias.map((media) => media.public_id));
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteMedia = async (index) => {
    const publicId = mediaId[index];

    try {
      const response = await api.media.delete({ public_id: publicId });
      if (response.status === "success") {
        setMediaUrls(mediaUrls.filter((_, i) => i !== index));
        setMediaTypes(mediaTypes.filter((_, i) => i !== index));
        setMediaFormats(mediaFormats.filter((_, i) => i !== index));
        setMediaId(mediaId.filter((_, i) => i !== index));

        setFiles(files.filter((_, i) => i !== index));

        if (mediaUrls.length > 1) {
          setSelectedFileIndex(0);
        } else {
          setSelectedFileIndex(null);
        }
      }
    } catch (error) {
      console.error("Error deleting media:", error);
    }
  };

  const handleSectionClick = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleDescripcionChange = (e) => {
    const value = e.target.value;
    setDescripcion(value);
    setLengthDescrip(value.length);
  };

  const handleThumbnailClick = (index) => {
    setSelectedFileIndex(index);
  };

  const renderFilePreview = (url, index) => {
    const isImage =
      mediaTypes[index] && mediaTypes[index].toLowerCase().includes("image");
    const isVideo =
      mediaTypes[index] && mediaTypes[index].toLowerCase().includes("video");
    const fileURL = url;

    const thumbnailClass =
      selectedFileIndex === index ? "thumbnail selected" : "thumbnail";

    return (
      <div
        key={index}
        className={thumbnailClass}
        onClick={() => handleThumbnailClick(index)}
      >
        {isImage && <img src={fileURL} alt={`Preview ${index}`} />}
        {isVideo && <video src={fileURL} />}
      </div>
    );
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const regexE = /^(?!([a-zA-Z])\1+$)[a-zA-Z\sñÑ\u00C0-\u017F]*$/;
    const hoy = new Date();
    const fechasel = new Date(fecha);
    const fechamax = new Date().setFullYear(hoy.getFullYear() + 1);

    const newErrors = [];

    if (evento.trim() === "")
      newErrors.evento = "El nombre del evento no puede estar vacío.";
    if (!regexE.test(evento))
      newErrors.evento =
        "Evita el uso de caracteres especiales en el nombre del evento";
    if (evento.length > 50)
      newErrors.evento = "El nombre del evento no puede exceder 50 caracteres.";
    if (evento.length < 5)
      newErrors.evento =
        "El nombre del evento debe tener al menos 5 caracteres.";

    if (descripcion.trim() === "")
      newErrors.descripcion = "La descripción es obligatoria.";
    if (descripcion.length > 480)
      newErrors.descripcion = "La descripción no puede superar 250 caracteres.";
    if (descripcion.length < 5)
      newErrors.descripcion = "La descripción necesita al menos 5 caracteres.";

    if (eventos_tipos_id === "")
      newErrors.eventos_tipos_id = "Selecciona un tipo de evento.";

    if (fechasel > fechamax) newErrors.fecha = t("errors.errorDate");
    if (fecha === "" || hora === "")
      newErrors.hora = "La fecha y hora son necesarias.";
    if (ciudadSeleccionada === "" || direccion === "")
      newErrors.direccion = "La ciudad y la direccion no pueden estar vacías.";

    if (mediaUrls.length === 0)
      newErrors.mediaUrls = "Por favor, ingrese una imagen o video.";

    // detiene el envio si hay errores
    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    /*  const isPrivado = publicoprivado === "true"; */
    const fechaEnHorarioLocal = moment
      .tz(`${fecha} ${hora}`, localTime)
      .format("YYYY-MM-DDTHH:mm:ss");
    const horaEnHorarioLocal = moment
      .tz(`${fecha} ${hora}`, localTime)
      .format("YYYY-MM-DDTHH:mm:ss");

    const data = {
      evento: {
        ownerid: parseInt(ownerid),
        id: parseInt(id),
        evento: evento,
        descripcion: descripcion,
        fecha: moment(fechaEnHorarioLocal)
          .clone()
          .tz("America/Buenos_Aires")
          .format("YYYY-MM-DDTHH:mm:ss-03:00"),
        hora: moment(horaEnHorarioLocal)
          .clone()
          .tz("America/Buenos_Aires")
          .format("YYYY-MM-DDTHH:mm:ss-03:00"),
        eventos_tipos_id: eventos_tipos_id,
        direccion: direccion,
        ciudad: ciudadSeleccionada,
      },
      attachs: mediaUrls.map((url, index) => ({
        url: url,
        type: mediaTypes[index],
        format: mediaFormats[index],
        public_id: mediaId[index],
      })),
    };

    try {
      const response = await api.eventos.setEvento(id, data);
      if (response.status === "error") {
        setError("Error: " + response.message);
      }
      if (response.status === "success") {
        setEvento("");
        setDescripcion("");
        setEventotipo("");
        setFecha("");
        setHora("");
        setDireccion("");
        setCiudad("");
        setMediaUrls("");
        setMediaTypes("");
        setMediaFormats("");
        setMediaId("");
        setError("");
        setSubmitted(true);
        setTimeout(() => {
          history.push("/eventos");
        }, 1500);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [enviado, setEnviado] = useState(false);
  const [errorEliminar, setErrorEliminar] = useState(false);
  const [inputNombreEvento, setInputNombreEvento] = useState("");
  const [errorValidacion, setErrorValidacion] = useState(false);
  const handleEliminarEvento = async () => {
    if (inputNombreEvento === evento) {
      try {
        const response = await api.eventos.deleteEvento(id);
        if (response.status === "success") {
          console.log("Evento eliminado");
          setEnviado(true);
          setTimeout(() => {
            history.push(`/eventos/`);
          }, 3000);
        } else {
          setErrorValidacion(true);
          setErrorEliminar(false);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    } else {
      setErrorEliminar(true);
      setErrorValidacion(false);
    }
  };

  const mapdireccion = direccion + " " + ciudad;
  const apiKey = "AIzaSyCzPvPffhA4gns9-sfxzSlXXC4ytQtbKxc";

  useEffect(() => {
    if (mapdireccion) {
      const direccionEncoded = encodeURIComponent(mapdireccion);
      const newMapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${direccionEncoded}`;

      setMapUrl(newMapUrl);
    }
  }, [mapdireccion, apiKey]);

  const [tipoSelected, setTipoSelected] = useState(eventos_tipos_id || null);

  useEffect(() => {
    if (eventos_tipos_id) {
      setTipoSelected(eventos_tipos_id);
    }
  }, [eventos_tipos_id]);

  const handleTipoSelect = (id) => {
    setTipoSelected(id);
    setEventotipo(id);
  };

  return (
    <div className="container-evento">
      <form
        id="edit-evento"
        onSubmit={handleSubmit}
        style={{ padding: "20px" }}
      >
        {/* SECCION IMAGENES */}
        <div
          ref={(el) => (refSeccion.current["img"] = el)}
          className={`seccion-evento ${expandedSection === "img" ? "expanded" : ""
            }`}
          onClick={() => handleSectionClick("img")}
        >
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p
              className={`titulo-seccion-evento ${expandedSection === "img" ? "hidden" : ""
                }`}
            >
              {t("event.image")}
            </p>
            {error.mediaUrls && (
              <ErrorOutlineOutlinedIcon
                sx={{
                  color: "red",
                  display: expandedSection === "img" ? "none" : "block",
                }}
              />
            )}
          </div>

          <p
            className={`descrip-seccion-evento ${expandedSection === "img" ? "hidden" : ""
              }`}
          >
            {t("event.imageDescrip")}
          </p>

          {expandedSection === "img" && (
            <div className="contenido-evento">
              <FormControl onClick={handleInputClick}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="titulo-seccion-evento-expanded">
                    {t("event.addImage")} <span className="text-danger">*</span>
                  </p>
                  <button
                    type="button"
                    onClick={() => handleSectionClick("img")}
                    className="d-flex justify-content-end"
                  >
                    <CloseOutlinedIcon sx={{ color: "#3659E3" }} />
                  </button>
                </div>

                <p className="descrip-seccion-evento-expanded">
                  Sube hasta 5 imágenes y videos que muestren los detalles y
                  momentos destacados para que los asistentes tengan una visión
                  del evento.
                  <br />
                  Asegúrate de elegir archivos de alta calidad para un mejor
                  impacto visual.
                </p>
                <div className="info-input-img">
                  <ul className="ul-info-img">
                    <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                      Tamaño recomendado de imagen: 19200x1280
                    </li>
                    <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                      Tamaño máximo de archivo: 10MB
                    </li>
                    <li style={{ fontSize: "10px", fontWeight: "normal" }}>
                      Formatos admitidos de imagen: JPEG, PNG
                    </li>
                  </ul>
                </div>

                <div
                  className={`input-file ${mediaUrls.length === 0 ? "" : "border-0"
                    }`}
                >
                  <input
                    id="files"
                    name="files"
                    type="file"
                    accept="image/*,video/*"
                    multiple
                    onChange={handleFileChange}
                    onClick={handleInputClick}
                    style={{ display: "none" }}
                  />
                  {loading ? (
                    <div className="d-flex flex-column align-items-center">
                      <p>Cargando archivos...</p>
                      <CircularProgress />
                    </div>
                  ) : mediaUrls.length === 0 ? (
                    <label className="placeholder-input-img" htmlFor="files">
                      {t("event.inputImage")}
                    </label>
                  ) : (
                    <>
                      <div className="imagen-contenedor">
                        {selectedFileIndex !== null && (
                          <div className="main-preview">
                            {mediaTypes[selectedFileIndex].includes("image") ? (
                              <img
                                src={mediaUrls[selectedFileIndex]}
                                alt="Imagen seleccionada"
                              />
                            ) : (
                              <video
                                src={mediaUrls[selectedFileIndex]}
                                controls
                                alt="Video seleccionado"
                              />
                            )}
                            <button
                              type="button"
                              className="remove-button"
                              onClick={() => {
                                deleteMedia(selectedFileIndex);
                              }}
                            >
                              Eliminar
                            </button>
                          </div>
                        )}

                        <div className="thumbnails-contenedor">
                          <div
                            className="boton-agregar-img"
                            onClick={handleAddFileClick}
                          >
                            <AddOutlinedIcon sx={{ color: "#3659E3" }} />
                          </div>
                          {mediaUrls.map(renderFilePreview)}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <></>
              </FormControl>
            </div>
          )}
        </div>

        {/* SECCION TITULO */}
        <div
          ref={(el) => (refSeccion.current["gral"] = el)}
          className={`seccion-evento ${expandedSection === "gral" ? "expanded" : ""
            }`}
          onClick={() => handleSectionClick("gral")}
        >
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p
              className={`titulo-seccion-evento fs-1 ${expandedSection === "gral" ? "hidden" : ""
                }`}
            >
              {t("event.eventName")}
            </p>
            {error.evento && (
              <ErrorOutlineOutlinedIcon
                sx={{
                  color: "red",
                  display: expandedSection === "gral" ? "none" : "block",
                }}
              />
            )}
          </div>
          <p
            className={`descrip-seccion-evento ${expandedSection === "gral" ? "hidden" : ""
              }`}
          >
            {t("event.eventNameDescrip")}
          </p>

          {expandedSection === "gral" && (
            <div className="contenido-evento">
              <FormControl onClick={handleInputClick}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="titulo-seccion-evento-expanded">
                    {t("event.eventDescrip")}
                  </p>
                  <button
                    type="button"
                    onClick={() => handleSectionClick("gral")}
                    className="d-flex justify-content-end"
                  >
                    <CloseOutlinedIcon sx={{ color: "#3659E3" }} />
                  </button>
                </div>

                <p className="subtitulo-seccion-evento-expanded">
                  {t("event.eventName")} <span className="text-danger">*</span>
                </p>
                <p className="descrip-seccion-evento-expanded mb-0">
                  {t("event.eventDescrip2")}
                </p>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    input: {
                      padding: "10px 15px",
                    },
                  }}
                  type="text"
                  name="evento"
                  value={evento}
                  onClick={handleInputClick}
                  placeholder={t("event.eventName")}
                  onChange={(e) => setEvento(e.target.value)}
                  required
                />
                <>
                  {error.evento && (
                    <Alert
                      severity="error"
                      sx={{
                        marginTop: "10px",
                      }}
                    >
                      {error.evento}
                    </Alert>
                  )}
                </>
              </FormControl>

              <FormControl onClick={handleInputClick}>
                <p className="subtitulo-seccion-evento-expanded">
                  {t("event.description")}{" "}
                  <span className="text-danger">*</span>
                </p>
                <p className="descrip-seccion-evento-expanded">
                  {t("event.descriptionXl")}
                </p>
                <ReactQuill
                  theme="snow"
                  minLength={5}
                  maxLength={480}
                  value={descripcion}
                  onChange={setDescripcion}
                  onClick={handleInputClick}
                  style={{ height: "auto" }}
                  modules={modules}
                  formats={["header", "bold", "italic", "underline", "list"]}
                />
                <>
                  {error.descripcion && (
                    <Alert
                      severity="error"
                      sx={{
                        marginTop: "10px",
                      }}
                    >
                      {error.descripcion}
                    </Alert>
                  )}
                </>
              </FormControl>
            </div>
          )}
        </div>

        {/* SECCION FECHA Y LUGAR */}
        <div
          ref={(el) => (refSeccion.current["date"] = el)}
          className={`seccion-evento ${expandedSection === "date" ? "expanded" : ""
            }`}
          onClick={() => handleSectionClick("date")}
        >
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p
              className={`titulo-seccion-evento ${expandedSection === "date" ? "hidden" : ""
                }`}
            >
              {t("event.dateandplace")}
            </p>
            {(error.fecha || error.hora || error.direccion) && (
              <ErrorOutlineOutlinedIcon
                sx={{
                  color: "red",
                  display: expandedSection === "date" ? "none" : "block",
                }}
              />
            )}
          </div>
          <p
            className={`descrip-seccion-evento ${expandedSection === "date" ? "hidden" : ""
              }`}
          >
            {t("event.dateDescrip")}
          </p>

          {expandedSection === "date" && (
            <div className="contenido-evento" onClick={handleInputClick}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p className="subtitulo-seccion-evento-expanded d-flex justify-content-start">
                  {t("event.dateandtime")}{" "}
                  <span className="text-danger">*</span>
                </p>
                <button
                  type="button"
                  onClick={() => handleSectionClick("date")}
                  className="d-flex justify-content-end"
                >
                  <CloseOutlinedIcon sx={{ color: "#3659E3" }} />
                </button>
              </div>
              <p className=" descrip-seccion-evento-expanded mb-0">
                Ingresa que dia y a que hora se realizara el evento
              </p>
              <div className="date-hora">
                <FormControl onClick={handleInputClick}>
                  <TextField
                    sx={{
                      marginTop: "5px",
                      marginBottom: "15px",
                      input: {
                        padding: "10px 15px",
                      },
                    }}
                    type="date"
                    name="date"
                    value={fecha}
                    onClick={handleInputClick}
                    onChange={(e) => setFecha(e.target.value)}
                    required
                    style={{ width: "300px" }}
                  />
                </FormControl>

                <FormControl onClick={handleInputClick}>
                  <TextField
                    sx={{
                      marginTop: "5px",
                      marginBottom: "15px",
                      input: {
                        padding: "10px 15px",
                      },
                    }}
                    type="time"
                    name="hora"
                    onClick={handleInputClick}
                    value={hora}
                    onChange={(e) => setHora(e.target.value)}
                    required
                  />
                </FormControl>
              </div>
              <>
                {error.hora && (
                  <Alert
                    severity="error"
                    sx={{
                      marginTop: "5px",
                      marginBottom: "25px",
                    }}
                  >
                    {error.hora}
                  </Alert>
                )}
              </>

              <FormControl>
                <p className="subtitulo-seccion-evento-expanded mt-2 ">
                  {t("event.location")} <span className="text-danger">*</span>
                </p>
                <p className="descrip-seccion-evento-expanded">
                  {t("event.locationDescrip")}
                </p>
                <div className="d-flex flex-wrap gap-1 align-items-center">
                  <FormControl
                    onClick={handleInputClick}
                    className=""
                    style={{}}
                  >
                    <div>
                      <select
                        value={provSeleccionada}
                        onChange={handleProvChange}
                        required
                        style={{}}
                        className="input-select-evento"
                      >
                        <option value="">Seleccione una provincia</option>
                        {provincias.map((provincia) => (
                          <option key={provincia.iso2} value={provincia.iso2}>
                            {provincia.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </FormControl>
                  <FormControl onClick={handleInputClick} className="">
                    <div>
                      <select
                        value={ciudadSeleccionada}
                        onChange={handleCityChange}
                        required
                        className="input-select-evento"
                        disabled={!provSeleccionada}
                      >
                        <option value="" disabled>
                          Seleccione una ciudad
                        </option>
                        {ciudades.map((ciudad) => (
                          <option key={ciudad.id} value={ciudad.name}>
                            {ciudad.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </FormControl>
                  <FormControl
                    onClick={handleInputClick}
                    className=""
                    style={{ width: "60%" }}
                  >
                    <TextField
                      sx={{
                        marginTop: "5px",
                        marginBottom: "15px",
                        input: {
                          padding: "10px 15px",
                        },
                      }}
                      name="direccion"
                      placeholder={t("event.address")}
                      onClick={handleInputClick}
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                      required
                    />
                  </FormControl>
                </div>
                <>
                  {error.direccion && (
                    <Alert severity="error">{error.direccion}</Alert>
                  )}
                </>
                <div className="d-flex justify-content-center mt-3">
                  <iframe
                    title={`Mapa de ${direccion}, ${ciudadSeleccionada}`}
                    className="rounded shadow"
                    width="100%"
                    height="230"
                    id="map"
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={mapUrl}
                  ></iframe>
                </div>
              </FormControl>
            </div>
          )}
        </div>

        {/* SECCION MAS DETALLES */}

        <div
          ref={(el) => (refSeccion.current["detail"] = el)}
          className={`seccion-evento ${expandedSection === "detail" ? "expanded" : ""
            }`}
          onClick={() => handleSectionClick("detail")}
        >
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p
              className={`titulo-seccion-evento ${expandedSection === "detail" ? "hidden" : ""
                }`}
            >
              {t("event.details")}
            </p>
            {error.eventos_tipos_id && (
              <ErrorOutlineOutlinedIcon
                sx={{
                  color: "red",
                  display: expandedSection === "detail" ? "none" : "block",
                }}
              />
            )}
          </div>
          <p
            className={`descrip-seccion-evento ${expandedSection === "detail" ? "hidden" : ""
              }`}
          >
            {t("event.detailsDescrip")}
          </p>

          {expandedSection === "detail" && (
            <div className="contenido-evento">
              <FormControl onClick={handleInputClick}>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="titulo-seccion-evento-expanded">
                    Detalles adicionales
                  </p>
                  <button
                    type="button"
                    onClick={() => handleSectionClick("detail")}
                    className="d-flex justify-content-end"
                  >
                    <CloseOutlinedIcon sx={{ color: "#3659E3" }} />
                  </button>
                </div>

                <p className="subtitulo-seccion-evento-expanded">
                  Tipo de evento <span className="text-danger">*</span>
                </p>
                <p className="descrip-seccion-evento-expanded">
                  Define la naturaleza del evento. Selecciona entre estas
                  opciones la que mejor se asemeje a tu evento.
                </p>

                {tipos?.length > 0 ? (
                  <div className="row">
                    {tipos.map((tipo) => (
                      <div key={tipo.id} className="col-12 col-md-6 mb-3">
                        <button
                          id="eventos_tipos_id"
                          name="eventos_tipos_id"
                          type="button"
                          value={eventos_tipos_id}
                          onClick={() => handleTipoSelect(tipo.id)}
                          /* onChange={(e) =>
                            setEventotipo(parseInt(e.target.value))
                          } */
                          className={`w-100 boton-tipo ${tipoSelected === tipo.id ? "boton-tipo-active" : ""
                            }`}
                        >
                          <span className="d-flex align-items-center">
                            {categoriaIcons[tipo.id]}
                            {tipo.evento_tipo}
                          </span>
                          <span className="indicator-wrapper">
                            <span className="indicator-fuera"></span>
                            <span className="indicator-dentro"></span>
                          </span>
                        </button>
                      </div>
                    ))}
                    {error.eventos_tipos_id && (
                      <div className="col-12">
                        <Alert severity="error" className="mt-2">
                          {error.eventos_tipos_id}
                        </Alert>
                      </div>
                    )}
                  </div>
                ) : (
                  <span color="error">Error..</span>
                )}
              </FormControl>
            </div>
          )}
        </div>
        {error && (
          <Alert severity="error">
            Por favor, chequea la información ingresada.
          </Alert>
        )}
          {submitted && (
            <Alert color="success">{t("event.successCreate")}</Alert>
          )}
        <div className="btn-form-edit">
          <Button
            sx={{
              color: "red",
              border: "1px solid red",
              padding: "5px 15px",
            }}
            type="button"
            className="mt-3 mr-3 d-inline-flex "
            onClick={() => setShowEliminar(true)}
          >
            {t("event.deleteEvent")}
          </Button>{" "}
          <Button
            type="submit"
            form="edit-evento"
            className="mt-3 boton-submit"
          >
            Editar evento
          </Button>{" "}
        </div>
      </form>

      {showEliminar && (
        <form id="form-delete" className="d-flex justify-content-center">
          <div className="seccion-evento">
            <p className="subtitulo-seccion-evento-expanded">
              {t("event.deleteEvent")}
            </p>
            <div className="contenido-evento">
              <FormControl>
                <p
                  className="txt-open-card"
                  style={{
                    userSelect: "none",
                    msUserSelect: "none",
                    WebkitUserSelect: "none",
                  }}
                >
                  {t("event.deleteEvent-p1")} "<b>{evento}</b>"{" "}
                  {t("event.deleteEvent-p2")}
                </p>

                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    input: {
                      padding: "10px 15px",
                    },
                  }}
                  type="text"
                  name="inputNombreEvento"
                  onChange={(e) => setInputNombreEvento(e.target.value)}
                  value={inputNombreEvento}
                />
              </FormControl>
              {errorEliminar && (
                <div className="mt-3">
                  <Alert severity="error">{t("event.deleteError")}</Alert>
                </div>
              )}
              {enviado && (
                <div className="mt-3">
                  <Alert color="success">{t("event.successAlert")}</Alert>
                </div>
              )}
              {errorValidacion && (
                <div className="mt-3">
                  <Alert severity="error">
                    <h5 className="alert-heading">Atencion</h5>
                    <p>{t("event.deleteValidationError")}</p>
                  </Alert>
                </div>
              )}
              <Button
                sx={{
                  color: "red",
                  border: "1px solid red",
                  padding: "5px 15px",
                }}
                type="button"
                form="form-delete"
                className="mt-3 mr-3 d-inline-flex "
                onClick={handleEliminarEvento}
              >
                {t("event.deleteEvent")}
              </Button>{" "}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditEvent;
