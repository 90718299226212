import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LikeButton from "../../../../components/Like/LikeButton";
import { Button, ButtonGroup, Breadcrumbs, Typography, Link, Accordion, AccordionSummary, AccordionDetails, Drawer, CircularProgress, IconButton, Tooltip } from "@mui/material";
import "./eventopublic.css";
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useState } from 'react';
import ShareButton from '../../../../components/shareButton/shareButton';
import { format } from 'date-fns';
import moment from 'moment';
import { es } from 'date-fns/locale';
import useCarrito from '../../../../hooks/hookCarrito';
import { toast } from 'react-toastify';

//escapado de caracteres para el wysiwyg
const sanitizeHtml = (html) => {
  // crea un div temporal
  const div = document.createElement("div");
  // guarda el html en el div
  div.innerHTML = html;

  // filtra las etiquetas permitidas
  const allowedTags = [
    "ol",
    "ul",
    "li",
    "p",
    "strong",
    "em",
    "br",
    "h1",
    "h2",
    "u",
  ]; // etiquetas permitidas
  Array.from(div.querySelectorAll("*")).forEach((node) => {
    if (!allowedTags.includes(node.nodeName.toLowerCase())) {
        node.parentNode.removeChild(node); // elimina los que no permite
    }
  });

  return div.innerHTML; // devuelve el html seguro
};

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    color: 'white', // Change this to the color you want
  },
}));


function EventoPublicMobile({ data, map }) {
  const param = useParams();
  const [open, setOpen] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const { crearCarrito } = useCarrito();
  const [loadingCarrito, setLoading] = useState(false);

  const initialCantidad = data.tarifas.reduce((acc, tarifa) => {
    acc[tarifa.id] = 0;
    return acc;
  }, {});
  
  const [cantidad, setCantidad] = useState(initialCantidad);

  const tarifasSeleccionadas = data?.tarifas?.filter((tarifa) => cantidad[tarifa.id] > 0);

  const preguntas = [
    {pregunta: "¿Cómo puedo comprar entradas para un evento?", respuesta: `Para comprar entradas, visita la página del evento en nuestra plataforma. Elige la categoría de entrada (como General, VIP, etc.), selecciona la cantidad que deseas comprar y haz clic en "Comprar". A continuación, serás redirigido al proceso de pago, donde podrás ingresar tus datos y completar la compra.`},
    {pregunta: "¿Qué métodos de pago están disponibles para comprar entradas?", respuesta: "Nuestra plataforma acepta diversos métodos de pago, como tarjetas de crédito, débito, PayPal, y transferencias bancarias. Al finalizar tu compra, podrás seleccionar el método que prefieras en la pantalla de pago."},
    {pregunta: "¿Cómo recibo mis entradas después de comprarlas?", respuesta: `Una vez completada la compra, recibirás tus entradas por correo electrónico en formato digital, con un código QR que se escaneará en el evento. También puedes acceder a tus entradas desde tu cuenta en nuestra plataforma, en la sección "Mis Entradas".`},
    {pregunta: "¿Es posible cambiar o devolver una entrada después de haberla comprado?", respuesta: "Las políticas de devolución y cambios dependen del organizador del evento. Revisa los términos del evento antes de completar la compra. Si el organizador permite devoluciones o cambios, podrás solicitarlo a través de nuestra plataforma o contactando directamente con el soporte del evento."},
    {pregunta: "¿Cómo puedo saber si mi compra ha sido exitosa?", respuesta: `Después de completar la compra, recibirás un correo electrónico de confirmación con los detalles de tu compra y tus entradas adjuntas. Si no recibes este correo en unos minutos, revisa tu carpeta de spam o accede a la sección "Mis Entradas" en tu cuenta para verificar la compra.`},
  ]

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    setShowResume(false);
  };

  const incremento = (idtarifa) => {
    setCantidad((prevCantidades) => {
      const nuevaCantidad = (prevCantidades[idtarifa] || 0) + 1;

      // Verificar si la cantidad supera el límite permitido
      const limite = data?.tarifas?.find(
        (tarifa) => tarifa.id === idtarifa
      )?.limite_persona;

      if (nuevaCantidad > parseInt(limite)) {
        return prevCantidades; // No actualizamos si se supera el límite
      }

      return {
        ...prevCantidades,
        [idtarifa]: nuevaCantidad,
      };
    });
  };

  const decremento = (idtarifa) => {
    setCantidad((prevCantidades) => ({
      ...prevCantidades,
      [idtarifa]: Math.max(0, (prevCantidades[idtarifa] || 0) - 1),
    }));
  };

  const handleAñadirCarrito = async () => {
    setLoading(true);

    const idevento = parseInt(param.id);

    const items = Object.entries(cantidad)
      .map(([idtarifa, cantidad]) => ({
        idtarifa: parseInt(idtarifa),
        cantidad,
      }))
      .filter((item) => item.cantidad > 0); // Filtramos para solo incluir items con cantidad mayor a 0

    if (items.length === 0) {
        toast.info("Debes seleccionar al menos un item para añadir al carrito.", {
        position: "top-right",
        autoClose: 4000,
      });
      setLoading(false);
      return;
    }

    await crearCarrito(idevento, items);

    setCantidad(initialCantidad);

    setLoading(false);
  };

  const calculoSubtotalTarifa = (tarifa) => {
    const cantidadSeleccionada = cantidad[tarifa.id] || 0; // Obtiene la cantidad, o 0 si no hay
    return cantidadSeleccionada * tarifa.precio; // Multiplica cantidad por precio
  };

  const calcularTotal = () => {
    return tarifasSeleccionadas.reduce((total, tarifa) => {
      return total + calculoSubtotalTarifa(tarifa);
    }, 0);
  };
  

  return (
    <>
      {/*PORTADA*/}
      <div className="header-public justify-content-start" style={{ backgroundImage: `url(${data.attachs[0].url})` }}>
        <span className="overlay" />
        <div className="breadcrumb-public m-0">
          <StyledBreadcrumbs aria-label="breadcrumb" separator="›">
            <Link className="texto-evento" sx={{ color: "white", fontSize: "18px" }} underline="hover" href="/">
              Inicio
            </Link>
            <Link
              className="texto-evento"
              underline="hover"
              sx={{ color: "white", fontSize: "18px" }}
              href="/"
            >
              {data.evento_tipo.evento_tipo}
            </Link>
            <Typography className="texto-evento" sx={{ color: "white", fontSize: "18px" }}>
            {data.evento}
            </Typography>
          </StyledBreadcrumbs>
        </div>
      </div>
      
      <div className="p-4 d-flex flex-column gap-4">
        {/*WIDGETS*/}
        <div className="d-flex justify-content-between">
          <p className="p-2 d-flex gap-2 rounded-lg my-auto" style={{color: '#3d2eab', backgroundColor: '#dadaff'}}>
            <NotificationsActiveOutlinedIcon />
            Reciente apertura
          </p>
          <div className="d-flex align-items-center">
            <LikeButton />
            <ShareButton idEvento={param.id} style={{color: '#221a60'}} />
          </div>
        </div>

        {/*TITULO*/}
        <h2 className="fs-1 fw-bold m-0" style={{color: '#221a60'}}>
          {data.evento}
        </h2>

        {/*UBICACION Y FECHA*/}
        <div className="d-flex flex-wrap gap-4">
          <div className="mr-5">
            <h4 className="fs-3" style={{color: '#221a60'}}>
              Fecha
            </h4>
            <p className="mb-2 d-flex gap-2">
              <CalendarMonthIcon /> {format(new Date(data.fecha), "d 'de' MMMM", { locale: es, })}
            </p>
            <p className="m-0 d-flex gap-2">
              <AccessAlarmIcon /> {moment.parseZone(data.hora).format("HH:mm")} hs.
            </p>
          </div>

          <div className="mr-5">
            <h4 className="fs-3" style={{color: '#221a60'}}>
              Ubicación
            </h4>
                
            <div className="d-flex gap-2">
              <LocationOnOutlinedIcon />
              <div>
                <p className="m-0 fw-bold">
                  {data.direccion}
                </p>
                <p className="my-2 text-secondary">
                  {data.ciudad}
                </p>
                <span onClick={() => setShowMap(!showMap)} style={{color: '#5656ff', cursor: 'pointer'}}>
                  Mostrar mapa {!showMap ? <ExpandMoreIcon fontSize='small' /> : <ExpandLessIcon fontSize='small' />}
                </span>
                
              </div>
            </div>
          </div>

          {/* Mapa */}
          {showMap && (
            <div className="w-100">
              <iframe
                title="location"
                width="100%"
                height="250"
                id="map"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={map}
                style={{ borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
              />
            </div>
          )}
        </div>

        {/*ORGANIZACION*/}
        <div>
          <h4 className="fs-3" style={{color: '#221a60'}}>
            Organizado por
          </h4>
          <p className="m-0 d-flex gap-2">
            <PersonOutlineIcon /> Owner {data.ownerid}
          </p>
        </div>

        {/*DESCRIPCION*/}
        <div>
          <h4 className="fs-3" style={{color: '#221a60'}}>
            Acerca del evento
          </h4>
          <span
            className="descripcion-unstyled"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(data?.descripcion) }}
          />
        </div>

        {/*PREGUNTAS FRECUENTES*/}
        <div>
          <h5 className="fs-3" style={{color: '#221a60'}}>
            Preguntas frecuentes
          </h5>
          {preguntas?.map((p, i) => (
            <Accordion
              key={i}
              defaultExpanded={i === 2 ? true : false}
              style={{backgroundColor: '#f9f9f9'}}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${i}-content`}
                id={`panel${i}-header`}
              >
                <p className="m-0 fw-medium">{p.pregunta}</p>
              </AccordionSummary>

              <AccordionDetails as="p" className="m-0">
                {p.respuesta}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>

      {/*DRAWER*/}
      <div className="p-4 rounded-top container-btn-compra">
        <Button
          id="btn-compra"
          onClick={toggleDrawer(true)}
          className="p-4 d-flex gap-1 rounded"
          aria-label='ver-tarifas'
        >
          <ShoppingCartIcon /> Ver tarifas
        </Button>
      </div>

      <Drawer
        sx={{'& .MuiDrawer-paper': {
          backgroundColor: '#f8f8ff',
          borderRadius: '10px 10px 0 0',
        }}}
        transitionDuration={500}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <IconButton
          sx={{position: 'absolute', right: '0'}}
          onClick={toggleDrawer(false)}
          aria-label="close"
          size="small"
        >
          <CloseIcon style={{color: '#221a60'}} />
        </IconButton>

        <div className="p-4 d-flex flex-column justify-content-between gap-2" style={!showResume ? {minHeight: '30vh'} : {minHeight: '100vh'}}>
          {!showResume ? (
            <div>
              <div className="text-center mb-2">
                <p className="m-0 fs-3 fw-bold" style={{color: '#221a60'}}>
                  {data.evento}
                </p>
                <small>
                  {format(new Date(data.fecha), "d 'de' MMMM ", { locale: es, })}
                  {moment.parseZone(data.hora).format("HH:mm")} hs.
                </small>
              </div>

              <p className="mb-1 fw-medium">
                Tarifas
              </p>

              {data.tarifas ? (
                data.tarifas.map((tarifa) => (
                  <div
                    key={tarifa.id}
                    className="position-relative my-3"
                  >
                    <div className="barra-tarifas" />

                    <div className="ps-4">
                      <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                        <div>
                          <p className="fw-medium m-0">
                            {tarifa.concepto}
                          </p>
                          <small className="text-secondary">
                            {tarifa.idtipo === 1 ? 'Tickets' : 'Vouchers'}{" "}
                            restantes: {tarifa.restantes}
                          </small>
                        </div>

                        <ButtonGroup>
                          <Button
                            className="fw-bold"
                            outline
                            disabled={cantidad[tarifa.id] === 0}
                            onClick={() => decremento(tarifa.id)}
                            sx={{ width: "40px", color: '#221a60', borderColor: '#221a60' }}
                            aria-label='restar'
                          >
                            -
                          </Button>
                          <Button
                            className="fw-bold"
                            outline
                            disabled
                            style={{
                              width: "40px",
                              color: "#221a60",
                              borderColor: '#221a60',
                              cursor: "default",
                            }}
                          >
                            {cantidad[tarifa.id] || 0}
                          </Button>
                          <Button
                            className="fw-bold"
                            outline
                            onClick={() => incremento(tarifa.id)}
                            sx={{ width: "40px", color: '#221a60', borderColor: '#221a60' }}
                            aria-label='sumar'
                          >
                            +
                          </Button>
                        </ButtonGroup>
                      </div>

                      <div>
                        <p className="fw-medium m-0 d-inline-block">
                          $ {tarifa.precio}
                        </p>
                        <small className="text-secondary"> + 5% Costo del servicio</small>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center">
                  Aún no hay tarifas.
                </p>
              )}
            </div>
          ) : (
            <div>
              <p className="mb-1 fw-bold fs-4">
                Resumen de tu compra
              </p>
              {tarifasSeleccionadas?.length > 0 ? (
                tarifasSeleccionadas.map((tarifa) => (
                  <div
                    key={tarifa.id}
                    className="my-3"
                  >
                    <div className="d-flex justify-content-between flex-wrap py-2">
                      <p className="fw-medium mb-0">{`${cantidad[tarifa.id]} x ${tarifa.concepto}`}</p>
                      <p className="fw-medium mb-0">$ {calculoSubtotalTarifa(tarifa)}</p>
                    </div>
                    <hr className="mx-3 border-2" style={{color: '#D9D9D9'}} />
                  </div>
                ))
              ) : (
                <p className="text-center">
                  No hay tarifas seleccionadas.
                </p>
              )}
              
              <div className="d-flex justify-content-between flex-wrap py-2">
                <p className="fw-medium mb-0 fs-5">Subtotal</p>
                <p className="fw-medium mb-0 fs-5">$ {calcularTotal()}</p>
              </div>

              <div className="d-flex justify-content-between flex-wrap py-2">
                <div className="d-flex align-items-center gap-1">
                  <p className="fw-medium mb-0 fs-5">Costo</p>
                  <Tooltip title="El costo de servicio de PassGo no es reembolsable" placement="right" arrow>
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
                <p className="fw-medium mb-0 fs-5">$ 0.00</p>
              </div>
              <hr className="mx-3 border-2" style={{color: '#D9D9D9'}} />

              <div className="d-flex justify-content-between flex-wrap py-2">
                <p className="fw-medium mb-0 fs-3">Total</p>
                <p className="fw-medium mb-0 fs-3">$ {calcularTotal()}</p>
              </div>
            </div>
          )}

          {data?.tarifas.length > 0 &&
            <div className="d-flex flex-column">
              <p className="mb-2 fs-3 fw-medium align-self-end"
                onClick={() => setShowResume(!showResume)}
                style={{cursor: 'pointer'}}
              >
                {!showResume ? <ExpandLessIcon /> : <ExpandMoreIcon />} $ {calcularTotal()}
              </p>
              <Button
                id="btn-compra"
                onClick={handleAñadirCarrito}
                className="p-4 rounded d-flex gap-1"
                aria-label='iniciar-compra'
              >
                {loadingCarrito
                  ? <CircularProgress />
                  : <span><ShoppingCartIcon /> Iniciar compra</span>
                }
              </Button>
            </div>
          }
        </div>
      </Drawer>
    </>
  );
}

export default EventoPublicMobile