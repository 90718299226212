import React, { useEffect, useState, useRef } from "react";
import CardPublicacion from "./CardPublicacion";
import { useTranslation } from "react-i18next";
import "./adsmobile.css";
import { TextField } from "@mui/material";

import { Calendar } from "react-multi-date-picker"
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import "react-multi-date-picker/styles/layouts/mobile.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MicExternalOnOutlinedIcon from "@mui/icons-material/MicExternalOnOutlined";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import SportsBasketballOutlinedIcon from "@mui/icons-material/SportsBasketballOutlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

const categorias = [
  "Música",
  "Fiestas",
  "Deportes",
  "Beneficencia",
  "Gastronomía",
  "Exposiciones",
];

const categoriaIconsMobile = {
  Fiestas: <NightlifeOutlinedIcon className="icon-category-mobile" />,
  Música: <MicExternalOnOutlinedIcon className="icon-category-mobile" />,
  Beneficencia: (
    <VolunteerActivismOutlinedIcon className="icon-category-mobile" />
  ),
  Deportes: <SportsBasketballOutlinedIcon className="icon-category-mobile" />,
  Gastronomía: <FastfoodOutlinedIcon className="icon-category-mobile" />,
  Exposiciones: <BusinessCenterOutlinedIcon className="icon-category-mobile" />,
};

const translations = {
  Música: {
    es: "Música",
    en: "Music",
  },
  Fiestas: {
    es: "Fiestas",
    en: "Parties",
  },
  Deportes: {
    es: "Deportivos",
    en: "Sports",
  },
  Beneficencia: {
    es: "Beneficios",
    en: "Profits",
  },
  Gastronomía: {
    es: "Gastronomía",
    en: "Food",
  },
  Exposiciones: {
    es: "Exposiciones",
    en: "Exhibitions",
  },
};

const translateCategory = (category, language) => {
  return translations[category] ? translations[category][language] : category;
};

const AdsMobile = ({
  usuario,
  publicaciones,
  filteredPublicaciones,
  setFilteredPublicaciones,
  handleFechaEventoChange,
  ciudadSeleccionada,
  setCiudadSeleccionada,
  ciudades,
}) => {
  const [publicacionesConFechaEvento, setPublicacionesConFechaEvento] =
    useState({});
  const [filtrosAplicados, setFiltrosAplicados] = useState(false);
  const { i18n, t } = useTranslation("global");
  const currentLanguage = i18n.language;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [showFiltroFecha, setShowFiltroFecha] = useState(false);
  const [showRegion, setShowRegion] = useState(false);
  const sliderRef = useRef(null);

  const months = [
    ["Enero", "En"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "Ma"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sept"],
    ["Octubre", "Oct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dic"],
  ];

  const weekDays = [
    ["Domingo", "Do"],
    ["Lunes", "Lu"],
    ["Martes", "Ma"],
    ["Miercoles", "Mi"],
    ["Jueves", "Ju"],
    ["Viernes", "Vi"],
    ["Sabado", "Sa"],
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategory((prev) => (prev === category ? "" : category));
    applyAutoFilters();
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    applyAutoFilters();
  };

  const handleFecha = (dates) => {
    setDateRange(dates);
  };

  const applyAutoFilters = () => {
    let filtered = publicaciones;

    if (selectedCategory) {
      filtered = filtered.filter(
        (pub) => pub.evento.evento_tipo.evento_tipo === selectedCategory
      );
    }

    if (searchText) {
      filtered = filtered.filter((pub) =>
        pub.titulo.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilteredPublicaciones(filtered);
    setFiltrosAplicados(selectedCategory !== "" || searchText !== "");
  };

  const applyAllFilters = () => {
    let filtered = publicaciones;

    if (selectedCategory) {
      filtered = filtered.filter(
        (pub) => pub.evento.evento_tipo.evento_tipo === selectedCategory
      );
    }

    if (searchText) {
      filtered = filtered.filter((pub) =>
        pub.titulo.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    const [startDate, endDate] = dateRange;
    if (startDate && endDate) {
      filtered = filtered.filter((pub) => {
        const fechaEvento =
          publicacionesConFechaEvento[pub.id] ||
          handleFechaEventoChange(pub.id, pub.evento.fecha);
        if (!fechaEvento) return false;
        return (
          fechaEvento >= startDate.toDate() && fechaEvento <= endDate.toDate()
        );
      });
    }

    if (ciudadSeleccionada) {
      filtered = filtered.filter(
        (pub) => pub.evento.ciudad === ciudadSeleccionada
      );
    }

    setFilteredPublicaciones(filtered);
    setFiltrosAplicados(true);
  };

  const clearFiltros = () => {
    setSelectedCategory("");
    setSearchText("");
    setDateRange([]);
    setCiudadSeleccionada("");
    setPublicacionesConFechaEvento({});
    setFilteredPublicaciones(publicaciones);
    setFiltrosAplicados(false);
  };

  const handleClearFiltros = () => {
    clearFiltros();
  };

  const toggleFecha = () => {
    setShowFiltroFecha(!showFiltroFecha);
  };

  const toggleRegion = () => {
    setShowRegion(!showRegion);
  };


  useEffect(() => {
    applyAutoFilters();
  }, [selectedCategory, searchText]);

  const settings = {
    infinite: true,
    centerMode: true,
    centerPadding: "8px",
    slidesToShow: 3, 
    slidesToScroll: 1,
    className: "category-slider",
    arrows: false,
    speed: 300,
    cssEase: "ease-out",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          centerPadding: "8px", 
          speed: 250
        }
      }
    ]
  };

  const handleClickCategory = (category, index) => {
    sliderRef.current.slickGoTo(index);
    handleCategoryChange(category);
  };

  return (
    <>
      <div className="boton-filtros-mobile px-2">
        <div className="d-flex flex-row">
          <TextField
            sx={{
              width: "100%",
              borderRight: "none",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px 0 0 10px",
              },
              input: {
                "::placeholder": {
                  fontFamily: "Inter",
                },
              },
            }}
            placeholder={t("home.filterText")}
            value={searchText}
            onChange={handleSearchChange}
            className="input-search"
          />
          <button
            type="button"
            className="boton-region-filtro"
            onClick={toggleRegion}
            style={{}}
          >
            {showRegion ? <ClearOutlinedIcon /> : <PinDropOutlinedIcon />}
          </button>
          <button
            type="button"
            className="boton-fecha-filtro"
            onClick={toggleFecha}
            style={{}}>
            {showFiltroFecha ? <ClearOutlinedIcon /> : <EventOutlinedIcon />}
          </button>

          {!filtrosAplicados ? (
            <button
              type="button"
              className="boton-buscar-filtros"
              onClick={applyAllFilters}
              style={{ borderRadius: "0 10px 10px 0", fontSize: "18px" }}
            >
              <SearchOutlinedIcon />
            </button>

          ) : (
            <button
              type="button"
              className="boton-buscar-filtros"
              onClick={handleClearFiltros}
              style={{ borderRadius: "0 10px 10px 0", fontSize: "18px" }}
            >
              <ClearOutlinedIcon />
            </button>
          )}

        </div>
        <div className="contenedor-filtros-mobile">
          {showRegion && (
            <div className="d-flex flex-column mt-3 mb-2">
              <p className="mb-0 fw-bold">Filtrar por ciudad</p>
              <select
                className="input-region-mobile"
                value={ciudadSeleccionada}
                onChange={(event) => {
                  setCiudadSeleccionada(event.target.value);
                }}
              >
                {<option value="" hidden />}
                {ciudades.map((ciudad) => (
                  <option key={ciudad} value={ciudad}>
                    {ciudad}
                  </option>
                ))}
              </select>
            </div>
          )}
          {showFiltroFecha && (
            <div className="d-flex flex-column align-items-center mt-3 mb-2">
              <p className="mb-2 mr-auto fw-bold">Filtrar por fecha</p>
              <Calendar
                months={months}
                weekDays={weekDays}
                range
                value={dateRange}
                onChange={handleFecha}
                format={`DD MMMM YYYY`}
                numberOfMonths={1}
                rangeColors={["#0A58C2"]}
                dateSeparator=" | "
                className="fs-6 rmdp-mobile"
                plugins={[
                  <Toolbar
                    position="bottom"
                    names={{
                      today: "Hoy",
                      deselect: "Limpiar",
                      close: "Cerrar",
                    }}
                  />,
                ]}
              />
            </div>
          )}

          <div className="mt-4 category-slider-container">
            <Slider ref={sliderRef} {...settings}>
              {categorias.map((category, index) => (
                <div key={category} className="category-slide">
                  <button
                    className={`button-ads-mobile ${selectedCategory === category ? "active" : ""}`}
                    onClick={() => handleClickCategory(category, index)}
                  >
                    <div className="button-content">
                      <span className="icon-category-mobile">{categoriaIconsMobile[category]}</span>
                      <p className="text-btn-category">
                        {translateCategory(category, currentLanguage)}
                      </p>
                    </div>
                  </button>
                </div>
              ))}
            </Slider>
          </div>

        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-evenly gap-3 pt-2 pb-4">
        {filteredPublicaciones.length > 0 ? (
          filteredPublicaciones.map((pub) => (
            <CardPublicacion
              usuario={usuario}
              key={pub.id}
              className="ads-detail-mobile"
              publicacion={pub}
            />
          ))
        ) : (
          <p>{t("publications.publicationsAlert")}</p>
        )}
      </div>
    </>
  );
};

export default AdsMobile;
