import React, { useEffect, useState } from "react";
import {Breadcrumb,BreadcrumbItem,Form,Input,Label,Button,Container, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import { api } from "../../services/api";
import Loading from "../../components/Loading";
import Inscripcion from "./Inscripcion";

import { useAuth0 } from "@auth0/auth0-react";


export const Compra = () => {
  // Resupuesta ordenada de la API
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });

  const history = useHistory();

  // Aca es donde seteariamos los eventos
  const [eventos, setEventos] = useState([]);
  const [categorias, setCategorias] = useState();
  const [selectedEvent, setSelectedEvent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Iniciamos el form
  const initForm = {
    mail: '',
    cantidad: 1,
    idcategoria: 0,
  }

  // Aca seteariamos los campos del formulario
  const [formData, setFormData] = useState(initForm);
  const [formValid, setFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleSelectedEvent = (event) => {
    const idEvento = event.target.value;
    const eventoSeleccionado = eventos.find((evento) => evento.id === parseInt(idEvento));

    getEventCategories(eventoSeleccionado.id);
    setSelectedEvent(eventoSeleccionado);
  }

  const getEventCategories = async (idEvento) => {
    const response = await api.categorias.getAll(idEvento);
    setCategorias(response.data.categorias);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  } 

  const confirmSubmit = async () => {
    setIsModalOpen(false);
    setIsLoading(true);

    // Pasar los strings a int
    const convertedFormData = {
      ...formData,
      idcategoria: parseInt(formData.idcategoria),
      cantidad: parseInt(formData.cantidad),
    };

    try {
      // Enviar los datos del formulario al backend
      const response = api.solicitudes.createSolicitud(selectedEvent.id, convertedFormData);
      
      setTimeout(() => {
        setFormData(initForm);
        setFormSubmitted(true);
        setIsLoading(false);
      }, 1000);

    } catch (error) {
      setState({
        ...state,
        apiMessage: "Error al enviar la solicitud",
      });
    }
  }

  useEffect(() => {
    // Obtener la lista de publicaciones activas desde la API
    const callApi = async () => {
      try {
        const response = await api.eventos.getAllPublic();
        console.log(response);
        if (response.status === "success") {
          setEventos(response.data.Eventos);
        }

      } catch (error) {
        setState({
          ...state,
          error: error.error
        });
      }
    }

    callApi();
  }, [state]);

  useEffect(() => {
    const { mail, cantidad, idcategoria  } = formData;
    
    // Validacion del formulario
    setFormValid(mail && cantidad && idcategoria);
  }, [formData]);

  // Usuario activo - Current user
  const { user, isAuthenticated } = useAuth0();

  return (
    <>
      <Breadcrumb listTag="div">
        <BreadcrumbItem href="" onClick={() => history.push("/")} tag="a">
          Home
        </BreadcrumbItem>

        <BreadcrumbItem href="" onClick={() => history.push("/encuentro/comprar")} tag="a">
          Evento
        </BreadcrumbItem>

        <BreadcrumbItem active tag="span">
          Solicitud de compra
        </BreadcrumbItem>
      </Breadcrumb>

      {isLoading ? (
        <Loading/>

      ) : formSubmitted ? (
        <Alert color="success" className="container my-5">
          Solicitud enviada exitosamente. Por favor, revise su correo electrónico para confirmar.
        </Alert>

      ) : (
        <Container className="col-12 col-xl-6">
          <p className="text-center fw-bold fs-4 m-0">Datos del evento</p>

          <div className="mb-5">
            <Label for="evento" className="fs-4 fw-bold">
              Evento:
            </Label>

            <Input
              id="evento"
              type="select"
              className="fs-5"
              required
              onChange={handleSelectedEvent}
              >
              <option defaultChecked hidden>
                Seleccionar el evento
              </option>

              {!user ? <option disabled>Primero debe iniciar sesión</option> :
              eventos?.map((evento) => (
                <option key={evento.id} value={evento.id}>
                  {evento.evento}
                </option>
              ))}
            </Input>
          </div>

          <div className="mb-5">
            <p className="fs-4 m-0">
              <strong>Descripción: </strong>{selectedEvent.descripcion}
            </p>

            <small className="text-secondary fw-bold">
              Fecha límite de compra: {selectedEvent?.fecha && new Date(selectedEvent.fecha).toLocaleString('es-AR')}
            </small>
          </div>

          <Form onSubmit={handleSubmit} className="d-flex flex-column gap-5">
            <div className="d-flex gap-3">
              <div className="flex-column w-50">
                <Label for="evento" className="fs-4 fw-bold">
                  Categoría:
                </Label>

                <Input
                  id="categoria"
                  name="idcategoria"
                  type="select"
                  className="fs-5"
                  value={formData.idcategoria}
                  onChange={handleChange}
                  required
                  >
                  <option defaultChecked hidden>
                    Seleccionar una categoría
                  </option>

                  {categorias ? (categorias.map((categoria) => (
                    <option key={categoria.id} value={categoria.id}>
                      {categoria.categoria}
                    </option>
                  ))
                  ) : (
                    <option disabled>Primero debe seleccionar un evento</option>
                  )}
                </Input>
              </div>

              <div className="w-50">
                <Label for="cantidad" className="fs-4 fw-bold">
                  Entradas: 
                </Label>

                <Input
                  id="cantidad"
                  name="cantidad"
                  className="fs-5"
                  placeholder="Cantidad"
                  type="number"
                  min={1}
                  value={formData.cantidad}    
                  onChange={handleChange}
                  required
                  />
              </div>
            </div>

            <div>
              <Label for="exampleEmail" className="fs-4 fw-bold">
                Email
              </Label>

              <Input
                id="exampleEmail"
                name="mail"
                className="fs-5"
                placeholder="example@gmail.com"
                type="email"
                value={formData.mail}  
                onChange={handleChange}
                required
                />
            </div>

            <Inscripcion />
            
            <Button type="submit" color={formValid ? 'primary' : 'secondary'} onClick={handleSubmit} disabled={!formValid}>
              Solicitar Compra
            </Button>
          </Form>
        </Container>
      )}
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} centered>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
          Confirmar solicitud
        </ModalHeader>

        <ModalBody>
          ¿Estás seguro de que deseas enviar esta solicitud de compra?
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={confirmSubmit}>
            Confirmar
          </Button>

          <Button color="secondary" onClick={() => setIsModalOpen(!isModalOpen)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
};


export default Compra;