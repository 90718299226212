import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation, Switch, Route, Redirect} from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { toast, Bounce  } from 'react-toastify';
import { api } from '../services/api';
import { useMediaQuery } from "react-responsive";


import CrearEvento from '../views/eventos/evento/CrearEvento';
import { SegundoPaso } from '../views/eventos/evento/SegundoPaso';
import { AgregarColaboradores } from '../views/eventos/evento/AgregarColaboradores';
import { PublicarEvento } from '../views/eventos/evento/PublicarEvento';

const steps = [
    'Información',
    'Tarifas',
    'Colaboradores',
    'Publicar'
];

export const StepperCrearEvento = ({ eventoIdInicial }) => {
    const history = useHistory();
    const location = useLocation();
   // const [activeStep, setActiveStep] = useState(getActiveStep(location.pathname));
    //const [eventId, setEventId] = useState(null);
    const [eventoCreado, setEventoCreado] = useState(null);
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const [eventId, setEventId] = useState(eventoIdInicial || null); 
    

    function getActiveStep(path) {
        switch (path) {
            case '/crearEvento/creacion':
                return 0;
            case '/crearEvento/segundoPaso':
                return 1;
            case '/crearEvento/agregarColaboradores':
                return 2;
            case '/crearEvento/publicarEvento':
                return 3;
        }
    }
    useEffect(() => {
        if (!eventId) {
          const storedEventId = localStorage.getItem('eventId');
          if (storedEventId) {
            setEventId(storedEventId); 
          }
        }
      }, [eventId]);

    useEffect(() => {
        setActiveStep(getActiveStep(location.pathname));
        const { state } = location;
    if (state && state.eventId) {
        setEventId(state.eventId);
        localStorage.setItem('eventId', state.eventId); 
    } else {
        const storedEventId = localStorage.getItem('eventId'); 
        if (storedEventId) {
            setEventId(storedEventId);
        }
    }
    }, [location]);

    const [activeStep, setActiveStep] = useState(eventId ? 1 : 0);  

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {

            try {
                const response = await api.eventos.confirmEvento(eventId);
                if (response.status === "success") {
                    toast.success('Tu evento se ha creado exitosamente', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                    setTimeout(() => {
                        history.push(`/eventos/${eventId}`);
                      }, 2000);
                }
            }catch (error) {
            console.error("Error uploading files:", error);
            }
        }
        else {
            setActiveStep((prevActiveStep) => {
                const nextStep = prevActiveStep + 1;

                switch (nextStep) {
                    case 0:
                        history.push('/crearEvento/creacion');
                        break;
                    case 1:
                        history.push('/crearEvento/segundoPaso');
                        break;
                    case 2:
                        history.push('/crearEvento/agregarColaboradores');
                        break;
                    case 3:
                        history.push('/crearEvento/publicarEvento');
                        break;
                    default:
                        break;
                }
                return nextStep;
            });
        }
    }

    
    return (
        <>
            {isMobile ? (
                <Container className="my-3" maxWidth="lg">
                    <div>
                        <h3
                            style={{color: "#001D67"}}
                        >
                            Crear evento &gt; <span style={{fontWeight: 700}}>{steps[activeStep]}</span></h3>
                    </div>
                    <div style={{ textAlign: "center"}}>
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel disabled={index < activeStep}
                                    StepIconProps={{
                                        sx: {
                                            '&.Mui-active': {
                                                color: '#6161FF', // Color cuando está activo
                                            },
                                            '&.Mui-completed': {
                                                color: '#6161FF', // Color cuando está completo
                                            },
                                        },
                                    }}></StepLabel>
                                </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </div>
                </Container>
            ) : (
                <Container className="my-3" maxWidth="lg">
                    <div>
                        <h1
                            style={{color: "#001D67"}}
                        >
                            Crear evento
                        </h1>
                    </div>
                    <div>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            marginBottom: "25px"
                        }}>
                            <Box sx={{ width: '800px' }}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel
                                                optional={index === 1 || index === 2 ? <Typography variant="caption">Opcional</Typography> : null}
                                                disabled={index < activeStep}
                                                StepIconProps={{
                                                    sx: {
                                                        '&.Mui-active': {
                                                            color: '#6161FF', // Color cuando está activo
                                                        },
                                                        '&.Mui-completed': {
                                                            color: '#6161FF', // Color cuando está completo
                                                        },
                                                    },
                                                }}
                                            >
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </div>
                    </div>
                </Container>
            )}
            <div>
                <Switch>
                    <Route exact path="/crearEvento/creacion">
                        <CrearEvento setEventId={setEventId} setEventoCreado={setEventoCreado}/>
                    </Route>
                    <Route exact path="/crearEvento/segundoPaso">
                        {/* {eventoCreado ? <SegundoPaso eventId={eventId} /> : <Redirect to="/crearEvento/creacion" />} */}
                        <SegundoPaso eventId={eventId} />
                    </Route>
                    <Route exact path="/crearEvento/agregarColaboradores">
                        {/* {eventoCreado ? <AgregarColaboradores eventId={eventId} /> : <Redirect to="/crearEvento/creacion" />} */}
                        <AgregarColaboradores eventId={eventId} />
                    </Route>
                    <Route exact path="/crearEvento/publicarEvento">
                        {/* {eventoCreado ? <PublicarEvento eventId={eventId} /> : <Redirect to="/crearEvento/creacion" />} */}
                        <PublicarEvento eventId={eventId} />
                    </Route>
                </Switch>
            </div>
            <Container className="my-3" maxWidth="lg">
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginRight:"20px", marginBottom:"20px"}}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="contained" onClick={handleNext} disabled={activeStep === 0 && !eventoCreado}>
                    {/* <Button variant="contained" onClick={handleNext}> */}
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                    </Button>
                </Box>
            </Container>
        </>
    );
}