import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './UserContext';
import { useParams, useHistory } from 'react-router-dom';
import { api } from '../../services/api';

const ProtectedLayout = ({ children, allowedRoles }) => {
    const { userRole, setUserRole } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        const fetchUserRole = async () => {
            setIsLoading(true);
            try {
                const response = await api.participaciones.getParticipaciones();
                const data = response;

                if (data.status === 'success' && data.data?.participaciones) {
                    const role = data.data.participaciones.find(p => p.idevento.toString() === id)?.idrol;
                    if (role !== undefined && role !== null) {
                        setUserRole(role);
                        setHasAccess(allowedRoles.includes(Number(role)));
                    } else {
                        setHasAccess(false);
                    }
                } else {
                    setHasAccess(false);
                }
            } catch (error) {
                console.error('Error fetching user role: ', error);
                setHasAccess(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserRole();
    }, [id, setUserRole, allowedRoles]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (hasAccess) {
        return <>{children}</>;
    } else {
        history.replace('/unauthorized');
        return null;
    }
};

export default ProtectedLayout;
