import { useContext, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Canva from '../../components/Canva';
import FontSideBar from '../../components/Impresion/FontSideBar';
import ImageSideBar from '../../components/Impresion/ImageSideBar';
import NavCanva from '../../components/Impresion/NavCanva';
import QrSideBar from '../../components/Impresion/QrSideBar';
import TemplateSideBar from '../../components/Impresion/TemplateSideBar';
import { ImpresionContext } from '../../providers/Impresion';
import { PaginatorContext } from '../../providers/Paginator';
import { Button } from '@mui/material';

const Impresion = (setToggle = true) => {
  const {
    font,
    // setOpenFont,
    qrCanva,
    // setOpenQr,
    image,
    // setOpenImage,
    templates,
    // setOpenTemplates,
    scale,
    setScale,
    dimensionContainer,
    setDimensionContainer,
    typeTicket,
    setTypeTicket,
    driverObj,
  } = useContext(ImpresionContext);

  const { setIsToggled, event } = useContext(PaginatorContext)


  const param = useParams();
  const history = useHistory();

  useEffect(() => {
    setIsToggled(setToggle)
    const header = document.getElementsByTagName('header')
    for (let i = 0; i < header.length; i++) {
      header[i].style.display = 'none';
    }

    window.addEventListener('popstate', () => HandleBackward());

    return () => {
      window.removeEventListener('popstate', HandleBackward)
    }
  }, [])

  const HandleBackward = () => {
    setIsToggled(false)
    const header = document.getElementsByTagName('header')
    for (let i = 0; i < header.length; i++) {
      header[i].style.display = 'block';
    }
    history.push(`/eventos/${param.id}`)
  }

  //No comentar

  const handleTypeTicket = (e) => {
    let selectedType = e.currentTarget.id
    if (selectedType === "pulsera") {
      setTypeTicket(true)
      setDimensionContainer({ ...dimensionContainer, type: "pulsera", width: 200, height: 17 })
      setScale(4)
    } else if (selectedType === "tarjeta") {
      setTypeTicket(true)
      setScale(6)
    } else {
      alert("Debe seleccionar un tipo de ticket")
    }

    driverObj.drive()
  }

  return (
    <>
      <div className='container-fluid d-flex p-0 m-0 h-100 bg-body-secondary'>
        {!typeTicket &&
          <>
            <div
              className='position-absolute'
              style={{
                width: "500px",
                height: "200px",
                top: "200px",
                left: "500px",
                zIndex: 10,
              }}
            >
              <div className='w-100 h-100 p-3 d-flex flex-column align-items-center justify-content-center bg-body-tertiary border rounded-4'>
                <p className='fs-5'>¿Qué diseño desearía realizar?</p>
                <div className='w-100 d-flex justify-content-center align-items-center gap-3'>
                  <button id='pulsera'
                    className='btn  btn-outline-primary fs-4'
                    onClick={(e) => handleTypeTicket(e)}
                  >
                    Pulsera
                  </button>
                  <button
                    id='tarjeta'
                    className='btn btn-outline-primary fs-4'
                    onClick={(e) => handleTypeTicket(e)}
                  >
                    Tarjeta
                  </button>
                </div>
              </div>
            </div>
          </>
        }
        {typeTicket &&
          <>
            <div className='position-absolute d-flex justify-content-center align-items-center' style={{ zIndex: 3, top: '80px', left: '35%' }}>

              <Button
                variant='text'
                className='text-capitalize fs-5 text-decoration-none'
                onClick={() => HandleBackward()}>
                {event?.toLowerCase()}
              </Button>
            </div>
            <div id='menuTools' className='h-100 d-flex flex-column justify-content-center align-items-center' style={{ 'width': '85px' }}>
              <NavCanva />
            </div>
            <div className='position-relative d-flex h-100 z-1000' style={{ zIndex: 3, transform: font ? 'translateX(5px)' : 'translateX(-600px)', transition: 'transform 0.4s ease' }} >
              <FontSideBar />
            </div>
            <div className='position-relative h-100 z-1000' style={{ zIndex: 3, transform: image ? 'translateX(5px)' : 'translateX(-600px)', transition: 'transform 0.4s ease' }} >
              <ImageSideBar />
            </div>
            <div className='position-relative h-100 z-1000' style={{ zIndex: 3, transform: qrCanva ? 'translateX(5px)' : 'translateX(-600px)', transition: 'transform 0.4s ease' }} >
              <QrSideBar />
            </div>
            <div className='position-relative h-100 z-1000' style={{ zIndex: 3, transform: templates ? 'translateX(5px)' : 'translateX(-600px)', transition: 'transform 0.4s ease' }} >
              <TemplateSideBar />
            </div>
            <div className='w-100 h-100 bg-body-secondary d-flex justify-content-center align-items-center overflow-hidden'
              style={{ zIndex: '2', position: 'relative' }}
            >
              <Canva id='canva' width={(dimensionContainer.width) * scale} height={(dimensionContainer.height) * scale} />
            </div>

            <style>
              {`
          @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
          
          .texto-roboto {
            font-family: 'Roboto', sans-serif;
          }
          .texto-merriweather {
            font-family: 'Merriweather', serif;
          }
          .texto-pacifico {
            font-family: 'Pacifico', cursive;
          }
          .texto-oswald {
            font-family: 'Oswald', sans-serif;
          }
          .texto-playfairDisplay {
            font-family: 'Playfair Display', serif;
          }
          .texto-lobster {
            font-family: 'Lobster', cursive;
          }
          .texto-anton {
            font-family: 'Anton', sans-serif;
          }
          .texto-indieFlower {
            font-family: 'Indie Flower', cursive;
          }
          .texto-abrilFatface {
            font-family: 'Abril Fatface', serif;
          }
          .texto-fjallaOne {
            font-family: 'Fjalla One', sans-serif;
          }

          header{
            display: none;
          }
        `}
            </style>
          </>
        }
      </div >
    </>
  )
}

export default Impresion;