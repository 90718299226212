import { useContext } from "react";
import { ImpresionContext } from "../../providers/Impresion";
import { PaginatorContext } from "../../providers/Paginator";

const AddLabel = ({ children, type }) => {
  const {
    elements,
    setElements,
    scale,
    index,
    setIndex,
    dimensionContainer
  } = useContext(ImpresionContext)
  
  const { event } = useContext(PaginatorContext)

  const HandleAddVariable = (variableType) => {
    let width = 0
    let height = 0
    let textFill = ''
    if (variableType === "text-evento") {
      textFill = event
      width = 120
      height = 15
    }
    if (variableType === "text-codigo") {
      textFill = '012345678912'
      width = 120
      height = 15
    }
    if (variableType === "text-nombre") {
      textFill = 'Nombre Apellido'
      width = 120
      height = 15
    }
    if (variableType === "qr") {
      textFill = ''
      width = 40
      height = 40
    }
    const textGenerated = elements.slice();
    setIndex(index + 1)
    textGenerated.push({
      id: index.toString(),
      x: ((dimensionContainer.width * scale) / 2) - 100,
      y: ((dimensionContainer.height * scale) / 2) - 25,
      text: textFill,
      fontSize: 14,
      width: width,
      height: height,
      rotation: 0,
      state: false,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      textDecoration: '',
      fill: '#000000',
      align: 'left',
      type: `${variableType}`,
    })
    setElements(textGenerated)
  }

  return (
    <div className="w-100 d-flex justify-content-between align-items-center border rounded-3 p-2">
      <div>
        <p className=" text-center p-0 m-0 align-middle fs-6 fw-normal">{children}</p>
      </div>
      <div>
        <button className="btn btn-primary rounded-2 d-flex align-items-center p-0" onClick={() => HandleAddVariable(type)}>
          <i className="material-symbols-outlined">add</i>
        </button>
      </div>
    </div>
  )
};

export default AddLabel;