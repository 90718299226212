import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ModeIcon from '@mui/icons-material/Mode';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Box, Modal, TextField, Alert, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMediaQuery } from "react-responsive";


const EditLocationModal = ({ id, callApi, loc, ubicaciones, onUpdate }) => {
  const [error, setError] = useState(false);
  const [errorMessage , setErrorMessage] = useState("");
  const [enviado, setEnviado] = useState(false);
  const [value, setValue] = useState(loc.ubicacion);
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 490px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 700px)` });

  function handleSubmit(e) {
    e.preventDefault();

    if(value.trim() === ''){
      setError(true);
      setErrorMessage(<>{t("errors.emptyField")}</>);
    }else if(value.length > 20){
      setError(true);
      setErrorMessage(<>{t("errors.characters")}</>);
    } else if (ubicaciones.some(item => item.ubicacion === value)) {
      setError(true);
      setErrorMessage(<>{t("errors.locationExist")}</>);
    }else{

    const updatedLocation = {
      id : loc.id,
      idevento: loc.idevento,
      ubicacion: value,
    }
    onUpdate(updatedLocation);
      setError(false)
      setErrorMessage("")
      setEnviado(true)
      setTimeout(() => {
        handleClose();
      }, 2000);
      callApi();
  }
}
function handleChange(e) {
    setValue(e.target.value);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width:isMobile ? 320 : isTablet ? 400 : 600,
    p: 3,
  };  

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false); 
    setError(false)
    setErrorMessage("")
    setEnviado(false)
  } 

  return (
    <>
      <IconButton aria-label="delete" color="primary" size="large" onClick={handleOpen}>
        <ModeIcon/>
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} onSubmit={handleSubmit}>
          <h4 id="modal-modal-title">
            {t("location.editLocation")}
          </h4>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <TextField 
                value={value}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={error} 
                helperText= {error && errorMessage}
              />
              {enviado && (
                <Alert icon={<CheckIcon fontSize="inherit"/>} style={{marginTop: "10px"}} severity="success">
                  Ubicacion editada.
                </Alert>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "15px" }}>
                <Button size="small" variant="contained" sx={{background: grey[500]}} style={{marginRight: "10px"}} onClick={handleClose}>
                  {t("button.cancel")}
                </Button>
                <Button variant="contained" type="submit" size="small">
                  {t("button.save")}
                </Button>
              </div>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
}
export default EditLocationModal;