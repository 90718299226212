import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { api } from "../../../services/api";
import {
  Alert,
  Button,
  Input,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { PaginatorContext } from "../../../providers/Paginator";
import { formatDistanceToNow } from "date-fns"; // Importar la dependencia instalada
import { es } from "date-fns/locale"; // Importar el locale español
import VolverButton from "../../../components/VolverButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Table, TablePagination, TableBody, Paper, TableContainer, TableHead, TableRow, TableCell } from "@mui/material";


export const Ventas = () => {
  const history = useHistory();
  const param = useParams();
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState();

  const {
    query,
    setQuery,
    totalLength,
    setTotalLength,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useContext(PaginatorContext);

  const [ventas, setVentas] = useState([]); // Almacenamiento de los registros
  const [showSearch, setShowSearch] = useState();

  useEffect(() => {
    const callApi = async (id) => {
      setLoading(true);

      try {
        const response = await api.ventas.getAll(
          id,
          query,
          pageSize,
          currentPage
        );

        if (response.status === "success") {
          setVentas(response.data.ventas);
          setTotalLength(response.data.totalDataSize);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    };

    callApi(param.id);
  }, [param.id, query, pageSize, currentPage, setTotalLength]);

  // Manejar el cambio de página
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Manejar el cambio de tamaño de página
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reiniciar la página al cambiar el tamaño
  };

  const handleRowClick = (venta) => {
    history.push(`/eventos/ventas/${venta.id}`);
  };
  
  //BUSQUEDA
  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    setQuery("");
  };

  return (
    <div className="container-fluid">
      <form onSubmit={(e) => e.preventDefault()} className="d-flex justify-content-end">
        {showSearch ? (
          <div className="w-50 d-flex gap-2">
            <Input
              id="exampleSearch"
              name="q"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Buscar.."
              type="search"
            />
            <Button onClick={() => setShowSearch(false)} color="light">
              <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
            </Button>
          </div>
        ) : (
          <Button color="secondary" onClick={handleToggleSearch}>
            <FontAwesomeIcon icon={faSearch} className="fs-6" />
          </Button>
        )}
      </form>
      
      <TableContainer component={Paper} className="mt-3 table-responsive">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t("sales.buyer")}</TableCell>
              <TableCell>{t("sales.initDate")}</TableCell>
              <TableCell>{t("sales.payDate")}</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Spinner color="primary" />
                </TableCell>
              </TableRow>
            ) : (
              ventas ? (
                ventas.map((venta) => (
                  <TableRow
                    key={venta.id}
                    onClick={() => handleRowClick(venta)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">{venta.id}</TableCell>
                    
                    <TableCell>{venta.email}</TableCell>
    
                    <TableCell>
                      {formatDistanceToNow(new Date(venta.fecha), {
                        addSuffix: true,
                        locale: es,
                      })}
                    </TableCell>
    
                    <TableCell className={
                      venta.atendido === null
                        ? "text-warning fw-bold"
                        : ""
                      }
                    >
                      {venta.atendido !== null
                        ? formatDistanceToNow(new Date(venta.atendido), {
                            addSuffix: true,
                            locale: es,
                          })
                        : t("sales.pending")
                      }
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Alert color="secondary" className="m-0">
                      {t("sales.saleAlert")}
                    </Alert>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          className="d-flex justify-content-center"
          rowsPerPageOptions={[10, 25, 50]} // Opciones de tamaño de página
          count={totalLength} // Total de registros
          rowsPerPage={pageSize} // Tamaño de la página actual
          page={currentPage} // Página actual
          onPageChange={handleChangePage} // Evento de cambio de página
          onRowsPerPageChange={handleChangeRowsPerPage} // Evento de cambio de tamaño de página
          labelRowsPerPage="Registros p/página"
          showFirstButton
          showLastButton
        />
      </TableContainer>


      <div className="d-flex justify-content-end mt-3">
        <VolverButton />
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Ventas, {
  onRedirecting: () => <Loading />,
});
