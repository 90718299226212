import React, { useContext, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { 
  Container,
  Button,
  TextField,
  IconButton,
  TableContainer,
  Paper,
  Table, 
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import VolverButton from "../../components/VolverButton";
import { PaginatorContext } from "../../providers/Paginator";
import DeleteTarifaModal from "../../components/modales/DeleteTarifaModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

export const Tarifas = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [tarifas, setTarifas] = useState([]);
  const [showSearch, setShowSearch] = useState();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const param = useParams();
  const [t] = useTranslation("global");
  const history = useHistory();

  //PAGINACION
  const {
    query,
    setQuery,
  } = useContext(PaginatorContext);


  useEffect(()=>{
    callApi();
  },[]);
 
  const callApi = async () =>{
    try {
      const response = await api.tarifas.getAll(param.id);

      if(response.status === "success"){
        setTarifas(response.data.tarifas);
      }
    } catch (error) {
      setState({
        ...state,
        error: error.message,
      });
    }
  }
  const tarifasTicket = tarifas?.filter((tarifa) => tarifa.idtipo === 1);
  const tarifasConsumicion = tarifas?.filter((tarifa) => tarifa.idtipo === 2);

  //BUSQUEDA
  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    setQuery('');
  };

  const [open, setOpen] = useState(''); 
  const toggle = (id) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id); 
    }
  };

  return (
    <Container>
      <div className="d-flex justify-content-between mb-3 gap-1">
        <Button variant="contained" color="success" size="small" startIcon={<AddIcon/>}
          onClick={() => history.push(`/eventos/${param.id}/creartarifa`)}
        >
          {t("button.add")}
        </Button>
        <form onSubmit={(e) => e.preventDefault()}>
          {showSearch ? (
            <div className="w-100 d-flex">
              <TextField
                id="exampleSearch"
                name="q"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Buscar..."
                type="search"
                variant="outlined"
                size="small"
                //sx={{ flexGrow: 1 }} // Para que el campo ocupe el espacio disponible
              />
              <IconButton onClick={() => setShowSearch(false)} color="inherit">
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <Button variant="contained" sx={{background: grey[500]}}  onClick={handleToggleSearch}>
              <SearchIcon />
            </Button>
          )}
        </form>
      </div>
      
      <h4>Para tickets</h4>
      <TableContainer component={Paper} sx={{marginBottom: "25px"}}>
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell>{t("rates.rate")}</TableCell>
              <TableCell>{t("rates.price")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tarifasTicket ? 
              (tarifasTicket.map((tarifa) => (
                <TableRow key={tarifa.id}>
                  <TableCell component="th" scope="row">{tarifa.concepto}</TableCell>
                  <TableCell>{tarifa.precio} {tarifa.unidad}</TableCell>
                  <TableCell align="center">
                    <DeleteTarifaModal id={tarifa.id} callApi={callApi} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="3">
                  <Alert severity="info" >
                    No hay tarifas registradas
                  </Alert>
                </TableCell>
              </TableRow>
            )}    
          </TableBody>
        </Table>
      </TableContainer>

      <h4>Para vouchers</h4>
      <TableContainer component={Paper} sx={{marginBottom: "15px"}}>
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#EDEDED" }}>
            <TableRow>
              <TableCell>{t("rates.rate")}</TableCell>
              <TableCell>{t("rates.price")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tarifasConsumicion ? 
              (tarifasConsumicion.map((tarifa) => (
                <TableRow key={tarifa.id}>
                  <TableCell component="th" scope="row">{tarifa.concepto}</TableCell>
                  <TableCell>{tarifa.precio} {tarifa.unidad}</TableCell>
                  <TableCell align="center">
                    <DeleteTarifaModal id={tarifa.id} callApi={callApi} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="3">
                  <Alert severity="info" >
                    No hay tarifas registradas
                  </Alert>
                </TableCell>
              </TableRow>
            )}    
          </TableBody>
        </Table>
      </TableContainer>
    

        {/* </><Container fluid="sm">
          <div className="d-flex justify-content-between">
            <Button color="success"
              className="d-flex align-items-center gap-2"
              onClick={() => history.push(`/eventos/${param.id}/creartarifa`)}
            >
              <FontAwesomeIcon icon={faAdd} className="fs-6" />
              {t("button.add")}
            </Button>
            <form onSubmit={(e) => e.preventDefault()}>
              {showSearch ? (
                <div className="w-100 d-flex gap-2">
                  <Input
                    id="exampleSearch"
                    name="q"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Buscar.."
                    type="search"
                  />
                  <Button onClick={() => setShowSearch(false)} color="light">
                    <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
                  </Button>
                </div>
              ) : (
                <Button color="secondary" onClick={handleToggleSearch}>
                  <FontAwesomeIcon icon={faSearch} className="fs-6" />
                </Button>
              )}
            </form>
          </div>

          <h4 className="mt-3">
            Tarifas de tickets
          </h4>
          <Table hover responsive size="sm">
            <thead>
              <tr className="table-light">
                <th>{t("rates.rate")}</th> */}
                {/* <th>{t("rates.unit")}</th> 
                <th>{t("rates.price")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tarifasTicket ? (tarifasTicket.map((tarifa) => (
                <tr key={tarifa.id}>
                  <td width={300}>{tarifa.concepto}</td>
                  {/* <td>{tarifa.unidad}</td> *
                  <td width={200}>${tarifa.precio} / {tarifa.unidad}</td>
                  <td width={100} align="center">
                    <DeleteTarifaModal id={tarifa.id} callApi={callApi} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <Alert color="secondary" className="text-center m-0">
                    No hay tickets aún.
                  </Alert>
                </td>
              </tr>
            )}
            </tbody>
          </Table>

          <h4 className="mt-3">
            Tarifas de vouchers
          </h4>
          <Table hover responsive size="sm">
            <thead>
              <tr className="table-light">
                <th>{t("rates.rate")}</th>
                {/* <th>{t("rates.unit")}</th> 
                <th>{t("rates.price")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tarifasConsumicion ? (tarifasConsumicion.map((tarifa) => (
                <tr key={tarifa.id}>
                  <td width={300}>{tarifa.concepto}</td>
                  {/* <td>{tarifa.unidad}</td> 
                  <td width={200}>${tarifa.precio} / {tarifa.unidad}</td>
                  <td width={100} align="center">
                    <DeleteTarifaModal id={tarifa.id} callApi={callApi} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <Alert color="secondary" className="text-center m-0">
                    No hay vouchers aún.
                  </Alert>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
 */}

          <div className="d-flex justify-content-end">
            <VolverButton />
          </div>


    </Container>
  );
};
export default withAuthenticationRequired(Tarifas, {
  onRedirecting: () => <Loading />,
});