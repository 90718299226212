import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
import "moment/locale/es";
import moment from "moment-timezone";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { api } from '../../services/api';
import { useMediaQuery } from "react-responsive";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';


export const MenuMobil = ({ evento }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
    const param = useParams();
    const [t] = useTranslation("global");
    const [state, setState] = useState({
        showResult: false,
        apiMessage: "",
        error: null
    });
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const fetchRole = async () => {
            try {
                const response = await api.participaciones.getParticipaciones();
                if (response.status === "success") {
                    const rol = response.data.participaciones.find((p) => p.idevento == param.id)?.idrol;
                    setUserRole(rol);
                } else {
                    setState({ ...state, error: "Error al obtener el rol." });
                }
            } catch (error) {
                setState({ ...state, error: "Error en la red." });
            }
        };
        fetchRole([]);
    }, [param.id])
    const attach = evento?.attachs?.[0];
    const urlImg = attach?.url;
    const imagen = urlImg || process.env.PUBLIC_URL + '/img_fiesta.jpg';

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [openMenu, setOpenMenu] = useState(null);
    const toggleMenu = (menuName) => {
        setOpenMenu(openMenu === menuName ? null : menuName);
    };
  
    return (
        <div>
        {isMobile && (
            <>
                <a className="button-offcanvas" onClick={toggle}>
                    <i className="material-symbols-outlined">menu_open</i>
                </a>
        
                <Offcanvas direction="end" isOpen={isOpen} toggle={toggle} fade={false} style={{width: "330px", zIndex: "99999"}}>
                    <OffcanvasHeader toggle={toggle}>
                        <div className='menu-header d-flex align-items-center gap-2'>
                            <div className='menu-header-img' style={{width: "50px", height: "50px", flexShrink: "0"}}>
                                <img src={imagen} alt="Fiesta" className='rounded-circle' style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "center"}} />
                            </div>
                            <div className='menu-header-info_evento'>
                                <p className='m-0'>{evento.evento}</p>
                                <small>
                                    {
                                        moment(evento.hora)
                                        .clone()
                                        .local()
                                        .format("D [de] MMMM YYYY")
                                    }
                                </small>
                            </div>
                        </div>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <div className='menu-body'>
                            <ul>
                                {userRole !== 3 && (
                                    <li className='menu-body-item' onClick={toggle}>
                                        <Link className="link" to={"/eventos/" + param.id}>
                                            <i className="material-symbols-outlined">home</i>
                                            <p style={{fontSize: "16px"}}>{t("menuTemplate.home")}</p>
                                        </Link>
                                    </li>
                                )}
                                {userRole !== 3 && (
                                    <li className="dropdown_list">
                                        <a onClick={() => toggleMenu("menu4")} className="dropdown_link">
                                        <div className="d-flex" style={{ marginRight: "10px" }}>
                                            <i className="material-symbols-outlined" style={{ marginRight: "10px" }}>
                                                qr_code
                                            </i>
                                            <p>{t("event.scan")}</p>
                                        </div>
                                        <i className={`material-symbols-outlined dropdown_arrow ${openMenu === "menu4" ? "open" : ""}`}>
                                            arrow_drop_down
                                        </i>
                                        </a>
                                        {openMenu === "menu4" && (
                                            <div className="dropdown_content">
                                                <ul className="dropdown_sub" style={{ marginLeft: "12px" }}>
                                                    <li className='menu-body-item dropdown_sub'  onClick={toggle}>
                                                        <Link className="link" to={"/eventos/" + param.id + "/qrvouchers"}>
                                                            <p>{t("menuTemplate.redeemVouchers")}</p>
                                                        </Link>
                                                    </li>
                                                    <li className='menu-body-item dropdown_sub'  onClick={toggle}>
                                                        <Link className="link" to={`/eventos/${param.id}/qrtickets`}>
                                                            <p>{t("menuTemplate.accessControl")}</p>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                )}
                                {userRole !== 2 && (
                                    <li className='menu-body-item' onClick={toggle}>
                                        <Link className="link" to={`/eventos/${param.id}/ventas`}>
                                            <i className="material-symbols-outlined">trending_up</i>
                                            <p>{t("menuTemplate.sales")}</p>
                                        </Link>
                                    </li>
                                )}
                                {userRole === 1 && (
                                    <li className='menu-body-item' onClick={toggle}>
                                        <Link className="link" to={"/eventos/" + param.id + "/cuentas"}>
                                            <i className="material-symbols-outlined">account_balance_wallet</i>
                                            <p>{t("menuTemplate.wallet")}</p>
                                        </Link>
                                    </li>
                                )}
                                {userRole !== 2 && (
                                    <li className='menu-body-item' onClick={toggle}>
                                        <Link className="link" to={`/eventos/${param.id}/publicaciones`}>
                                            <i className="material-symbols-outlined">newspaper</i>
                                            <p>{t("menuTemplate.publications")}</p>
                                        </Link>
                                    </li>
                                )}
                                {userRole === 1 && (
                                    <li className='menu-body-item' onClick={toggle}>
                                        <Link className="link" to={`/eventos/${param.id}/impresion`}>
                                            <i className="material-symbols-outlined">colors</i>
                                            <p>{t("menuTemplate.design")}</p>
                                        </Link>
                                    </li>
                                )}
                                <li className='dropdown_list'>
                                    <a onClick={() => toggleMenu("menu1")} className='dropdown_link'>
                                        <div className='d-flex' style={{marginRight: "10px"}}>
                                            <i className="material-symbols-outlined" style={{marginRight: "10px"}}>group</i>
                                            <p>Staff</p> 
                                        </div>
                                        <i className={`material-symbols-outlined dropdown_arrow ${openMenu === "menu1" ? 'open' : ''}`}>arrow_drop_down</i>
                                    </a>
                                    {openMenu === "menu1" && (
                                        <div className='dropdown_content'>
                                            <ul className='dropdown_sub' style={{marginLeft: "12px"}}>
                                                {userRole !== 2 && (
                                                    <li className='menu-body-item dropdown_sub' onClick={toggle}>
                                                        <Link className="link" to={`/eventos/${param.id}/clientes`}>
                                                            <p>{t("menuTemplate.customers")}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                {userRole !== 3 && (
                                                    <li className='menu-body-item dropdown_sub' onClick={toggle}>
                                                        <Link className="link" to={`/eventos/${param.id}/colaboradores`}>
                                                            <p>{t("menuTemplate.collaborators")}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </li>

                                <li className='dropdown_list'>
                                    <a onClick={() => toggleMenu("menu2")} className='dropdown_link'>
                                        <div className='d-flex'>
                                            <i className="material-symbols-outlined" style={{marginRight: "10px"}}>confirmation_number</i>
                                            <p>{t("menuTemplate.manageEntries")}</p> 
                                        </div>
                                        <i className={`material-symbols-outlined dropdown_arrow ${openMenu === "menu2" ? 'open' : ''}`}>arrow_drop_down</i>
                                    </a>
                                    {openMenu === "menu2" && (
                                        <div className='dropdown_content'>
                                            <ul className='dropdown_sub' style={{marginLeft: "12px"}}>
                                                {userRole === 1 && (
                                                    <li className='menu-body-item' onClick={toggle}>
                                                        <Link className="link" to={"/eventos/" + param.id + "/tarifas"}>
                                                            <p>{t("rates.rate")}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                <li className='menu-body-item' onClick={toggle}>
                                                    <Link className="link" to={"/eventos/" + param.id + "/tickets&vouchers"}>
                                                        <p>Tickets & Vouchers</p>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </li>
                                <li className='dropdown_list'>
                                    <a onClick={() => toggleMenu("menu3")} className='dropdown_link'>
                                        <div className='d-flex' >
                                            <i className="material-symbols-outlined" style={{marginRight: "10px"}}>database</i>
                                            <p>{t("menuTemplate.manageData")}</p> 
                                        </div>
                                        <i className={`material-symbols-outlined dropdown_arrow ${openMenu === "menu3" ? 'open' : ''}`}>arrow_drop_down</i>
                                    </a>
                                    {openMenu === "menu3" && (
                                        <div className='dropdown_content'>
                                            <ul className='dropdown_sub' style={{marginLeft: "12px"}}>
                                                {userRole !== 3 && (
                                                    <li className='menu-body-item' onClick={toggle}>
                                                        <Link className="link" to={"/eventos/" + param.id + "/categorias"}>
                                                            <p>{t("menuTemplate.categories")}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                {userRole !== 3 && (
                                                    <li className='menu-body-item' onClick={toggle}>
                                                        <Link className="link" to={"/eventos/" + param.id + "/ubicaciones"}>
                                                            <p>{t("menuTemplate.locations")}</p>
                                                    </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </li>
                                {userRole == 1 && (
                                    <li className='menu-body-item' onClick={toggle}>
                                        <Link className="link" to={"/eventos/" + param.id + "/editar"}>
                                            <i className="material-symbols-outlined">settings</i>
                                            <p>{t("menuTemplate.eventConfiguration")}</p>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </OffcanvasBody>
                </Offcanvas>
            </>
        )}
    </div>
  )
}
