import React, { useContext, useState, useEffect } from "react";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Row,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Button,
  Alert
} from "reactstrap";
import VolverButton from "../../components/VolverButton";
import AddLocationModal from "../../components/modales/AddLocationModal";
import DeleteLocationModal from "../../components/modales/DeleteLocationModal";
import EditLocationModal from "../../components/modales/EditLocationModal";
import { PaginatorContext } from "../../providers/Paginator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

export const Ubicaciones = () => {
  const [locations, setLocations] = useState([""]);
  const [location, setLocation] = useState("");
  const [evento, setEvento] = useState();
  const [showSearch, setShowSearch] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const param = useParams();
  const [t, i18n] = useTranslation("global");
  const locationn = useLocation();
  // const currentPath = location.pathname;
  const currentPath = "https://www.google.com"


  //PAGINACION
  const {
    query,
    setQuery,
    totalLength,
    setTotalLength,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useContext(PaginatorContext);

  const limitPages = 5; // Definimos cuantas paginas queremos mostrar a la vez
  const totalPages = Math.max(Math.ceil(totalLength / pageSize), 1); // Calculamos el total de paginas, en caso de ser < 1 que tome valor 1.
  let startPage = Math.max(currentPage - Math.floor(limitPages / 2), 1); // Calculamos la primera pagina a mostrar
  let endPage = Math.min(startPage + limitPages - 1, totalPages); // Calculamos la ultima pagina a mostrar
  // Ajustamos startPage en caso de q no alcance a mostrar las paginas asignadas al limitPages
  if (endPage - startPage + 1 < limitPages && startPage > 1) {
    startPage = Math.max(endPage - limitPages + 1, 1);
  }

  useEffect(() => {
    callApi().then((data) => {
      callApiLocations(data);
    });
  }, [state, query, pageSize, currentPage, submitted, totalLength, location]);

  const callApi = async () => {
    try {
      const response = await api.eventos.get(param.id);

      if (response.status === "success") {
        const data = response.data.evento;
        setEvento(data);
        return data;
      }
    } catch (error) {
      setState({
        ...state,
        error: "Error en la Red.",
      });
    }
  };

  const callApiLocations = async (evento) => {
    const response2 = await api.locations.getAll(
      evento.id,
      query,
      pageSize,
      currentPage
    );
    const dataUbicaciones = response2.data.ubicaciones;

    setLocations(dataUbicaciones);
    if (response2.data.totalDataSize) {
      setTotalLength(response2.data.totalDataSize);
    }      if (response2.data.totalDataSize) {
      setTotalLength(response2.data.totalDataSize);
    }
    if (!locations) {
      setTotalLength(0);
    }
    setSubmitted(false);
    if (!locations) {
      setTotalLength(0);
    }
    setSubmitted(false);
  };

  const callLocationsForModals = () => {
    callApi().then((data) => {
      callApiLocations(data);
    });
  };

  //PAGINACION
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const paginationItems = [];
  for (let page = startPage; page <= endPage; page++) {
    paginationItems.push(
      <PaginationItem active={page === currentPage} key={page}>
        <PaginationLink onClick={() => handleChangePage(page)}>
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  }

  //BUSQUEDA
  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    setQuery('');
  };

  // EDIT
  const editLocation = async (value) => {
    try {
      const response = await api.locations.updateLocation(value);
      if (response.status === "success") setLocation("");
      setState({
        ...state,
        apiMessage: "Elemento editado.",
      });
    } catch (error) {
      console.log(`error: ${error}`);
    }
  };

  return (
    <>
      {evento && (
        <Container fluid="sm">
          <div className="d-flex justify-content-between">
            <AddLocationModal
              evento={evento}
              locations={locations}
              callApi={callLocationsForModals}
            />
            <form onSubmit={(e) => e.preventDefault()}>
              {showSearch ? (
                <div className="w-100 d-flex gap-2">
                  <Input
                    id="exampleSearch"
                    name="q"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Buscar.."
                    type="search"
                  />
                  <Button onClick={() => setShowSearch(false)} color="light">
                    <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
                  </Button>
                </div>
              ) : (
                <Button color="secondary" onClick={handleToggleSearch}>
                  <FontAwesomeIcon icon={faSearch} className="fs-6" />
                </Button>
              )}
            </form>
          </div>

          <Table hover responsive size="sm" className="mt-3">
            <thead>
              <tr className="table-light">
                <th>#</th>
                <th>{t("location.location")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {locations ? (locations.map((loc) => (
                <tr key={loc.id}>
                  <th scope="row">{loc.id}</th>
                  <td>{loc.ubicacion}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-center",
                      }}
                    >
                      <DeleteLocationModal
                        id={loc.id}
                        callApi={callLocationsForModals}
                      ></DeleteLocationModal>
                      <EditLocationModal
                        id={loc.id}
                        callApi={callLocationsForModals}
                        loc={loc}
                        ubicaciones={locations}
                        onUpdate={editLocation}
                      ></EditLocationModal>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <Alert color="secondary" className="m-0">
                    No hay ubicaciones aún.
                  </Alert>
                </td>
              </tr>
            )}
            </tbody>
          </Table>

          <Row>
            <Col xs="auto">
              <small className="text-left">
                {t("location.totalEntries")} {totalLength}
              </small>
            </Col>
            <Col xs="auto" className="mx-auto">
              <Pagination>
                <PaginationItem disabled={currentPage <= 1}>
                  <PaginationLink
                    first
                    href=""
                    onClick={() => handleChangePage(1)}
                  />
                </PaginationItem>
                <PaginationItem disabled={currentPage <= 1}>
                  <PaginationLink
                    previous
                    href=""
                    onClick={() => handleChangePage(currentPage - 1)}
                  />
                </PaginationItem>

                {paginationItems}

                <PaginationItem disabled={currentPage >= totalPages}>
                  <PaginationLink
                    next
                    href=""
                    onClick={() => handleChangePage(currentPage + 1)}
                  />
                </PaginationItem>

                <PaginationItem disabled={currentPage >= totalPages}>
                  <PaginationLink
                    last
                    href=""
                    onClick={() => handleChangePage(totalPages)}
                  />
                </PaginationItem>
              </Pagination>
            </Col>
          </Row>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <br />
            <VolverButton></VolverButton>
          </div>



          <div className="fb-share-button" data-href={currentPath} data-layout="" data-size="">
            <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + currentPath}>
              <span className="material-symbols-outlined" icon="share">
                share
              </span>
            </a>
          </div>
        
        
          <br/>
          <br/>
        </Container>
      )}
    </>
  );
};
export default withAuthenticationRequired(Ubicaciones, {
  onRedirecting: () => <Loading />,
});
